/* eslint-disable jsx-a11y/anchor-is-valid */
//import { overflow } from 'html2canvas/dist/types/css/property-descriptors/overflow';
import React, { useState } from 'react';
import { KTSVG, toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import { Plants } from '../../../../../@types/plants';
import { ModalPlant } from '../../../../components/modalPlant';
import './styles.css'
import useSize from '../../../../../hooks/useSize';
import { ModalArquivePlant } from '../ModalArquivePlant';
import { useAuth } from '../../../../../modules/auth';
import { ModalDeletePlant } from '../ModalDeletePlant';

type Props = {
  plants: Plants[]
  refresh: any
}

export function TablePlantsSearch({plants, refresh}: Props) {
  const [myPlant, setMyPlant] = useState<string>("")
  const [myPlantObject, setMyPlantObject] = useState<any>(null)
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  const [showCreateAppModalDelete, setShowCreateAppModalDelete] = useState<boolean>(false)
  const [showCreateAppModalDeleteV2, setShowCreateAppModalDeleteV2] = useState<boolean>(false)
  const {width} = useSize()
  const user = useAuth()

  const closedModal = (active: boolean) => {
    setMyPlant("")
    setShowCreateAppModal(active)
  }
  return (
    <table className={`table table-hover align-middle ${width < 1200 ? '' : 'gs-0 gy-4'} `}>
      <thead>
        <tr className='fw-bold text-muted bg-light '>
          <th className='ps-4 min-w-325px rounded-start'>Name</th>
          <th className='min-w-150px'>Marca</th>
          <th className='min-w-200px'>Endereço</th>
          <th className='min-w-125px'>Capacidade</th>
          <th className='min-w-150px'>Energia Gerada {"(Total)"}</th>
          <th className='min-w-100px'>Alarmes</th>
          <th className='min-w-150px'>Status</th>
          <th className='min-w-200px text-end rounded-end'></th>
        </tr>
      </thead>
      <tbody>
          {
            plants && plants.length > 0 ?
              plants.map((plant: Plants) => {
                  return (
                      <tr
                        className={(plant.arquive !== 'ARQUIVADA') && ((plant.mark && plant.mark.active === true) || (plant.credential && plant.credential.mark && plant.credential.mark.active === true)) ? "" : "disabled"}
                        onClick={() => {
                          setMyPlant(plant.id)
                          setShowCreateAppModal(true)
                        }} 
                      >
                          <td>
                            <div className='d-flex align-items-center'>
                                <span
                                  className={`bullet bullet-vertical h-50px bg-${
                                    plant.status !== null
                                      ? plant.status === 0
                                      ? "success"
                                      : plant.status === 1
                                      ? "danger"
                                      : plant.status === 2
                                      ? "warning"
                                      : plant.status === 7 || plant.status === 3
                                      ? "info"
                                      : "secondary"
                                    : "secondary"
                                  } spanMarcador`}
                                >
                                </span>
                                <div className='symbol symbol-50px me-5'>
                                  <img
                                      src={toAbsoluteUrl('/media/imgs/plant2.png')}
                                      className=''
                                      alt=''
                                  />
                                </div>
                                <div className='d-flex justify-content-start flex-column'>
                                  <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                      {plant && plant.name ? plant.name : "Usina"}
                                  </a>
                                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                      {plant && plant.createDate ? plant.createDate : "0000-00-00"}
                                  </span>
                                </div>

                                <div style={{marginLeft: '1rem'}}>
                                  <span className={`badge badge-light-primary fw-bolder fs-8 px-2 py-1 ms-2`}>
                                    {plant.plano
                                    ? plant.plano : ""
                                    }
                                  </span>
                                </div>
                            </div>
                          </td>

                          <td>
                            <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                {
                                  plant && plant.mark 
                                  ? plant.mark.name 
                                  : plant.credential && plant.credential.mark 
                                  ? plant.credential.mark.name 
                                  : "Não encontrado. "
                                }
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>******</span>
                          </td>

                          <td>
                            <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                              {plant && plant.address ? plant.address : "Não encontrado. "}
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>Brasil</span>
                          </td>
                          <td>
                            <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                              {plant && plant.capacity ? plant.capacity : "Usina"}
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>KWp</span>
                          </td>
                          <td>
                            <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                              {plant && plant.energyTotal ? plant.energyTotal : "0.0"}
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>KWh</span>
                          </td>
                          <td>
                            {plant.alarmsCount && plant.alarmsCount > 0 ? (
                              <a 
                                href="#"
                                data-bs-toggle='tooltip'
                                title={`Alarmes: ${plant.alarmsCount}`}
                              >
                                <KTSVG path="/media/icons/duotune/general/gen044.svg" className="svg-icon-danger svg-icon-2hx" />
                              </a>
                            ) : <></>}
                          </td>
                          <td>
                            <span 
                              className={
                                `badge badge-light-${
                                  plant.status !== null
                                    ? plant.status === 0
                                    ? "success"
                                    : plant.status === 1
                                    ? "danger"
                                    : plant.status === 2
                                    ? "warning"
                                    : plant.status === 7 || plant.status === 3
                                    ? "info"
                                    : "secondary"
                                  : "secondary"
                                } fs-7 fw-semibold`
                              }
                            >
                                {
                                  plant.status !== null
                                    ? plant.status === 0
                                    ? "Online"
                                    : plant.status === 1
                                    ? "Offline"
                                    : plant.status === 2
                                    ? "Alerta"
                                    : plant.status === 7 || plant.status === 3
                                    ? "Lembrar"
                                    : "Desconhecido"
                                : "Desconhecido"
                                }
                            </span>
                          </td>
                          
                          <td className='text-end' style={{pointerEvents: "all"}}>
                            {user.currentUser?.role.slug !== "CLIENTE" && 
                                <a href='#' 
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    setMyPlantObject(plant)
                                    setShowCreateAppModalDelete(true)
                                  }}
                                >
                                    <KTSVG path='/media/icons/duotune/files/fil012.svg' className='svg-icon-3' />
                                </a>
                            }
                            {user.currentUser?.role.slug !== "CLIENTE" && 
                              <a href='#' 
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setMyPlantObject(plant)
                                  setShowCreateAppModalDeleteV2(true)
                                }}
                              >
                                  <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                              </a>
                            }
                          </td>
                      </tr>
                  )
              })
            : 
              <tr>
                <td>
                  <div className='divNotPlants'>
                    <h3 className='card-title align-items-start flex-column'>
                      <span className='text-muted mt-1 fw-semibold fs-5'>Usinas não encontradas!</span>
                    </h3>
                  </div>
                </td>
              </tr>
          }
      </tbody>
      <tfoot>
        <ModalPlant show={showCreateAppModal} handleClose={() => closedModal(false)} plant={myPlant}/>

        <ModalArquivePlant 
          show={showCreateAppModalDelete}
          data={myPlantObject}
          id={myPlantObject ? myPlantObject.id : undefined} 
          handleClose={() => {
            setMyPlantObject(null)
            setShowCreateAppModalDelete(false)
            refresh()
          }} 
        />

        <ModalDeletePlant 
          show={showCreateAppModalDeleteV2}
          data={myPlantObject}
          id={myPlantObject ? myPlantObject.id : undefined} 
          handleClose={() => {
            setMyPlantObject(null)
            setShowCreateAppModalDeleteV2(false)
            refresh()
          }} 
        />



      </tfoot>
    </table>
  );
}