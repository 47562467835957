/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import * as Yup from "yup";

import { StepperComponent } from '../../../../../../_metronic/assets/ts/components'
import { useToast } from '../../../Toats'
import { KTSVG } from '../../../../../../_metronic/helpers'
import { LoadingSpan } from '../../../Loadings/loadingSpan'
import { useFormik } from 'formik';
import { postPrognosisOne, putPlantOne, putPrognosisOne } from '../../../../../services/plantsService';
import useSize from '../../../../../hooks/useSize';

type Props = {
    id: string;
    data: any;
    show: boolean;
    handleClose: () => void
}

const validationSchema = Yup.object().shape({
  value_implementation: Yup.number(),
  annual_generation_forecast: Yup.number(),

  prog_abr: Yup.number(),
  prog_ago: Yup.number(),
  prog_dez: Yup.number(),
  prog_fev: Yup.number(),
  prog_jan: Yup.number(),
  prog_jul: Yup.number(),
  prog_jun: Yup.number(),
  prog_mar: Yup.number(),
  prog_may: Yup.number(),
  prog_nov: Yup.number(),
  prog_oct: Yup.number(),
  prog_sep: Yup.number(),
});


const modalsRoot = document.getElementById('root-modals') || document.body

const ModalUpdatePlant = ({show, handleClose, id, data}: Props) => {
  const { toggleToast } = useToast();
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [loading, setLoading] = useState(false)
  const [initialValues] = useState<any>({
    value_implementation: data.value_implementation ? data.value_implementation : 0,
    annual_generation_forecast: data.annual_generation_forecast ? data.annual_generation_forecast : 0,

    prog_abr: data && data.prognosis && data.prognosis.prog_abr ? data.prognosis.prog_abr : 0,
    prog_ago: data && data.prognosis && data.prognosis.prog_ago ? data.prognosis.prog_ago : 0,
    prog_dez: data && data.prognosis && data.prognosis.prog_dez ? data.prognosis.prog_dez : 0,
    prog_fev: data && data.prognosis && data.prognosis.prog_fev ? data.prognosis.prog_fev : 0,
    prog_jan: data && data.prognosis && data.prognosis.prog_jan ? data.prognosis.prog_jan : 0,
    prog_jul: data && data.prognosis && data.prognosis.prog_jul ? data.prognosis.prog_jul : 0,
    prog_jun: data && data.prognosis && data.prognosis.prog_jun ? data.prognosis.prog_jun : 0,
    prog_mar: data && data.prognosis && data.prognosis.prog_mar ? data.prognosis.prog_mar : 0,
    prog_may: data && data.prognosis && data.prognosis.prog_may ? data.prognosis.prog_may : 0,
    prog_nov: data && data.prognosis && data.prognosis.prog_nov ? data.prognosis.prog_nov : 0,
    prog_oct: data && data.prognosis && data.prognosis.prog_oct ? data.prognosis.prog_oct : 0,
    prog_sep: data && data.prognosis && data.prognosis.prog_sep ? data.prognosis.prog_sep : 0,
  })
  const {width} = useSize()
  
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const closed = () => {
    handleClose()
  }

  const updatePlantSubmit = async () => {
    try {
      setLoading(true)
      const body = {
        value_implementation: formik.values.value_implementation,
        annual_generation_forecast: formik.values.annual_generation_forecast
      };

      const bodyV2 = {
        prog_abr: formik.values.prog_abr ? formik.values.prog_abr : 0,
        prog_ago: formik.values.prog_ago ? formik.values.prog_ago : 0,
        prog_dez: formik.values.prog_dez ? formik.values.prog_dez : 0,
        prog_fev: formik.values.prog_fev ? formik.values.prog_fev : 0,
        prog_jan: formik.values.prog_jan ? formik.values.prog_jan : 0,
        prog_jul: formik.values.prog_jul ? formik.values.prog_jul : 0,
        prog_jun: formik.values.prog_jun ? formik.values.prog_jun : 0,
        prog_mar: formik.values.prog_mar ? formik.values.prog_mar : 0,
        prog_may: formik.values.prog_may ? formik.values.prog_may : 0,
        prog_nov: formik.values.prog_nov ? formik.values.prog_nov : 0,
        prog_oct: formik.values.prog_oct ? formik.values.prog_oct : 0,
        prog_sep: formik.values.prog_sep ? formik.values.prog_sep : 0,
        idPlant: id ? id : ""
      }
      await putPlantOne(id, body);
      
      if(data && data.prognosis){
        await putPrognosisOne(data.prognosis.id, bodyV2);
      }else{
        await postPrognosisOne(bodyV2)
      }
      

      toggleToast("Sucesso ao atualizar as informaçoes usina. ", "success")

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao atualizar as informações da usina. ", "error")
    }finally{
      setLoading(false)
      closed()
      // handleClose()
    //   setTimeout(()=>{
    //     window.location.reload()
    //   }, 2000);
    }
  }

  const submit = () => {
    updatePlantSubmit()
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: submit,
  });

  useEffect(() => {
    formik.setValues({
      value_implementation: data && data.value_implementation ? data.value_implementation : 0,
      annual_generation_forecast: data && data.annual_generation_forecast ? data.annual_generation_forecast : 0,

      prog_abr: data && data.prognosis && data.prognosis.prog_abr ? data.prognosis.prog_abr : 0,
      prog_ago: data && data.prognosis && data.prognosis.prog_ago ? data.prognosis.prog_ago : 0,
      prog_dez: data && data.prognosis && data.prognosis.prog_dez ? data.prognosis.prog_dez : 0,
      prog_fev: data && data.prognosis && data.prognosis.prog_fev ? data.prognosis.prog_fev : 0,
      prog_jan: data && data.prognosis && data.prognosis.prog_jan ? data.prognosis.prog_jan : 0,
      prog_jul: data && data.prognosis && data.prognosis.prog_jul ? data.prognosis.prog_jul : 0,
      prog_jun: data && data.prognosis && data.prognosis.prog_jun ? data.prognosis.prog_jun : 0,
      prog_mar: data && data.prognosis && data.prognosis.prog_mar ? data.prognosis.prog_mar : 0,
      prog_may: data && data.prognosis && data.prognosis.prog_may ? data.prognosis.prog_may : 0,
      prog_nov: data && data.prognosis && data.prognosis.prog_nov ? data.prognosis.prog_nov : 0,
      prog_oct: data && data.prognosis && data.prognosis.prog_oct ? data.prognosis.prog_oct : 0,
      prog_sep: data && data.prognosis && data.prognosis.prog_sep ? data.prognosis.prog_sep : 0,
    })
   }, [show]);

   useEffect(() => {
    if(
      formik.values.prog_abr ||
      formik.values.prog_ago ||
      formik.values.prog_dez ||
      formik.values.prog_fev ||
      formik.values.prog_jan ||
      formik.values.prog_jul ||
      formik.values.prog_jun ||
      formik.values.prog_mar ||
      formik.values.prog_may ||
      formik.values.prog_nov ||
      formik.values.prog_oct ||
      formik.values.prog_sep
    ){
      formik.setValues({...formik.values, annual_generation_forecast : 
        formik.values.prog_abr
        + formik.values.prog_ago
        + formik.values.prog_dez
        + formik.values.prog_fev
        + formik.values.prog_jan
        + formik.values.prog_jul
        + formik.values.prog_jun
        + formik.values.prog_mar
        + formik.values.prog_may
        + formik.values.prog_nov
        + formik.values.prog_oct
        + formik.values.prog_sep
      })
    }
    
   }, [
    formik.values.prog_abr,
    formik.values.prog_ago,
    formik.values.prog_dez,
    formik.values.prog_fev,
    formik.values.prog_jan,
    formik.values.prog_jul,
    formik.values.prog_jun,
    formik.values.prog_mar,
    formik.values.prog_may,
    formik.values.prog_nov,
    formik.values.prog_oct,
    formik.values.prog_sep,
   ]);

  // useEffect(() => {
  //   formik.setValues({...formik.values, prog_jan: formik.values.annual_generation_forecast / 12})
  //   formik.setValues({...formik.values, prog_fev: formik.values.annual_generation_forecast / 12})
  //   formik.setValues({...formik.values, prog_mar: formik.values.annual_generation_forecast / 12})
  //   formik.setValues({...formik.values, prog_abr: formik.values.annual_generation_forecast / 12})
  //   formik.setValues({...formik.values, prog_may: formik.values.annual_generation_forecast / 12})
  //   formik.setValues({...formik.values, prog_jun: formik.values.annual_generation_forecast / 12})
  //   formik.setValues({...formik.values, prog_jul: formik.values.annual_generation_forecast / 12})
  //   formik.setValues({...formik.values, prog_ago: formik.values.annual_generation_forecast / 12})
  //   formik.setValues({...formik.values, prog_sep: formik.values.annual_generation_forecast / 12})
  //   formik.setValues({...formik.values, prog_oct: formik.values.annual_generation_forecast / 12})
  //   formik.setValues({...formik.values, prog_nov: formik.values.annual_generation_forecast / 12})
  //   formik.setValues({...formik.values, prog_dez: formik.values.annual_generation_forecast / 12})

  // }, [formik.values.annual_generation_forecast])


  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-800px'
      show={show}
      onHide={closed}
      onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Configurações da Usina {" - "}{data && data.name ? data.name : "Não identificado"}</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-5'>( {unitBranch.name} )</span> */}
        </h3>

        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        {/*begin::Stepper */}
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >
         
          {/*begin::Content */}
          <div className='flex-row-fluid py-lg-0'>
            <div style={{marginBottom: '3rem'}}>
                
                {/* <h3 className='card-title align-items-start flex-column'>
                    <span className='text-muted mt-1 fw-semibold fs-5'>Deseja deletar esse cliente permanentemente?</span>
                </h3> */}

                <div className='fv-row' style={{marginBottom: '1.5rem'}}>
                  <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                      <span>Total investido (R$)</span>
                      <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Valor da implantação da usina'
                      ></i>
                  </label>
                  <input
                      type='number'
                      className='form-control form-control-lg'
                      name='value_implementation'
                      placeholder=''
                      value={formik.values.value_implementation}
                      onChange={formik.handleChange("value_implementation")}
                  />
                </div>

                <div className='fv-row'>
                  <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                      <span>Geração Anual Prevista (KWh)</span>
                      <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Valor da implantação da usina'
                      ></i>
                  </label>
                  <input
                      type='number'
                      className='form-control form-control-lg'
                      name='annual_generation_forecast'
                      placeholder=''
                      value={formik.values.annual_generation_forecast}
                      onChange={formik.handleChange("annual_generation_forecast")}
                  />
                </div>

                <div className='fv-row mt-5' style={{display: 'flex', flexDirection: 'column', gap: '1rem', height: '20rem', overflow: 'auto'}}>
                  <label className='d-flex align-items-center fs-6 fw-semibold'>
                      <span>Geração Anual Dividida por Mês (KWh)</span>
                      <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Valor da implantação da usina'
                      ></i>
                  </label>
                  
                  <div style={{display: 'flex', gap: '1rem', flexWrap: width < 1200 ? 'wrap' : 'nowrap'}}>
                    <div style={{display: 'flex', gap: '0.5rem'}}>
                      <label style={{width: '11rem'}} className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                          <span>Janeiro</span>
                      </label>
                      <input
                          type='number'
                          className='form-control form-control-lg'
                          name='prog_jan'
                          placeholder=''
                          value={formik.values.prog_jan}
                          onChange={formik.handleChange("prog_jan")}
                      />
                    </div>

                    <div style={{display: 'flex', gap: '0.5rem'}}>
                      <label style={{width: '11rem'}} className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                          <span>Fevereiro</span>
                      </label>
                      <input
                          type='number'
                          className='form-control form-control-lg'
                          name='prog_fev'
                          placeholder=''
                          value={formik.values.prog_fev}
                          onChange={formik.handleChange("prog_fev")}
                      />
                    </div>

                    <div style={{display: 'flex', gap: '0.5rem'}}>
                      <label style={{width: '11rem'}} className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                          <span>Março</span>
                      </label>
                      <input
                          type='number'
                          className='form-control form-control-lg'
                          name='prog_mar'
                          placeholder=''
                          value={formik.values.prog_mar}
                          onChange={formik.handleChange("prog_mar")}
                      />
                    </div>
                  </div>

                  <div style={{display: 'flex', gap: '1rem', flexWrap: width < 1200 ? 'wrap' : 'nowrap'}}>
                    <div style={{display: 'flex', gap: '0.5rem'}}>
                      <label style={{width: '11rem'}} className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                          <span>Abril</span>
                      </label>
                      <input
                          type='number'
                          className='form-control form-control-lg'
                          name='prog_abr'
                          placeholder=''
                          value={formik.values.prog_abr}
                          onChange={formik.handleChange("prog_abr")}
                      />
                    </div>

                    <div style={{display: 'flex', gap: '0.5rem'}}>
                      <label style={{width: '11rem'}} className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                          <span>Maio</span>
                      </label>
                      <input
                          type='number'
                          className='form-control form-control-lg'
                          name='prog_may'
                          placeholder=''
                          value={formik.values.prog_may}
                          onChange={formik.handleChange("prog_may")}
                      />
                    </div>

                    <div style={{display: 'flex', gap: '0.5rem'}}>
                      <label style={{width: '11rem'}} className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                          <span>Junho</span>
                      </label>
                      <input
                          type='number'
                          className='form-control form-control-lg'
                          name='prog_jun'
                          placeholder=''
                          value={formik.values.prog_jun}
                          onChange={formik.handleChange("prog_jun")}
                      />
                    </div>
                  </div>

                  <div style={{display: 'flex', gap: '1rem', flexWrap: width < 1200 ? 'wrap' : 'nowrap'}}>
                    <div style={{display: 'flex', gap: '0.5rem'}}>
                      <label style={{width: '11rem'}} className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                          <span>Julho</span>
                      </label>
                      <input
                          type='number'
                          className='form-control form-control-lg'
                          name='prog_jul'
                          placeholder=''
                          value={formik.values.prog_jul}
                          onChange={formik.handleChange("prog_jul")}
                      />
                    </div>
                    
                    <div style={{display: 'flex', gap: '0.5rem'}}>
                      <label style={{width: '11rem'}} className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                          <span>Agosto</span>
                      </label>
                      <input
                          type='number'
                          className='form-control form-control-lg'
                          name='prog_ago'
                          placeholder=''
                          value={formik.values.prog_ago}
                          onChange={formik.handleChange("prog_ago")}
                      />
                    </div>

                    <div style={{display: 'flex', gap: '0.5rem'}}>
                      <label style={{width: '11rem'}} className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                          <span>Setembro</span>
                      </label>
                      <input
                          type='number'
                          className='form-control form-control-lg'
                          name='prog_sep'
                          placeholder=''
                          value={formik.values.prog_sep}
                          onChange={formik.handleChange("prog_sep")}
                      />
                    </div>
                  </div>

                  <div style={{display: 'flex', gap: '1rem', flexWrap: width < 1200 ? 'wrap' : 'nowrap'}}>
                    <div style={{display: 'flex', gap: '0.5rem'}}>
                      <label style={{width: '11rem'}} className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                          <span>Outubro</span>
                      </label>
                      <input
                          type='number'
                          className='form-control form-control-lg'
                          name='prog_oct'
                          placeholder=''
                          value={formik.values.prog_oct}
                          onChange={formik.handleChange("prog_oct")}
                      />
                    </div>

                    <div style={{display: 'flex', gap: '0.5rem'}}>
                      <label style={{width: '11rem'}} className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                          <span>Novembro</span>
                      </label>
                      <input
                          type='number'
                          className='form-control form-control-lg'
                          name='prog_nov'
                          placeholder=''
                          value={formik.values.prog_nov}
                          onChange={formik.handleChange("prog_nov")}
                      />
                    </div>
                    
                    <div style={{display: 'flex', gap: '0.5rem'}}>
                      <label style={{width: '11rem'}} className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                          <span>Dezembro</span>
                      </label>
                      <input
                          type='number'
                          className='form-control form-control-lg'
                          name='prog_dez'
                          placeholder=''
                          value={formik.values.prog_dez}
                          onChange={formik.handleChange("prog_dez")}
                      />
                    </div>
                  </div>
                </div>


            </div>
            {/*begin::Form */}
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <button
                    type='button'
                    className='btn btn-lg btn-secondary'
                    disabled={loading}
                    onClick={() => {
                        closed()
                    }}
                    //data-kt-stepper-action='submit'
                >
                    <KTSVG
                        path='/media/icons/duotune/arrows/arr063.svg'
                        className='svg-icon-3 ms-2 me-0'
                    />
                    <span style={{marginLeft: '0.5rem'}}>Cancelar</span>
                    
                </button>

                <button
                    type='button'
                    className='btn btn-lg btn-primary'
                    disabled={loading}
                    //data-kt-stepper-action='submit'
                    onClick={submit}
                >
                    Atualizar
                    {
                        loading ? 
                        <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                        :
                        <KTSVG
                            path='/media/icons/duotune/arrows/arr064.svg'
                            className='svg-icon-3 ms-2 me-0'
                        />
                    }
                </button>
            </div>
            {/*end::Form */}
          </div>
          {/*end::Content */}
        </div>
        {/* end::Stepper */}
      </div>
    </Modal>,
    modalsRoot
  )
}

export {ModalUpdatePlant}
