import React, { useEffect, useState } from 'react';
import DateSelector from '../../../../TableReports/components/DateInput';
import DateSelectorDays from '../../../../../components/DateInputDays';

type Props = {
  initialHtml: string,
  formik: any
}

const StepModalValuesV2 = ({initialHtml, formik} : Props) => {
    const [largura, setLargura] = useState(window.innerWidth);
    //const [dateInitial, setDateInitial] = useState(new Date());
    // const [editorStateV2, setEditorStateV2] = useState(() => {
    //     if(initialHtml){
    //       return { __html: initialHtml };
    //     }
    //     return { __html: '' };
    // });

    const atualizarTamanhoDaTela = () => {
        setLargura(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', atualizarTamanhoDaTela);
        return () => {
          window.removeEventListener('resize', atualizarTamanhoDaTela);
        };
    }, []);

    return (
        <div className='overflow-auto bg-light' style={{ display: 'flex', gap: '2rem', height: '50rem', padding: '1rem', width: '100%'}}>
            <div style={{ flex: '1', height: '85%' }}>
                <div>
                    <h3 className='align-items-start flex-column' style={{display: "flex", marginBottom: '2rem'}}>
                        <span className='card-label fw-bold fs-4 mb-1'>Datas do Relatório</span>
                        <span className='text-muted fw-semibold fs-7'>Referente ao mês referencia e período de análise</span>
                    </h3>

                    <div style={{display: 'flex', gap: '1rem', flexDirection: largura < 1200 ? 'column' : 'row'}}>
                        <div>
                            <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                                <span className='required'>Mês Referência</span>
                                <i
                                    className='fas fa-exclamation-circle ms-2 fs-7'
                                    data-bs-toggle='tooltip'
                                    title='Mês referênte ao período de avaliação'
                                ></i>
                            </label>
                            <DateSelector 
                                selectedDate={formik.values.data}
                                setSelectedDate={(date: any) => {
                                    //setDateInitial(date)
                                    formik.setValues({...formik.values, data: date})
                                }}
                            />
                        </div>
                        
                        <div className='fv-row'>
                            <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                                <span className='required'>Inicio Período</span>
                                <i
                                className='fas fa-exclamation-circle ms-2 fs-7'
                                data-bs-toggle='tooltip'
                                title='Início do período de análise do relatório'
                                ></i>
                            </label>

                            <DateSelectorDays 
                                selectedDate={new Date(formik.values.initPeriod)}
                                setSelectedDate={(date: any) => {
                                    formik.setValues({...formik.values, initPeriod: date.toString()})
                                }}
                            />

                            {/* <input
                                type='text'
                                className='form-control form-control-lg'
                                name='initPeriod'
                                placeholder=''
                                value={formik.values.initPeriod}
                                onChange={formik.handleChange("initPeriod")}
                            /> */}
                        </div>

                        <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                                <span className='required'>Fim Período</span>
                                <i
                                className='fas fa-exclamation-circle ms-2 fs-7'
                                data-bs-toggle='tooltip'
                                title='Fim do período de análise do relatório'
                                ></i>
                            </label>
                            
                            <DateSelectorDays 
                                selectedDate={new Date(formik.values.endPeriod)}
                                setSelectedDate={(date: any) => {
                                    formik.setValues({...formik.values, endPeriod: date.toString()})
                                }}
                            />
                            {/* <input
                                type='text'
                                className='form-control form-control-lg'
                                name='endPeriod'
                                placeholder=''
                                value={formik.values.endPeriod}
                                onChange={formik.handleChange("endPeriod")}
                            /> */}
                        </div>
                    </div>

                    {/* <h3 className='align-items-start flex-column' style={{display: "flex", marginBottom: '2rem'}}>
                        <span className='card-label fw-bold fs-4 mb-1'>Dados da Unidade Geradora</span>
                        <span className='text-muted fw-semibold fs-7'>Dados referente a geração e consumo da unidade geradora</span>
                    </h3>

                    <div style={{display: 'flex', gap: '1rem', flexDirection: 'column', marginBottom: '2.5rem'}}>
                        
                        <div style={{display: 'flex', gap: '1rem'}}>
                            <div className='fv-row'>
                                <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                                    <span className='required'>Consumo Registrado (KWh)</span>
                                    <i
                                    className='fas fa-exclamation-circle ms-2 fs-7'
                                    data-bs-toggle='tooltip'
                                    title='Consumo mostrado pela concessionária'
                                    ></i>
                                </label>
                                <input
                                    type='text'
                                    className='form-control form-control-lg'
                                    name='consumerRegister'
                                    placeholder=''
                                    value={formik.values.consumerRegister}
                                    onChange={formik.handleChange("consumerRegister")}
                                />
                            </div>

                            <div className='fv-row'>
                                <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                                    <span className='required'>Consumo Intantâneo (KWh)</span>
                                    <i
                                    className='fas fa-exclamation-circle ms-2 fs-7'
                                    data-bs-toggle='tooltip'
                                    title='Consumo por fora da concessionária'
                                    ></i>
                                </label>
                                <input
                                    type='text'
                                    className='form-control form-control-lg'
                                    name='consumerInstant'
                                    placeholder=''
                                    value={formik.values.consumerInstant}
                                    onChange={formik.handleChange("consumerInstant")}
                                />
                            </div>
                        </div>
                        
                        <div style={{display: 'flex', gap: '1rem'}}>
                            <div className='fv-row'>
                                <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                                    <span className='required'>Consumo Total (KWh)</span>
                                    <i
                                    className='fas fa-exclamation-circle ms-2 fs-7'
                                    data-bs-toggle='tooltip'
                                    title='Consumo da unidade geradora no periodo'
                                    ></i>
                                </label>
                                <input
                                    type='text'
                                    className='form-control form-control-lg'
                                    name='unitConsumerTotal'
                                    placeholder=''
                                    value={formik.values.unitConsumerTotal}
                                    onChange={formik.handleChange("unitConsumerTotal")}
                                />
                            </div>

                            <div className='fv-row'>
                                <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                                    <span className='required'>Energia Injetada (KWh)</span>
                                    <i
                                    className='fas fa-exclamation-circle ms-2 fs-7'
                                    data-bs-toggle='tooltip'
                                    title='Energia injetada no periodo'
                                    ></i>
                                </label>
                                <input
                                    type='text'
                                    className='form-control form-control-lg'
                                    name='energyInjected'
                                    placeholder=''
                                    value={formik.values.energyInjected}
                                    onChange={formik.handleChange("energyInjected")}
                                />
                            </div>
                        </div>
                    </div> */}


                    <h3 className='align-items-start flex-column' style={{display: "flex", marginBottom: '2rem'}}>
                        <span className='card-label fw-bold fs-4 mb-1'>Dados Gerados</span>
                        <span className='text-muted fw-semibold fs-7'>Dados referente a geração de energia no período selecionado</span>
                    </h3>

                    <div style={{display: 'flex', gap: '1rem', flexDirection: 'column', marginBottom: '2.5rem'}}>
                        
                        <div style={{display: 'flex', gap: '1rem'}}>
                            <div className='fv-row'>
                                <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                                    <span className='required'>Geração - mês (KWh)</span>
                                    <i
                                    className='fas fa-exclamation-circle ms-2 fs-7'
                                    data-bs-toggle='tooltip'
                                    title='Energia total gerada no período selecionado'
                                    ></i>
                                </label>
                                <input
                                    type='text'
                                    className='form-control form-control-lg'
                                    name='generation'
                                    placeholder=''
                                    value={formik.values.generation}
                                    onChange={formik.handleChange("generation")}
                                />
                            </div>

                            <div className='fv-row'>
                                <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                                    <span className='required'>Geração - Ult. 12 meses (KWh)</span>
                                    <i
                                    className='fas fa-exclamation-circle ms-2 fs-7'
                                    data-bs-toggle='tooltip'
                                    title='Geração referente aos ultimos 12 meses'
                                    ></i>
                                </label>
                                <input
                                    type='text'
                                    className='form-control form-control-lg'
                                    name='generation_anual'
                                    placeholder=''
                                    value={formik.values.generation_anual}
                                    onChange={formik.handleChange("generation_anual")}
                                />
                            </div>
                        </div>
                        
                        <div style={{display: 'flex', gap: '1rem'}}>
                            <div className='fv-row'>
                                <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                                    <span className='required'>Performace - mês (%)</span>
                                    <i
                                    className='fas fa-exclamation-circle ms-2 fs-7'
                                    data-bs-toggle='tooltip'
                                    title='Performace referente ao período selecionado'
                                    ></i>
                                </label>
                                <input
                                    type='text'
                                    className='form-control form-control-lg'
                                    name='performance'
                                    placeholder=''
                                    value={formik.values.performance}
                                    onChange={formik.handleChange("performance")}
                                />
                            </div>

                            <div className='fv-row'>
                                <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                                    <span className='required'>Performace - Ult. 12 meses (%)</span>
                                    <i
                                    className='fas fa-exclamation-circle ms-2 fs-7'
                                    data-bs-toggle='tooltip'
                                    title='Performance referente aos ultimos 12 meses'
                                    ></i>
                                </label>
                                <input
                                    type='text'
                                    className='form-control form-control-lg'
                                    name='performance_anual'
                                    placeholder=''
                                    value={formik.values.performance_anual}
                                    onChange={formik.handleChange("performance_anual")}
                                />
                            </div>
                        </div>

                        <div style={{display: 'flex', gap: '1rem'}}>
                            <div className='fv-row'>
                                <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                                    <span className='required'>Potência (KWp)</span>
                                    <i
                                    className='fas fa-exclamation-circle ms-2 fs-7'
                                    data-bs-toggle='tooltip'
                                    title='Potência da usina instalada'
                                    ></i>
                                </label>
                                <input
                                    type='text'
                                    className='form-control form-control-lg'
                                    name='capacity'
                                    placeholder=''
                                    value={formik.values.capacity}
                                    onChange={formik.handleChange("capacity")}
                                />
                            </div>

                            <div className='fv-row'>
                                <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                                    <span className='required'>Prognóstico - Ult. 12 meses (KWh)</span>
                                    <i
                                    className='fas fa-exclamation-circle ms-2 fs-7'
                                    data-bs-toggle='tooltip'
                                    title='Prognóstico referente aos ultimos 12 meses'
                                    ></i>
                                </label>
                                <input
                                    type='text'
                                    className='form-control form-control-lg'
                                    name='prog_12_meses'
                                    placeholder=''
                                    value={formik.values.prog_12_meses}
                                    onChange={formik.handleChange("prog_12_meses")}
                                />
                            </div>  
                        </div>

                        {/* <div style={{display: 'flex', gap: '1rem'}}>

                            <div className='fv-row'>
                                <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                                    <span className='required'>Retorno financeiro Total (%)</span>
                                    <i
                                    className='fas fa-exclamation-circle ms-2 fs-7'
                                    data-bs-toggle='tooltip'
                                    title='% recuperada no total, sobre o valor investido na usina'
                                    ></i>
                                </label>
                                <input
                                    type='text'
                                    className='form-control form-control-lg'
                                    name='financial_feedback_year'
                                    placeholder=''
                                    value={formik.values.financial_feedback_year}
                                    onChange={formik.handleChange("financial_feedback_year")}
                                />
                            </div> 

                            <div className='fv-row'>
                                <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                                    <span className='required'>Economizado Total (R$)</span>
                                    <i
                                    className='fas fa-exclamation-circle ms-2 fs-7'
                                    data-bs-toggle='tooltip'
                                    title='Valor total economizado com a geração da usina'
                                    ></i>
                                </label>
                                <input
                                    type='text'
                                    className='form-control form-control-lg'
                                    name='total_economy'
                                    placeholder=''
                                    value={formik.values.total_economy}
                                    onChange={formik.handleChange("total_economy")}
                                />
                            </div> 
                        </div> */}
                    </div>

                    <h3 className='align-items-start flex-column' style={{display: "flex", marginBottom: '2rem'}}>
                        <span className='card-label fw-bold fs-4 mb-1'>Informações da Empresa / Filial</span>
                        <span className='text-muted fw-semibold fs-7'>Dados disponibilzados da empresa e da filial vinculada a usina</span>
                    </h3>

                    <div style={{display: 'flex', gap: '1rem', flexDirection: 'column'}}>
                        
                        <div className='fv-row'>
                            <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                                <span className='required'>Nome da Empresa</span>
                                <i
                                className='fas fa-exclamation-circle ms-2 fs-7'
                                data-bs-toggle='tooltip'
                                title='Nome referente a empresa principal'
                                ></i>
                            </label>
                            <input
                                type='text'
                                className='form-control form-control-lg'
                                name='corporateName'
                                placeholder=''
                                value={formik.values.corporateName}
                                onChange={formik.handleChange("corporateName")}
                            />
                        </div>

                        {/* <div className='fv-row'>
                            <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                                <span className='required'>E-mail da Empresa</span>
                                <i
                                className='fas fa-exclamation-circle ms-2 fs-7'
                                data-bs-toggle='tooltip'
                                title='E-mail referente à empresa principal'
                                ></i>
                            </label>
                            <input
                                type='text'
                                className='form-control form-control-lg'
                                name='username'
                                placeholder=''
                            />
                        </div> */}

                        <div className='fv-row'>
                            <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                                <span className='required'>CNPJ da Empresa</span>
                                <i
                                className='fas fa-exclamation-circle ms-2 fs-7'
                                data-bs-toggle='tooltip'
                                title='CNPJ referente à empresa principal'
                                ></i>
                            </label>
                            <input
                                type='text'
                                className='form-control form-control-lg'
                                name='cnpj'
                                placeholder=''
                                value={formik.values.cnpj}
                                onChange={formik.handleChange("cnpj")}
                            />
                        </div>

                        <div style={{display: 'flex', gap: '1rem'}}>
                            <div className='fv-row mb-10'>
                                <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                                    <span className='required'>Nome da Filial</span>
                                    <i
                                    className='fas fa-exclamation-circle ms-2 fs-7'
                                    data-bs-toggle='tooltip'
                                    title='CNPJ referente à empresa principal'
                                    ></i>
                                </label>
                                <input
                                    type='text'
                                    className='form-control form-control-lg'
                                    name='companyName'
                                    placeholder=''
                                    value={formik.values.companyName}
                                    onChange={formik.handleChange("companyName")}
                                />
                            </div>
                            <div className='fv-row mb-10'>
                                <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                                    <span className='required'>E-mail da Filial</span>
                                    <i
                                    className='fas fa-exclamation-circle ms-2 fs-7'
                                    data-bs-toggle='tooltip'
                                    title='CNPJ referente à empresa principal'
                                    ></i>
                                </label>
                                <input
                                    type='text'
                                    className='form-control form-control-lg'
                                    name='emailCompany'
                                    placeholder=''
                                    value={formik.values.emailCompany}
                                    onChange={formik.handleChange("emailCompany")}
                                />
                            </div>
                        </div>

                    </div>

                    <h3 className='align-items-start flex-column' style={{display: "flex", marginBottom: '2rem'}}>
                        <span className='card-label fw-bold fs-4 mb-1'>Informações da Usina</span>
                        <span className='text-muted fw-semibold fs-7'>Dados disponibilzados da usina vinculada ao relatório</span>
                    </h3>

                    <div style={{display: 'flex', gap: '1rem', flexDirection: 'column'}}>
                        
                        <div className='fv-row'>
                            <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                                <span className='required'>Nome da Usina</span>
                                <i
                                className='fas fa-exclamation-circle ms-2 fs-7'
                                data-bs-toggle='tooltip'
                                title='Nome referente a usina vinculada ao relatório'
                                ></i>
                            </label>
                            <input
                                type='text'
                                className='form-control form-control-lg'
                                name='namePlant'
                                placeholder=''
                                value={formik.values.namePlant}
                                onChange={formik.handleChange("namePlant")}
                            />
                        </div>

                        <div className='fv-row'>
                            <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                                <span className='required'>Endereço da Usina</span>
                                <i
                                className='fas fa-exclamation-circle ms-2 fs-7'
                                data-bs-toggle='tooltip'
                                title='Endereço referente à usina vinculada ao relatório'
                                ></i>
                            </label>
                            <input
                                type='text'
                                className='form-control form-control-lg'
                                name='adressPlant'
                                placeholder=''
                                value={formik.values.adressPlant}
                                onChange={formik.handleChange("adressPlant")}
                            />
                        </div>

                        <div className='fv-row' style={{marginBottom: '2rem'}}>
                            <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                                <span className='required'>Portal da Usina</span>
                                <i
                                className='fas fa-exclamation-circle ms-2 fs-7'
                                data-bs-toggle='tooltip'
                                title='Portal na qual a usina do relatório é vinculada'
                                ></i>
                            </label>
                            <input
                                type='text'
                                className='form-control form-control-lg'
                                name='mark'
                                placeholder=''
                                value={formik.values.mark}
                                onChange={formik.handleChange("mark")}
                            />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default StepModalValuesV2;