import html2pdf from 'html2pdf.js';
// import { url } from 'inspector';
// import jsPDF from 'jspdf';
// import html2canvas from 'html2canvas';

//---------------------------- Versão 1 --------------------------------------
// export const linkPDF = async (myHTML: string): Promise<string> => {
//   return new Promise((resolve, reject) => {
//     var pdf = new jsPDF({
//       unit: 'mm',
//       format: 'a4',
//       orientation: 'portrait',
//     });

//     pdf.html(myHTML, {
//       callback: function (pdf) {
//         // Converta o Blob do PDF em um URL
//         var pdfBlob = pdf.output("blob");
//         var url = URL.createObjectURL(pdfBlob);

//         // Resolva a promise com o URL
//         resolve(url);
//       },
//       // Você pode adicionar mais opções aqui, se necessário
//     });
//   });
// };

//---------------------------Versão 2-------------------------------------------
// export const linkPDF = async (myHTML: string) => {
//   try {
//     const parser = new DOMParser();
//     const myDocument = parser.parseFromString("<div id='report_template'>"+myHTML+"</div>", 'text/html')
//     const content = myDocument.getElementById(`report_template`);

//     const options = {
//       margin: 10,
//       filename: 'documento.pdf',
//       image: { type: 'jpeg', quality: 1.0 },
//       html2canvas: { scale: 2, useCORS: true },
//       jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
//     };

//     let pdfBlob = await html2pdf().set(options).from(content).toPdf().output('blob').then( (data: Blob) => {
//       return data
//     })

//     const url = URL.createObjectURL(pdfBlob);

//     return url;
//   } catch (error) {
//     throw new Error(`Erro ao gerar PDF: ${error}`);
//   }
// };
//-----------------------------------Versão 3 ---------------------------------------

// export const linkPDF = async (myHTML: string) => {
//   try {
//     const parser = new DOMParser();
//     const myDocument = parser.parseFromString("<div id='report_template'>"+myHTML+"</div>", 'text/html')
//     const content = myDocument.getElementById('report_template');

//     // Encontre todas as imagens no conteúdo
//     const images = content!.querySelectorAll('img');

//     // Pré-carregue cada imagem
//     await Promise.all(Array.from(images).map(async (img) => {
//       const src = img.getAttribute('src');
//       if (src && src.startsWith('http')) {
//         // Se a imagem for referenciada por uma URL externa, pré-carregue antes de gerar o PDF
//         await new Promise((resolve) => {
//           const image = new Image();
//           image.onload = resolve;
//           image.src = src;
//         });
//       }
//     }));

//     const options = {
//       margin: 10,
//       filename: 'documento.pdf',
//       image: { type: 'jpeg', quality: 0.98 },
//       html2canvas: { scale: 2 },
//       jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
//     };

//     const pdfBlob = await html2pdf().from(content).set(options).outputPdf();
//     const pdfBuffer = await pdfBlob.arrayBuffer();  // Converta o Blob para ArrayBuffer
//     const pdfData = new Uint8Array(pdfBuffer);

//     // Crie um Blob com o ArrayBuffer
//     const pdfBlobWithBuffer = new Blob([pdfData], { type: 'application/pdf' });

//     // Crie o URL do objeto usando a função createObjectURL
//     const url = URL.createObjectURL(pdfBlobWithBuffer);

//     return url;
//   } catch (error) {
//     throw new Error(`Erro ao gerar PDF: ${error}`);
//   }
// };

//----------------------------Versão 4 --------------------------------

// export const linkPDF = async (myHTML: string) => {
//   try {
//     const parser = new DOMParser();
//     const myDocument = parser.parseFromString("<div id='report_template'>"+myHTML+"</div>", 'text/html');
//     const content = myDocument.getElementById('report_template');

//     // Use html2canvas para renderizar o conteúdo em um canvas
//     const canvas = await html2canvas(content!);

//     // Converta o canvas para um Blob
//     const pdfBlob = await new Promise<Blob>((resolve) => {
//       canvas.toBlob((blob) => {
//         resolve(blob as Blob);
//       }, 'application/pdf');
//     });

//     // Crie o URL do objeto usando a função createObjectURL
//     const url = URL.createObjectURL(pdfBlob);

//     return url;
//   } catch (error) {
//     throw new Error(`Erro ao gerar PDF: ${error}`);
//   }
// };


//-------------------Versão 5 -------------------------------------
// export const linkPDF = async (myHTML: string) => {
//   try {
//     const browser = await puppeteer.launch();
//     const page = await browser.newPage();

//     // Carregue o HTML diretamente na página do Puppeteer
//     await page.setContent(myHTML);

//     // Gere o PDF a partir do conteúdo HTML
//     const pdfBuffer = await page.pdf();

//     // Feche o navegador
//     await browser.close();

//     // Crie o URL do objeto usando a função createObjectURL
//     const url = URL.createObjectURL(new Blob([pdfBuffer], { type: 'application/pdf' }));

//     return url;
//   } catch (error) {
//     throw new Error(`Erro ao gerar PDF: ${error}`);
//   }
// };

//-------------------Versão 6 ---------------------------

export const linkPDF = async (myHTML: string) => {
  try {
    const parser = new DOMParser();
    const myDocument = parser.parseFromString("<div id='report_template'>"+myHTML+"</div>", 'text/html')
    const content = myDocument.getElementById(`report_template`);

    const options = {
      margin: 3,
      filename: 'documento.pdf',
      image: { type: 'jpeg', quality: 1.0 },
      html2canvas: { scale: 2, useCORS: true, logging: true },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };

    let pdfBlob = await html2pdf().set(options).from(content).toPdf().output('blob').then( (data: Blob) => {
      return data
    })

    const url = URL.createObjectURL(pdfBlob);

    return url;
  } catch (error) {
    throw new Error(`Erro ao gerar PDF: ${error}`);
  }
};


export const generatePDF = (myHTML: string, id: string) => {

    const parser = new DOMParser();
    const myDocument = parser.parseFromString(myHTML, 'text/html')

    const content = myDocument.getElementById(`${id}`);
    
    // const content = document.getElementById('report-content');
    
    const pdfOptions = {
      margin: 3,
      filename: 'documento.pdf',
      image: { type: 'jpeg', quality: 1.0 },
      html2canvas: { scale: 2, useCORS: true, logging: true },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };

    const pdf = html2pdf().from(content).set(pdfOptions)

    pdf.outputPdf(pdf => {
      const blob = new Blob([pdf], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);

      // Crie um link temporário e clique nele para abrir o PDF em uma nova guia
      const a = document.createElement('a');
      a.href = url;
      a.target = '_blank'; // Abre o PDF em uma nova guia
      a.rel = 'noopener noreferrer'; // Melhora a segurança
      a.click();
    }).save()
  };