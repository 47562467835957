import axios from 'axios';
import { getAuth } from '../modules/auth';

const auth = getAuth()

const api = axios.create({
  //baseURL: 'http://localhost:3333/api/v1/'
  baseURL: 'https://dash.solarvision.app/api/v1/'
  //baseURL: process.env.REACT_APP_API_URL
});

if (auth?.api_token) {
  api.defaults.headers.common['Authorization'] = `${auth?.api_token}`;
}

api.interceptors.request.use((config) => {
  return config;
});

export default api;
