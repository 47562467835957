/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import { useFormik } from 'formik'
import * as Yup from "yup";
import { StepModal } from './components/stepModal'
import { useToast } from '../../../../../components/Toats';
import { StepperComponent } from '../../../../../../../_metronic/assets/ts/components';
import { KTSVG } from '../../../../../../../_metronic/helpers';
import { LoadingSpan } from '../../../../../components/Loadings/loadingSpan';
import { postProposalsOne, putProposalOne } from '../../../../../../services/proposalsService';

type DataForm = {
  description: string | null,
  title: string | null,
  address: string | null,
  dateInit: string | Date | null,
  dateEnd: string | Date | null,
  idClient: string | null,
  idUnitBranch: string | null,
  idPlant: string | null,
  idsCollaborator: string[] | [],
  idPhaseFunnel: string | null,
}

type Props = {
    id?: string;
    data?: DataForm;
    show: boolean
    handleClose: () => void
}

const validationSchema = Yup.object().shape({
  description: Yup.string(),
  title: Yup.string(),
  address: Yup.string(),
  dateInit: Yup.date(),
  dateEnd: Yup.date(),
  idClient: Yup.string(),
  idUnitBranch: Yup.string(),
  idPlant: Yup.string(),
  idsCollaborator: Yup.array()
  .of(Yup.string())
  .notRequired()
  .default([]),
  idPhaseFunnel: Yup.string(),
});

const modalsRoot = document.getElementById('root-modals') || document.body

const ModalAddUpdateProposal = ({show, handleClose, data, id}: Props) => {
  const { toggleToast } = useToast();
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)

  const [initialValues, setInitialValues] = useState<DataForm>({
    description: null,
    title: null,
    address: null,
    dateInit: null,
    dateEnd: null,
    idClient: null,
    idUnitBranch: null,
    idPlant: null,
    idsCollaborator: [],
    idPhaseFunnel: null,
  })
  const [hasError, setHasError] = useState(false)
  const [myError, MyError] = useState(false)
  const [loading, setLoading] = useState(false)
  
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const updateData = (fieldsToUpdate: any) => {
    const updatedData = {...initialValues, ...fieldsToUpdate}
    setInitialValues(updatedData)
  }

  // const checkInputs = (): boolean => {
  //   if (!formik.values.nameResponsible 
  //       || !formik.values.cpfResponsible 
  //       || !formik.values.email 
  //       || !formik.values.idUnitBranch) {
  //     return false
  //   }
  //   return true
  // }

  const closed = () => {
    formik.setValues(initialValues)
    handleClose()
  }

  const postProposalSubmit = async () => {
    try {
      setLoading(true)
      const myData = {
        description: formik.values.description,
        title: formik.values.title,
        address: formik.values.address,
        dateInit: formik.values.dateInit,
        dateEnd: formik.values.dateEnd,
        idClient: formik.values.idClient,
        idUnitBranch: formik.values.idUnitBranch,
        idPlant: formik.values.idPlant,
        idsCollaborator: formik.values.idsCollaborator,
        idPhaseFunnel: formik.values.idPhaseFunnel,
      }
      Object.keys(myData).forEach((prop) => {
        if (myData[prop] === null) {
          delete myData[prop];
        }
      });

      await postProposalsOne(myData)
      toggleToast("Sucesso ao cadastrar a proposta. ", "success")

    } catch (error) {
      console.log('erro',error)
      toggleToast("Erro ao cadastrar a proposta. ", "error")
    }finally{
      setLoading(false)
      closed()
    }
  }


  const updateOSSubmit = async (id: string) => {
    try {
      setLoading(true)
      const myData = {
        description: formik.values.description,
        title: formik.values.title,
        address: formik.values.address,
        dateInit: formik.values.dateInit,
        dateEnd: formik.values.dateEnd,
        idClient: formik.values.idClient,
        idUnitBranch: formik.values.idUnitBranch,
        idPlant: formik.values.idPlant,
        idsCollaborator: formik.values.idsCollaborator,
        idPhaseFunnel: formik.values.idPhaseFunnel,
      }
      Object.keys(myData).forEach((prop) => {
        if (myData[prop] === null) {
          delete myData[prop];
        }
      });
      const myOS = await putProposalOne(id, myData)
      toggleToast("Sucesso ao atualizar a proposta. ", "success")

    } catch (error) {
      console.log('erro',error)
      toggleToast("Erro ao atualizar a proposta. ", "error")
    }finally{
      setLoading(false)
      closed()
    }
  }

  const submit = () => {
    if(id){
      updateOSSubmit(id)
    }else{
        postProposalSubmit()
    }
  }

  const nextStep = () => {
    setHasError(false)
    if (!stepper.current) {
      return
    }
    // if (!checkInputs()) {
    // setHasError(true)
    // return
    // }
    submit()
    
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: nextStep,
  });


  useEffect(() => {
    if(data){
      const myObject = {
        description: data && data.description ? data.description : null,
        title: data &&  data.title ? data.title : null,
        address: data &&  data.address ? data.address : null,
        dateInit: data &&  data.dateInit ? data.dateInit : null,
        dateEnd: data &&  data.dateEnd ? data.dateEnd : null,
        idClient: data &&  data.idClient ? data.idClient : null,
        idUnitBranch: data && data.idUnitBranch ? data.idUnitBranch : null,
        idPlant: data &&  data.idPlant ? data.idPlant : null,
        idsCollaborator: data && data.idsCollaborator ? data.idsCollaborator : [],
        idPhaseFunnel: data.idPhaseFunnel ? data.idPhaseFunnel : null,

      }
      formik.setValues(myObject)
    }else{
      formik.setValues(initialValues)
    }
  }, [show])


  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-800px'
      show={show}
      onHide={closed}
      onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{id ? "Editar" : "Adicionar"} Proposta {" "}</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-5'>( {unitBranch.name} )</span> */}
        </h3>

        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >
         
          <div className='flex-row-fluid py-lg-1 px-lg-2'>
            <form noValidate id='kt_modal_create_app_form' >
              <StepModal data={initialValues} updateData={updateData} hasError={hasError} formik={formik} show={show}/>

              <div className='d-flex flex-stack pt-10' style={{justifyContent: 'end'}}>
 
                <div style={{display: 'flex', gap: '1rem'}}>
                  <button
                    type='button'
                    className='btn btn-lg btn-primary'
                    disabled={loading}
                    //data-kt-stepper-action='submit'
                    onClick={nextStep}
                  >
                    {id ? "Editar Proposta" : "Gerar Proposta" }{' '}
                    {
                      loading ? 
                        <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                      :
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-3 ms-2 me-0'
                        />
                    }
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>,
    modalsRoot
  )
}

export {ModalAddUpdateProposal}
