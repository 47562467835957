export function DateMes(date: any){
    const dataObjeto = new Date(date);
    const nomeMes = new Intl.DateTimeFormat('pt-BR', { month: 'long' }).format(dataObjeto).replace(/^\w/, (c) => c.toUpperCase());
    
    const resultado = `${nomeMes}`;
    return resultado
}

export function DateAno(date: any){
    const dataObjeto = new Date(date);
    const ano = dataObjeto.getFullYear();

    const resultado = `${ano}`;
    return resultado
}

export function DateCommon(date: any){
    const dataObjeto = new Date(date);
    const dia = dataObjeto.getDate();
    const mes = dataObjeto.getMonth() + 1;
    

    const dataFormat = `${dia}/${mes}`;
    return dataFormat
}

export function DateComplete(date: any){
    const dataObjeto = new Date(date);
    const dia = padZero(dataObjeto.getDate());
    const mes = padZero(dataObjeto.getMonth() + 1);
    const ano = padZero(dataObjeto.getFullYear());

    const dataFormat = `${dia}-${mes}-${ano}`;
    return dataFormat
}

export function DateCompleteBarra(date: any){
    const dataObjeto = new Date(date);
    const dia = padZero(dataObjeto.getDate());
    const mes = padZero(dataObjeto.getMonth() + 1);
    const ano = padZero(dataObjeto.getFullYear());

    const dataFormat = `${dia}/${mes}/${ano}`;
    return dataFormat
}

function padZero(numero: number) {
    return numero < 10 ? `0${numero}` : numero;
}

export function formatarDataParaString(data: Date) {
    const ano = data.getFullYear();
    const mes = String(data.getMonth() + 1).padStart(2, '0'); // Mês é base 0, então somamos 1
    const dia = String(data.getDate()).padStart(2, '0');
    const horas = String(data.getHours()).padStart(2, '0');
    const minutos = String(data.getMinutes()).padStart(2, '0');
  
    return `${ano}-${mes}-${dia}T${horas}:${minutos}`;
}

export function formatarData(dataISO) {
    const diasSemana = ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"];
    const meses = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];
    
    const data = new Date(dataISO);
    
    const diaSemana = diasSemana[data.getDay()];
    const dia = data.getDate();
    const mes = meses[data.getMonth()];
    const horas = data.getHours();
    const minutos = data.getMinutes();
    
    const dataFormatada = diaSemana + ", " + dia + " de " + mes + ", às " + horas + ":" + minutos + " horas";
    
    return dataFormatada;
}

export function extrairMesDia(dataString: any) {
    const partes = dataString.split("-");
    const mes = partes[1];
    const dia = partes[2];
    return `${dia}-${mes}`
    //return { mes: parseInt(mes, 10), dia: parseInt(dia, 10) };
  }


export function getShortMonthName(dateString: string) {
    const months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const date = new Date(dateString);
    const monthIndex = date.getMonth();
    return months[monthIndex];
}

export function formatDate(date: Date) {
    const dataObjeto = new Date(date);
    const monthNames = ["jan", "fev", "mar", "abr", "mai", "jun", "jul", "ago", "set", "out", "nov", "dez"];
    const month = dataObjeto.getMonth();
    const year = dataObjeto.getFullYear();
    return `${monthNames[month]}/${year}`;
}

export const formatDateToYYYYMMDD = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    
    return `${year}-${month}-${day}`;
  };

export function getShortMonthNameString(dateString: string) {
    const shortMonthNames = {
        '01': 'jan',
        '02': 'fev',
        '03': 'mar',
        '04': 'abr',
        '05': 'may', 
        '06': 'jun',
        '07': 'jul',
        '08': 'ago',
        '09': 'sep', 
        '10': 'oct', 
        '11': 'nov',
        '12': 'dez'
    };

    const month = dateString.split('-')[1];

    return shortMonthNames[month];
}


const isDateString = (dateString) => {
    return /^\d{2}\/\d{2}\/\d{4}$/.test(dateString);
};
  
export const parseDate = (dateString: string) => {
    if (isDateString(dateString)) {
        const [day, month, year] = dateString.split('/');
        return new Date(`${year}-${month}-${day}`);
    }
    return new Date(dateString);
};
  
export const nameMes = (dateString: string) => {
    const mounthYear = [
        'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
      ];

      const data = new Date(dateString);
      const year = data.getFullYear();
      const month = data.getMonth() + 1;
      const nameMonth = mounthYear[month - 1];

      return nameMonth
}

