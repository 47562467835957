/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import { useToast } from '../../../../../components/Toats';
import { StepperComponent } from '../../../../../../../_metronic/assets/ts/components';
import { KTSVG} from '../../../../../../../_metronic/helpers';
import { LoadingSpan } from '../../../../../components/Loadings/loadingSpan';
import { getServiceOne, putServiceOne } from '../../../../../../services/servicesService';
import { formatarData } from '../../../../../../utils/date';
import * as Yup from "yup";
import { useFormik } from 'formik';
import { useAuth } from '../../../../../../modules/auth';
import { getReportServiceOne, putReportService } from '../../../../../../services/reportService';

type Props = {
    id: string;
    data: any;
    show: boolean;
    handleClose: () => void
}

const validationSchema = Yup.object().shape({
  description: Yup.string(),
  created_at: Yup.string(),
  status: Yup.string(),
  plant: Yup.string(),
  unitBranch: Yup.string(),
  typeService: Yup.string(),
  client: Yup.string()
});

const modalsRoot = document.getElementById('root-modals') || document.body

const ModalRequestService = ({show, handleClose, id}: Props) => {

  const { toggleToast } = useToast();
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [loading, setLoading] = useState(false)
  //const [myService, setMyService] = useState<any>(null)
  const [largura, setLargura] = useState(window.innerWidth);
  const user = useAuth()

  const [initialValues] = useState<any>({
    description: null,
    created_at: null,
    status: null,
    plant: null,
    unitBranch: null,
    typeService: null,
    client: null
  })
  
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const closed = () => {
    handleClose()
  }

  const updateReportServiceSubmit = async () => {
    try {
      setLoading(true)
      const myDate = {
        status: formik.values.status ? formik.values.status : null
      }
      await putReportService(myDate, id);
      toggleToast("Sucesso ao atualizar a solicitação de serviço. ", "success")

    } catch (error) {
      toggleToast("Erro ao atualizar a solicitação de serviço. ", "error")
    }finally{
      setLoading(false)
      closed()
      // handleClose()
    //   setTimeout(()=>{
    //     window.location.reload()
    //   }, 2000);
    }
  }

  const getService = async () => {
    try {
      setLoading(true)
      const service = await getReportServiceOne(id)
      //setMyService(service)
      const myData = {
        description: service.description ? service.description : null,
        created_at: service.created_at ? service.created_at : null,
        status: service.status ? service.status : null,
        plant: service.plant ? service.plant.name : null,
        unitBranch: service.unitBranch ? service.unitBranch.companyName : null,
        typeService: service.typeService ? service.typeService.description : null,
        client: service.client ? service.client.nameResponsible : null,
      }
      formik.setValues(myData)

    } catch (error) {
      toggleToast("Erro ao buscar o serviço. ", "error")
    }finally{
      setLoading(false)
    }
  }

  const submit = () => {
    updateReportServiceSubmit()
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: () => {},
  });

  const atualizarTamanhoDaTela = () => {
    setLargura(window.innerWidth);
  };

  useEffect(() => {
    if(id){
        getService()
    }

    window.addEventListener('resize', atualizarTamanhoDaTela);
    return () => {
      window.removeEventListener('resize', atualizarTamanhoDaTela);
    };
  }, [show])


  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-700px'
      show={show}
      onHide={closed}
      onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Detalhes da Solicitação de Serviço</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-5'>( {unitBranch.name} )</span> */}
        </h3>

        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        {/*begin::Stepper */}
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >
         

          {/*begin::Content */}
          <div className='flex-row-fluid py-lg-0'>
            
            {loading ? 
                <></>
                : 
                <div style={{marginBottom: '3rem', display: 'flex', flexDirection: 'column', gap: '1.5rem'}}>
                    
                    <div className='d-flex' style={{flexDirection: "row", gap: '1rem', alignItems: 'center'}}>
                        {/* <KTSVG path="/media/icons/duotune/general/gen016.svg" className="svg-icon-muted svg-icon-3hx" /> */}
                        <div className='fw-bold fs-4 text-gray-400'>Tipo de Serviço: </div>
                        <div className='fs-4 fw-bolder'>{formik.values.typeService ? formik.values.typeService : "Não identificado."}</div>
                    </div>

                    <div className='d-flex' style={{flexDirection: "row", gap: '1rem', alignItems: 'center'}}>
                        {/* <KTSVG path="/media/icons/duotune/general/gen014.svg" className="svg-icon-muted svg-icon-3hx" /> */}
                        <div className='fw-bold fs-4 text-gray-400'>Data da solicitação: </div>
                        <div className='fs-4 fw-bolder'>{formik.values.created_at ? formatarData(formik.values.created_at) : "Não identificado."}</div>
                    </div>

                    <div className='d-flex' style={{flexDirection: "row", gap: '1rem', alignItems: 'center'}}>
                        {/* <KTSVG path="/media/icons/duotune/general/gen055.svg" className="svg-icon-muted svg-icon-3hx" /> */}
                        <div className='fw-bold fs-4 text-gray-400'>Descrição: </div>
                        <div className='fs-4 fw-bolder'>{formik.values.description ? formik.values.description : "Não identificado."}</div>
                    </div>

                    <div className='d-flex' style={{flexDirection: "row", gap: '1rem', alignItems: 'center'}}>
                        {/* <KTSVG path="/media/icons/duotune/general/gen016.svg" className="svg-icon-muted svg-icon-3hx" /> */}
                        <div className='fw-bold fs-4 text-gray-400'>Usina referente: </div>
                        <div className='fs-4 fw-bolder'>{formik.values.plant ? formik.values.plant : "Não identificado."}</div>
                    </div>
                    
                    {/* 
                    <div className='d-flex' style={{flexDirection: "row", gap: '1rem', alignItems: 'center'}}>
                        <div className='fw-bold fs-4 text-gray-400'>Unidade realizadora: </div>
                        <div className='fs-4 fw-bolder'>{formik.values.unitBranch ? formik.values.unitBranch : "Não identificado."}</div>
                    </div> */}

                       
                    <div className='d-flex' style={{flexDirection: "row", gap: '1rem', alignItems: 'center'}}>
                        <div className='fw-bold fs-4 text-gray-400'>Cliente: </div>
                        <div className='fs-4 fw-bolder'>{formik.values.client ? formik.values.client : "Não identificado."}</div>
                    </div>

                    <div className='d-flex' style={{flexDirection: largura > 1200 ? "row" : "column", gap: '1rem', alignItems: largura > 1200 ? 'center' : ''}}>
                        {/* <KTSVG path="/media/icons/duotune/arrows/arr016.svg" className="svg-icon-muted svg-icon-3hx" /> */}
                        <div style={{display: 'flex', gap: '1rem', alignItems: 'center'}}>
                          <div className='fw-bold fs-4 text-gray-400'>Status da solicitação: </div>
                          <div className='fs-4 fw-bolder'>
                              <span 
                                  className={
                                  `badge badge-light-${
                                    formik.values.status && formik.values.status !== null ? 
                                      formik.values.status === "PENDENTE" ? "info"
                                      : formik.values.status === "CANCELADO" ? "danger"
                                      : formik.values.status === "ATENDIDO" ? "success"
                                      : "secondary"
                                      : "secondary"
                                  
                                  } fs-5 fw-semibold`
                                  }
                              >
                                  {formik.values.status ? formik.values.status : "Não identificado"}
                              </span>
                          </div>
                        </div>

                        {user.currentUser?.role.slug !== "CLIENTE" && (<div className='me-0 dropdown' style={{width: largura < 1200 ? '100%' : ''}}>
                          <button
                            //style={{color: 'white'}}
                            //disabled={loadingAvaiable || loading || report ? !report.template ? true : false : true}
                            className='btn btn-lg btn-secondary'
                            style={{width: largura < 1200 ? '100%' : '',display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '1rem'}}
                            type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"
                            //data-kt-menu-trigger='click'
                            //data-kt-menu-dismiss='false'
                            //data-kt-menu-placement='bottom-end'
                            //data-kt-menu-flip='top-end'
                          >
                            <span>Alterar o Status</span>
                            <i className='bi bi-three-dots fs-3'></i>
        
                          </button>
                
                          <div className='dropdown-menu w-150px w-md-150px dropdown-menu-end' aria-labelledby="dropdownMenuButton1" style={{padding: '1rem'}}>
                            <div className='card-toolbar nav nav-tabs' data-kt-buttons='true'>
                              <a
                                className={`btn btn-sm btn-color-muted btn-active btn-active-success 
                                  ${formik.values.status === "ATENDIDO" ? "active" : ""} px-5 me-1 nav-item`
                                }
                                style={{width: '100%'}}
                                data-bs-toggle="tab"
                                href="#"
                                onClick={()=> {
                                  formik.setValues({...formik.values, status: "ATENDIDO"})
                                }}
                              >
                                ATENDIDO
                                {/* <span 
                                  className='badge badge-dark'
                                  style={{marginLeft: '10px'}}
                                >
                                  FINALIZADO
                                </span> */}
                              </a>
                              {/* <a
                                className={`btn btn-sm btn-color-muted btn-active btn-active-info 
                                  ${formik.values.status === "PENDENTE" ? "active" : ""} px-5 me-1 nav-item`
                                }
                                //className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1 nav-item'
                                data-bs-toggle="tab"
                                style={{width: '100%'}}
                                href="#"
                                onClick={()=> {
                                  formik.setValues({...formik.values, status: "PENDENTE"})
                                }}
                              >
                                PENDENTE
                              </a> */}
                              <a
                                className={`btn btn-sm btn-color-muted btn-active btn-active-danger
                                  ${formik.values.status === "CANCELADO" ? "active" : ""} px-5 me-1 nav-item`
                                }
                                //className='btn btn-sm btn-color-muted btn-active btn-active-danger px-4 nav-item'
                                data-bs-toggle="tab"
                                style={{width: '100%'}}
                                href="#"
                                onClick={()=> {
                                  formik.setValues({...formik.values, status: "CANCELADO"})
                                }}
                              >
                                CANCELADO
                                {/* <span 
                                  className='badge badge-danger'
                                  style={{marginLeft: '10px'}}
                                >
                                  {alarmStatus.offlineNum}
                                </span> */}
                              </a>
                            </div>
                          </div>
                        </div>)}
                    </div>
                    
                    {/* <h3 className='card-title align-items-start flex-column'>
                        <span className='text-muted mt-1 fw-semibold fs-5'>Deseja deletar esse cliente permanentemente?</span>
                    </h3> */}

                </div>
            }


            {/*begin::Form */}
            {user.currentUser?.role.slug !== "CLIENTE" && (
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <button
                    type='button'
                    className='btn btn-lg btn-secondary'
                    disabled={loading}
                    onClick={() => {
                        closed()
                    }}
                    //data-kt-stepper-action='submit'
                >
                    <KTSVG
                        path='/media/icons/duotune/arrows/arr063.svg'
                        className='svg-icon-3 ms-2 me-0'
                    />
                    <span style={{marginLeft: '0.5rem'}}>Cancelar</span>
                </button>
                    
                <button
                    type='button'
                    className='btn btn-lg btn-primary'
                    disabled={loading}
                    //data-kt-stepper-action='submit'
                    onClick={submit}
                >
                    Salvar
                    {
                        loading ? 
                        <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                        :
                        <KTSVG
                            path='/media/icons/duotune/arrows/arr064.svg'
                            className='svg-icon-3 ms-2 me-0'
                        />
                    }
                </button>
              </div>
            )}
            
            {/*end::Form */}
          </div>
          {/*end::Content */}
        </div>
        {/* end::Stepper */}
      </div>
    </Modal>,
    modalsRoot
  )
}

export {ModalRequestService}
