/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import { KTSVG } from '../../../_metronic/helpers';
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import { useAuth } from '../../modules/auth';
import { Vertical } from '../../modules/wizards/components/Vertical';
import { CalendarServices } from './components/CalendarServices';
import { RequestService } from './components/RequestService';
import { ScreenServices } from './components/ScreenServices';
import { TableTypeServices } from './components/TableTypeServices';
import useSize from '../../hooks/useSize';
//import { MyPortal } from './components/my-portal'

const storeServicesBreadcrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const StoreServicesPage: FC = () => {
    const [refreshPage, setRefreshPage] = useState<boolean>(false);
    const [largura, setLargura] = useState(window.innerWidth);
    const user = useAuth()
    const {height} = useSize()

    const refresh = () => {
      //getClients()
    }

    const atualizarTamanhoDaTela = () => {
      setLargura(window.innerWidth);
    };
  
    useEffect(() => {
      window.addEventListener('resize', atualizarTamanhoDaTela);
      return () => {
        window.removeEventListener('resize', atualizarTamanhoDaTela);
      };
    }, [])

  return (
    <div 
      className='column g-5 g-xl-8 card p-7' 
      style={{
        height: height <= 750 ? '68vh' 
        : height <= 850 ? '70vh'
        : height <= 930 ? '70vh'
        : '72vh',
        paddingBottom: '1rem'
      }}>
       <div>
          <ul className={
            `nav nav-stretch nav-line-tabs 
            nav-line-tabs-2x border-transparent fw-bolder 
            flex-nowrap ${largura < 1200 ? "" : "mb-5"} fs-${largura < 1200 ? '6' : '5'}` 
          } 
            style={{
              overflowX: 'auto',
              display: 'flex',
              height: 'auto',
              width: '100%'
            }}>
            <li className="nav-item">
              <a
                className="nav-link active text-active-primary me-6"
                data-bs-toggle="tab"
                href="#kt_tab_pane_5"
                //onClick={() => setPages(1)}
              >
                Serviços
              </a>
            </li>

            <li className="nav-item">
              <a
                className="nav-link text-active-primary me-6"
                data-bs-toggle="tab"
                href="#kt_tab_pane_2"
              >
                Solicitação de Serviço
              </a>
            </li>
          </ul>

          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade active show"
              id="kt_tab_pane_5"
              role="tabpanel"
            >
              <ScreenServices 
                refreshPage={refreshPage}
                setRefreshPage={setRefreshPage}
              />
            </div>
            <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
              <RequestService 
                refreshPage={refreshPage}
                setRefreshPage={setRefreshPage}
              />
            </div>       
          </div>
       </div>

       {/* <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '70vh'}}>
          <KTSVG path="media/icons/duotune/coding/cod009.svg" className="svg-icon-muted svg-icon-5hx" />
          <h3 className='card-title' style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <span className='card-label fw-bold text-muted'>Em Desenvolvimento!</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Em breve novas atualizações!</span>
          </h3>
       </div> */}
    </div>
  )
}

const StoreServicesWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={storeServicesBreadcrumbs}>Loja de Serviços</PageTitle>
      <StoreServicesPage />
    </>
  )
}

export {StoreServicesWrapper}
// function toggleToast(arg0: string, arg1: string) {
//     throw new Error('Function not implemented.');
// }

