/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers';
import { searchSubstitut } from '../../../utils/search';
import { SearchInput } from '../../components/SearchInput';
import { ModalAddUpdateUser } from '../components/ModalAddEditUser';
import { ModalDeleteUser } from '../components/ModalDeleteUser';
// import { ModalCreateCredential } from '../../../portals/components/modalCreateCredential';
// import { ModalAddUpdateClient } from '../ModalAddEditClient';
// import { ModalDeleteClient } from '../ModalDeleteClient';
// import { ModalDetailsClient } from '../ModalDetailsClient';
import { TableBody } from './TableBody';
import { ModalActiveUser } from '../components/ModalActiveUser';


type Props = {
  className: string;
  users: any[]
  refresh: any
}

const TableUsers: React.FC<Props> = ({className, users, refresh}) => {
  
  const [mySearch, setMySearch] = useState<any[]>([]);
  const [control, setControl] = useState(false);
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  const [showCreateAppModalDelete, setShowCreateAppModalDelete] = useState<boolean>(false)
  const [showCreateAppModalActive, setShowCreateAppModalActive] = useState<boolean>(false)
  //const [showCreateAppModalDetails, setShowCreateAppModalDetails] = useState<boolean>(false)
  const [myUser, setMyUser] = useState<any | null>(null);
  const [largura, setLargura] = useState(window.innerWidth);


  const atualizarTamanhoDaTela = () => {
    setLargura(window.innerWidth);
  };

  useEffect(()=> {
    window.addEventListener('resize', atualizarTamanhoDaTela);
    return () => {
      window.removeEventListener('resize', atualizarTamanhoDaTela);
    };
  }, [])
  
  useEffect(()=> {
    const search_users = mySearch.length > 0 ? 
    searchSubstitut(users, mySearch)
      : []
    setMySearch(search_users)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users])

  return (
    <div className={`card ${className}`} style={{height: largura > 1200 ? '72vh' : '68vh', paddingBottom: '1rem'}}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-6'>
        {/* <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Lista de Usuários</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{users.length} Usuários(s)</span>
        </h3> */}
        <div 
          style={{
            display: 'flex', 
            gap: '1rem',  
            justifyContent: 'space-between', 
            width: '100%',
            flexDirection: largura > 1200 ? 'row' : 'column'
          }}>
          <SearchInput 
            className='form-control form-control-solid bg-body ps-14'
            placeholder='Buscar Usuários'
            myDate={users}
            setMySearch={setMySearch}
            control={control}
            style={{width: largura > 1200 ? "300px" : "100%"}}
            setControl={setControl}
            searchParameter="name"
          />

          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click para adicionar uma unidade filial'
            style={largura < 1200 ? {width: "100%", display: 'flex', justifyContent: 'end'} : {}}
          >
            <a
              onClick={() =>{setShowCreateAppModal(true)}}
              href='#'
              className='btn btn-sm btn-light-primary'
              //data-bs-target='#kt_modal_create_unitBranch'

            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
              Novo Usuário
            </a>
          </div>
        </div>
        
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3' style={largura < 1200 ? {height: '20rem', overflow: 'auto', paddingTop: '0rem'} : {overflow: 'auto'}}>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-hover table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='text-muted text-start fw-bolder fs-7 text-uppercase gs-0'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th>
                <th className='min-w-250px'>Nome</th>
                <th className='min-w-150px'>Tipo</th>
                <th className='min-w-100px'>Status</th>
                <th className='min-w-50px'>Data de criação</th>
                <th className='min-w-150px text-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
              {
                control === false ? (
                  <TableBody 
                    users={users} 
                    setMyUser={setMyUser}
                    setShowCreateAppModal={setShowCreateAppModal}
                    setShowCreateAppModalDelete={setShowCreateAppModalDelete}
                    setShowCreateAppModalActive={setShowCreateAppModalActive}
                    // setShowCreateAppModalDetails={setShowCreateAppModalDetails}
                  />
                )
                : (
                  <TableBody 
                    users={mySearch} 
                    setMyUser={setMyUser}
                    setShowCreateAppModal={setShowCreateAppModal}
                    setShowCreateAppModalDelete={setShowCreateAppModalDelete}
                    setShowCreateAppModalActive={setShowCreateAppModalActive}
                    // setShowCreateAppModalDetails={setShowCreateAppModalDetails}
                  />
                )
              }
              
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
        <ModalAddUpdateUser
          show={showCreateAppModal}
          id={myUser ? myUser.id : undefined} 
          data={myUser ? myUser : undefined}
          handleClose={() => {
            setMyUser(null)
            setShowCreateAppModal(false)
            refresh()
          }} 
        />
        <ModalDeleteUser 
          show={showCreateAppModalDelete}
          data={myUser}
          id={myUser ? myUser.id : undefined} 
          handleClose={() => {
            setMyUser(null)
            setShowCreateAppModalDelete(false)
            refresh()
          }} 
        />
        <ModalActiveUser 
          show={showCreateAppModalActive}
          data={myUser}
          id={myUser ? myUser.id : undefined} 
          handleClose={() => {
            setMyUser(null)
            setShowCreateAppModalActive(false)
            refresh()
          }} 
        />

        {/* <ModalDetailsClient
          show={showCreateAppModalDetails}
          id={myClient ? myClient.id : undefined} 
          handleClose={() => {
            setMyClient(null)
            setShowCreateAppModalDetails(false)
            refresh()
          }} 
        /> */}
      {/* begin::Body */}
    </div>
  )
}

export {TableUsers}
