/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { KTSVG } from '../../../../../../_metronic/helpers';
import { useAuth } from '../../../../../modules/auth';

type Props = {
    unitBranchs: any[];
    setMyUnitBranch: any;
    setShowCreateAppModal: any;
    setShowCreateAppModalDelete: any
    setShowCreateAppModalDetails: any
}

export function TableBody({
    unitBranchs, 
    setMyUnitBranch, 
    setShowCreateAppModal, 
    setShowCreateAppModalDelete, 
    setShowCreateAppModalDetails
}: Props) {

    const user = useAuth()
    const handleButtonClickDelete = (event, unitBranch) => {
        event.stopPropagation();
        unitBranch.idUser = unitBranch.users.length > 0 ? unitBranch.users[0].id : null; 
        setMyUnitBranch(unitBranch)
        setShowCreateAppModalDelete(true)
    };

    const handleButtonClickUpdate = (event, unitBranch) => {
        event.stopPropagation();
        unitBranch.idUser = unitBranch.users.length > 0 ? unitBranch.users[0].id : null; 
        setMyUnitBranch(unitBranch)
        setShowCreateAppModal(true)
    };

  return (
    <tbody>
        {unitBranchs.length !== 0 ?   
            unitBranchs.map((unitBranch: any) => {
                return (
                    <tr 
                        onClick={() =>{
                            setMyUnitBranch(unitBranch)
                            setShowCreateAppModalDetails(true)
                        }}
                    >
                        <td>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                            <input className='form-check-input widget-9-check' type='checkbox' value='1' />
                            </div>
                        </td>

                        <td>
                            <div className='d-flex align-items-center'>
                            <div className='symbol symbol-45px me-5'>
                                <KTSVG path="/media/icons/duotune/ecommerce/ecm009.svg" className="svg-icon-muted svg-icon-3hx" />
                            </div>
                            <div className='d-flex justify-content-start flex-column'>
                                <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                {unitBranch.companyName}
                                </a>
                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                {unitBranch.dateOfBirth}
                                </span>
                            </div>
                            </div>
                        </td>

                        <td>
                            <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                            {unitBranch.cnpj}
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                CNPJ da filial
                            </span>
                        </td>

                        <td>
                            <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                            {unitBranch.street}, {unitBranch.district}
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                            {unitBranch.city}, {unitBranch.country}
                            </span>
                        </td>

                        <td>
                            {unitBranch.users.length > 0 ? (
                            <div>
                                <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                {unitBranch.users[0].name}
                                </a>
                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                {unitBranch.users[0].email}
                                </span>
                            </div>
                            )
                            :
                            (
                                <div>
                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                    Não encontrado.
                                </span>
                                </div>
                            )
                        }
                            
                        </td>
                        

                        <td>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                            {/* <a
                                href='#'
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            >
                                <KTSVG
                                path='/media/icons/duotune/general/gen019.svg'
                                className='svg-icon-3'
                                />
                            </a> */}
                            <a
                                onClick={(event: any) =>{
                                    handleButtonClickUpdate(event, unitBranch)
                                }}
                                href='#'
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            >
                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                            </a>
                            
                            {user.currentUser && user.currentUser.companies && user.currentUser.companies.length > 0
                                && user.currentUser.companies[0].planos === "PREMIUM" &&
                                (<a
                                onClick={(event: any) => {
                                    handleButtonClickDelete(event, unitBranch)
                                }}
                                href='#'
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            >
                                <KTSVG
                                path='/media/icons/duotune/general/gen027.svg'
                                className='svg-icon-3'
                                />
                            </a>)}
                            </div>
                        </td>
                    </tr>
                )
            })
        :
            <tr>
                <td colSpan={6} style={{textAlign: 'center'}}>
                    Unidades Filiais não encontradas!
                </td>
            </tr>
        }
    </tbody>
  );
}