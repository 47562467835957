/* eslint-disable jsx-a11y/anchor-is-valid */

import { useEffect, useState } from "react";
import { getUnitBranchAll } from "../../../../../../services/unitBranchService";
import { Loading } from "../../../../../components/Loadings/loading";
import { ListUnitBranchLinked } from "../../../../../storeServices/components/ModalAddEditTypeService/components/listUnitBranchLinked";
import { LoadingSpan } from "../../../../../components/Loadings/loadingSpan";
import { KTSVG } from "../../../../../../../_metronic/helpers";
import { getPhasesFunnelAll } from "../../../../../../services/phasesFunnel";
import { ModalDeletePhaseFunnel } from "../../../ModalDeletePhaseFunnel";


const StepModal = ({hasError, formik, formikPhases, submit, submitPhases, loadingPhaseFunnel, loadingFunnel}: any) => {
  const [unitBranchs, setUnitBranchs] = useState<any[]>([]);
  const [myPhasesFunnel, setMyPhasesFunnel] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingPhases, setLoadingPhases] = useState<boolean>(false);
  const [showCreateAppModalDelete, setShowCreateAppModalDelete] = useState<boolean>(false)

  const getUnitBranchs = async () => {
    try {
      setLoading(true)
      const unitBranchs = await getUnitBranchAll()
      setUnitBranchs(unitBranchs)

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar a lista de unidades. ", "error")

    }finally{
      setLoading(false)
    }
  }

  const getPhasesFunnel = async () => {
    try {
      setLoadingPhases(true)
      const phasesFunnel = await getPhasesFunnelAll(formik && formik.values.idFunnel ? formik.values.idFunnel : "")
      setMyPhasesFunnel(phasesFunnel)

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar as etapas do funil. ", "error")

    }finally{
      setLoadingPhases(false)
    }
  }

  const refresh = () => {
    getPhasesFunnel()
  }

  useEffect(() => {
    const fuction_async = async () =>{
      await getUnitBranchs()
    }
    fuction_async()
  }, []);

  useEffect(() => {
    if(formik.values.idFunnel){
      getPhasesFunnel()
    }
  }, [formik.values.idFunnel, loadingPhaseFunnel]);

  if (loading) {
    return (
      <div style={{display: 'flex', justifyContent: 'center', height: '15rem', alignItems: 'center'}}>
        <Loading myClass='spinner-border' style={{width: '3rem', height: '3rem'}}/>
      </div>
    );
  }
  // }else{
  //   if(users.length === 0){
  //     return (
  //       <div style={{display: 'flex', justifyContent: 'center', height: '15rem', alignItems: 'center'}}>
  //         <h3 style={{display: "flex", flexDirection: "column"}}>
  //           <span className='text-muted mt-1 fw-semibold fs-7'>Há um problema no cadastro. Tente novamente mais tarde. </span>
  //         </h3>
  //       </div>
  //     )
  //   }
  // }

  return (
    <div className='current' data-kt-stepper-element='content' 
        style={{display: 'flex', gap: '2rem'}}
    >
      <div className='w-100' style={{flex: '3'}}>
        <h3 className='card-title align-items-start flex-column' style={{display: 'flex', flexDirection: 'column'}}>
            <span className='card-label fw-bold fs-3 mb-1'>Adicionar Funil</span>
            <span className='text-muted mt-1 fw-semibold fs-5'>Crie contextos (funis) para gerir as propostas</span>
        </h3>

        <div className='separator my-2 mt-5 mb-5' style={{width: '100%'}}></div>

        <div style={{display: 'flex', gap: '1rem'}}> 
            
            <div className='fv-row mb-10' style={{width: '100%'}}>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span className='required'>Descrição</span>
                <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Digite uma descrição para o tipo de serviço'
                ></i>
                </label>
                <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='description'
                placeholder=''
                value={formik.values.description}
                onChange={formik.handleChange("description")}
                />
                {!formik.values.description && hasError && (
                <div className='fv-plugins-message-container'>
                    <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                    Informe uma descrição para o funil
                    </div>
                </div>
                )}
            </div>
        </div>
        
        <div style={{display: 'flex'}}>
          <div style={{flex: '3'}}>
            <ListUnitBranchLinked funnel="funnel" unitBranchs={unitBranchs} className="className='card-xl-stretch bg-light mb-xl-8" formik={formik}/>
          </div>
        </div>
        <div style={{width: '100%', display: 'flex', justifyContent: 'end', marginTop: '1rem'}}>
            <button
                type='button'
                className='btn btn-sm btn-primary'
                disabled={loading || loadingFunnel}
                //data-kt-stepper-action='submit'
                onClick={submit}
            >
                {formik.values.idFunnel ? "Editar Funil" : "Cadastrar Funil"}{' '}
                {
                    loading ? 
                    <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                    :
                    <KTSVG
                        path='/media/icons/duotune/arrows/arr064.svg'
                        className='svg-icon-3 ms-2 me-0'
                    />
                }
            </button>
        </div>
      </div>
      

      <div className='w-100' style={{flex: '2'}}>
        {/*begin::Form Group */}
        <h3 className='card-title align-items-start flex-column' style={{display: 'flex', flexDirection: 'column'}}>
            <span className='card-label fw-bold fs-3 mb-1'>Adicionar Etapa</span>
            <span className='text-muted mt-1 fw-semibold fs-5'>Crie etapas para o funil</span>
        </h3>

        <div className='separator my-2 mt-5 mb-5' style={{width: '100%'}}></div>

        <div style={{display: 'flex', gap: '1rem'}}> 
          <div className='fv-row mb-10' style={{width: '100%'}}>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span className='required'>Descrição</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Digite uma descrição para o tipo de serviço'
              ></i>
            </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='description'
              disabled={formik.values.idFunnel || !loadingFunnel ? false : true}
              placeholder=''
              value={formikPhases.values.description}
              onChange={formikPhases.handleChange("description")}
            />
            {!formikPhases.values.description && hasError && (
              <div className='fv-plugins-message-container'>
                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                  Informe uma descrição para a fase
                </div>
              </div>
            )}
            <div style={{width: '100%', display: 'flex', justifyContent: 'end', marginTop: '1rem', gap: '0.5rem'}}>
                {formikPhases.values.idPhaseFunnel &&
                (<button
                    type='button'
                    className='btn btn-sm btn-secondary'
                    disabled={loading}
                    onClick={() => {
                      formikPhases.setValues({
                        idPhaseFunnel: null,
                        description: "",
                        idFunnel: null,
                      });
                    }}
                    //data-kt-stepper-action='submit'
                >
                    <KTSVG
                        path='/media/icons/duotune/arrows/arr063.svg'
                        className='svg-icon-3 ms-2 me-0'
                    />
                    <span style={{marginLeft: '0.5rem'}}>Cancelar</span>
                    
                </button>)}
                <button
                    type='button'
                    className='btn btn-sm btn-primary'
                    disabled={loading || !formik.values.idFunnel}
                    //data-kt-stepper-action='submit'
                    onClick={submitPhases}
                >
                    {formikPhases.values.idPhaseFunnel ? "Editar Etapa" : "Cadastrar Etapa"}{' '}
                    {
                        loading ? 
                        <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                        :
                        <KTSVG
                            path='/media/icons/duotune/arrows/arr064.svg'
                            className='svg-icon-3 ms-2 me-0'
                        />
                    }
                </button>
            </div>
          </div>
        </div>
        

        <div style={{display: 'flex'}}>
          <div style={{width: '100%', height: '24rem', overflow: 'auto'}}>
            <table className='table table-row-dashed table-hover table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                <tr className='fw-bolder text-muted fs-7 text-uppercase gs-0'>
                    <th className='min-w-100px'>Descrição</th>
                    <th className='min-w-100px text-end'>Actions</th>
                </tr>
                </thead>
                <tbody>
                  {
                    myPhasesFunnel && myPhasesFunnel.map((phaseFunnel) => {
                      return (
                        <tr key={phaseFunnel.id} >
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                  <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                  {/* {unitBranch.companyName} */}
                                  {phaseFunnel.description ? phaseFunnel.description : ""}
                                  </a>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                              <a
                                  onClick={(event: any) =>{
                                      formikPhases.setValues({
                                        idPhaseFunnel: phaseFunnel && phaseFunnel.id ? phaseFunnel.id : null,
                                        description: phaseFunnel && phaseFunnel.description ? phaseFunnel.description : null,
                                      })
                                  }}
                                  href='#'
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              >
                                  <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                              </a>
                              <a
                                  onClick={(event: any) => {
                                    formikPhases.setValues({
                                      idPhaseFunnel: phaseFunnel && phaseFunnel.id ? phaseFunnel.id : null,
                                      description: phaseFunnel && phaseFunnel.description ? phaseFunnel.description : null,
                                    })
                                    setShowCreateAppModalDelete(true)
                                  }}
                                  href='#'
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                              >
                                  <KTSVG
                                  path='/media/icons/duotune/general/gen027.svg'
                                  className='svg-icon-3'
                                  />
                              </a>
                            </div>
                          </td>
                        </tr>
                      )
                    })
                  }
                  <tr>
                  </tr>
                </tbody>
            </table>
          </div>
        </div>
      </div>
        <ModalDeletePhaseFunnel
          show={showCreateAppModalDelete}
          data={formikPhases.values}
          id={formikPhases ? formikPhases.values.idPhaseFunnel : undefined} 
          handleClose={() => {
            formikPhases.setValues({
              idPhaseFunnel: null,
              description: "",
              idFunnel: null,
            });
            setShowCreateAppModalDelete(false)
            refresh()
          }} 
        />
    </div>
  )
}

export {StepModal}
function toggleToast(arg0: string, arg1: string) {
  throw new Error("Function not implemented.");
}

