/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
//import { KTSVG, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { getConcessionariesAll } from '../../../../services/concessionariesService';
import { getInvoicesAll, getInvoicesAllPagination } from '../../../../services/invoices';
import { getUnitConsumerAll } from '../../../../services/unitConsumerService';
import { searchSubstitut } from '../../../../utils/search';
import { Loading } from '../../../components/Loadings/loading';
import { SearchInput } from '../../../components/SearchInput';
//import { ModalCreateCredential } from '../../../portals/components/modalCreateCredential';
import DateSelector from '../../../reports/TableReports/components/DateInput';
import { ModalAddUpdateInvoice } from '../ModalAddEditInvoices';

//import { ModalDeleteClient } from '../ModalDeleteClient';
//import { ModalDetailsClient } from '../ModalDetailsClient';
import { TableBody } from './TableBody';
import { useAuth } from '../../../../modules/auth';


type Props = {
  className: string;
  //invoices: any[]
  //refresh: any
}

const TableInvoices: React.FC<Props> = ({className}) => {
  
  const [mySearch, setMySearch] = useState<any[]>([]);
  const [control, setControl] = useState(false);
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  //const [showCreateAppModalDelete, setShowCreateAppModalDelete] = useState<boolean>(false)
  //const [showCreateAppModalDetails, setShowCreateAppModalDetails] = useState<boolean>(false)
  const [myInvoice, setMyInvoice] = useState<any | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedUnitConsumer, setSelectedUnitConsumer] = useState<string>('1');
  const [selectedConcessionaries, setSelectedConcessionaries] = useState<string>("");

  const [invoices, setInvoices] = useState<any>([]);
  const [unitConsumers, setUnitConsumers] = useState<any>([]);
  const [concessionaries, setConcessionaries] = useState<any>([]);
  const [loading, setLoading] = useState<any>(true);
  const [loadingUnit, setLoadingUnit] = useState<any>(true);
  const [loadingConcessionaries, setLoadingConcessionaries] = useState<any>(true);
  const [largura, setLargura] = useState(window.innerWidth);
  const [filterConcessionaries, setFilterConcessionaries] = useState<string>("");
  const user = useAuth();

  const [pagination, setPagination] = useState<any>({
    page: 1,
    limit: 100,
    total: 0,
    qtdPages: 1
  });

  const [qtd, setQtd] = useState<any>({
    start: 0,
    end: 100,
  });

  const getInvoice = async () => {
      try {
        setLoading(true)
        const myInvoice = await getInvoicesAllPagination(selectedUnitConsumer, selectedDate, selectedConcessionaries, "", pagination.limit, pagination.page);
        setInvoices(myInvoice.data)
        setPagination({...pagination, qtdPages: myInvoice.lastPage, total: myInvoice.total})

        const search_invoices = mySearch.length > 0 ? 
            searchSubstitut(myInvoice, mySearch)
          : []
          setMySearch(search_invoices)

      } catch (error) {
        console.log(error)
        toggleToast("Erro ao buscar as faturas!", "error")
      }finally{
        setLoading(false)
      }
  }

  const getUnitConsumer = async () => {
    try {
      setLoadingUnit(true)
      const myUnitConsumers = await getUnitConsumerAll()
      setUnitConsumers(myUnitConsumers)

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar as faturas!", "error")
    }finally{
      setLoadingUnit(false)
    }
  }

const getConcessionaries = async () => {
  try {
    setLoadingConcessionaries(true)
    const myConcessionaries = await getConcessionariesAll()
    setConcessionaries(myConcessionaries)

  } catch (error) {
    console.log(error)
    toggleToast("Erro ao buscar as faturas!", "error")
  }finally{
    setLoadingConcessionaries(false)
  }
}

  const refresh = () => {
    getInvoice()
  }

  const handleChange = (event: any) => {
    const selectedValue = event.target.value;
    setSelectedUnitConsumer(selectedValue)
  };

  const atualizarTamanhoDaTela = () => {
    setLargura(window.innerWidth);
  };

  useEffect(() => {
    getInvoice()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, selectedUnitConsumer, selectedConcessionaries, pagination.page]);

  useEffect(() => {
    const start = (pagination.page - 1) * pagination.limit + 1;
    const end = Math.min(pagination.page * pagination.limit, pagination.total);

    setQtd({start: start, end: end === 0 ? pagination.limit : end})
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.page]);

  useEffect(() => {
    getInvoice()
    getUnitConsumer()
    getConcessionaries()

    window.addEventListener('resize', atualizarTamanhoDaTela);
    return () => {
      window.removeEventListener('resize', atualizarTamanhoDaTela);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loadingUnit || loadingConcessionaries) {
    return (
      <div style={{display: 'flex', justifyContent: 'center', height: '30rem', alignItems: 'center'}}>
        <Loading myClass='spinner-border' style={{width: '3rem', height: '3rem'}}/>
      </div>
    );
  }

  
  return (
    <div className={`card ${className}`} style={{height: largura > 1200 ? '72vh' : '72vh', paddingBottom: '1rem'}}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5 mb-7'>
        {/*<h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Lista de Clientes</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{clients.length} Cliente(s)</span>
        </h3>*/}
        <div 
          style={{
            display: 'flex', 
            gap: '1rem', 
            //height: '4rem', 
            alignItems: 'center', 
            width: "100%", 
            justifyContent: 'space-between', 
            marginTop: '1rem'
          }}
        >
          <div 
            style={{
              display: 'flex', 
              gap: '1rem', 
              justifyContent: 'space-between', 
              flexDirection: largura > 1200 ? "row" : "column",
              width: '100%'
            }}>
            <div 
              style={{
                display: 'flex', 
                gap: '1rem',
                flexDirection: largura > 1200 ? "row" : "column",
              }}>
              <div style={{width: '15rem'}}>
                <DateSelector 
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                />
              </div>

              <div>
                <select
                  className='form-select form-select-solid'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  defaultValue={selectedUnitConsumer}
                  onChange={handleChange}
                >
                  <option value={'1'}>Selecione uma Unidade</option>
                  {unitConsumers && unitConsumers.map((unitConsumer: any) => {
                    return (
                      <option key={unitConsumer.id} value={unitConsumer.id}>{unitConsumer.name}</option>
                    )
                  })}
                </select>
              </div>

              {/* <div>
                <select
                  className='form-select form-select-solid'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  defaultValue={selectedConcessionaries}
                  onChange={(e) => {
                    setSelectedConcessionaries(e.target.value)
                  }}
                >
                  <option value={''}>Selecione uma Concessionária</option>
                  {concessionaries && concessionaries.map((concessionarie: any) => {
                    return (
                      <option key={concessionarie.id} value={concessionarie.name}>{concessionarie.name}</option>
                    )
                  })}
                  
                </select>
              </div> */}


              <div>
                <div className='me-0 dropdown' style={{width: '100%'}}>
                  <button
                    className='btn btn-lg btn-light buttonFilterMark'
                    type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"
                    style={{display: 'flex', gap: '1rem'}}
                  >
                    <span>{filterConcessionaries !== "" ? filterConcessionaries : "Selecione uma concessionaria"} </span>
                    <i className='bi bi-three-dots fs-3'></i>
                  </button>

                  <div className='dropdown-menu dropdown-menu-end' aria-labelledby="dropdownMenuButton1" style={{padding: '1rem'}}>
                    <div className='card-toolbar nav nav-tabs' data-kt-buttons='true' style={{display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'start'}}>
                      <a
                          className={`btn btn-sm btn-color-muted btn-active btn-active-dark px-5 me-1 nav-item text-dark`}
                          //style={{background: 'green'}}
                          data-bs-toggle="tab"
                          style={{display: 'flex', gap: '1rem', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}
                          href="#"
                          onClick={()=> {
                            setFilterConcessionaries("")
                            setSelectedConcessionaries("")
                          }}
                        >
                          Selecione uma concessionaria
                        {/* <span 
                          className='badge badge-dark spanFilter'
                        >
                          {concessionarie.status_mon_aut ? concessionarie.status_mon_aut : 'Não identificado.'}
                        </span> */}
                      </a>
                      {concessionaries && concessionaries.map((concessionarie: any) => {
                      return (
                        <a
                          className={`btn btn-sm btn-color-muted btn-active btn-active-
                            ${concessionarie.status_mon_aut && concessionarie.status_mon_aut === 'MONITORADA' ? 'success' : 'danger'}
                            px-5 me-1 nav-item`}
                          style={{display: 'flex', gap: '1rem', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}
                          data-bs-toggle="tab"
                          href="#"
                          onClick={()=> {
                            setFilterConcessionaries(concessionarie.name ? concessionarie.name : "")
                            setSelectedConcessionaries(concessionarie.name ? concessionarie.name : "")
                          }}
                        >
                        <span style={{textAlign: 'start'}}>
                          {concessionarie.name ? concessionarie.name : "Não identificado"} 
                        </span>
                        {user.currentUser && user.currentUser.companies && user.currentUser.companies.length > 0
                          && user.currentUser.companies[0].planos === "PREMIUM" && 
                        (<span 
                          className={`badge badge-${concessionarie.status_mon_aut && concessionarie.status_mon_aut === 'MONITORADA' ? 'success' : 'danger'} fs-9`}
                          style={{height: '1.5rem', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                        >
                          {concessionarie.status_mon_aut ? concessionarie.status_mon_aut : 'Não identificado.'}
                        </span>)}
                      </a>
                        // <option key={concessionarie.id} value={concessionarie.name}>{concessionarie.name}</option>
                      )
                    })}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <SearchInput 
              className='form-control form-control-solid ps-14 bg-body'
              placeholder='Buscar Faturas'
              myDate={invoices}
              style={{width: largura > 1200 ? "200px" : "100%"}}
              setMySearch={setMySearch}
              control={control}
              setControl={setControl}
              searchParameter="unitConsumer.name"
            />
          </div>

          {/* <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click para adicionar uma unidade filial'
          >
            <a
              onClick={() =>{setShowCreateAppModal(true)}}
              href='#'
              className='btn btn-sm btn-light-primary'
              //data-bs-target='#kt_modal_create_unitBranch'

            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
              Nova Fatura
            </a>
          </div> */}
        </div>
        
      </div>
      <div className='card-body py-3 pb-5' style={largura < 1200 ? {height: '20rem', overflow: 'auto', paddingTop: '0rem'} : {overflow: 'auto'}}>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-hover table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bolder text-muted fs-7 text-uppercase gs-0'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th>
                <th className='min-w-250px'>Denominação</th>
                <th className='min-w-200px'>Mês de Referência</th>
                <th className='min-w-140px'>Status</th>
                <th className='min-w-140px'>Concessionária</th>
                {user.currentUser && user.currentUser.companies && user.currentUser.companies.length > 0
                && user.currentUser.companies[0].planos === "PREMIUM" && 
                (<th className='min-w-70px'>Mon. Aut.</th>)}
                <th className='min-w-100px text-end'>Pagamento</th>
                <th className='min-w-100px text-end'>Actions</th>
              </tr>
            </thead>
              {loading ? 
                  <tbody>
                    <tr>
                      <td colSpan={5} style={{textAlign: 'center', padding: '2rem'}}>
                        <Loading myClass='spinner-border' style={{width: '3rem', height: '3rem'}}/>
                      </td>
                    </tr>
                  </tbody>
                : 
                control === false ? (
                  <TableBody 
                    invoices={invoices} 
                    setMyInvoice={setMyInvoice}
                    setShowCreateAppModal={setShowCreateAppModal}
                    // setShowCreateAppModalDelete={setShowCreateAppModalDelete}
                    // setShowCreateAppModalDetails={setShowCreateAppModalDetails}
                  />
                )
                : (
                  <TableBody 
                    invoices={mySearch} 
                    setMyInvoice={setMyInvoice}
                    setShowCreateAppModal={setShowCreateAppModal}
                    // setShowCreateAppModalDelete={setShowCreateAppModalDelete}
                    // setShowCreateAppModalDetails={setShowCreateAppModalDetails}
                  />
                )
                }
              
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>

        <div className='d-flex flex-stack flex-wrap pt-10' style={{marginLeft: '2rem', marginRight: '2rem'}}>
          <div className='fs-6 fw-bold text-gray-700'>Mostrando {qtd.start} a {qtd.end} de {pagination.total} encontradas</div>

          <ul className='pagination'>
            <li className='page-item previous'>
              <a 
                href='#' 
                className='page-link'
                onClick={() => {
                  setPagination({...pagination, page: pagination.page - 1 > 0 ? pagination.page - 1 : pagination.page})
                }}
              >
                <i className='previous'></i>
              </a>
            </li>

            {Array.from({ length: pagination.qtdPages }, (_, index) => (
            <li 
              key={index} 
              className={`page-item ${(index + 1) === pagination.page ? "active" : ""}`}
              onClick={() => {
                setPagination({...pagination, page: index + 1})
              }}
            >
              <a href='#' className='page-link'>
                {index + 1}
              </a>
            </li>
            ))}

            
            <li className='page-item next'>
              <a 
                href='#' 
                className='page-link'
                onClick={() => {
                  setPagination({...pagination, page: pagination.page + 1 <= pagination.qtdPages ? pagination.page + 1 : pagination.page})
                }}
              >
                <i className='next'></i>
              </a>
            </li>
          </ul>
        </div>


        <ModalAddUpdateInvoice
          show={showCreateAppModal}
          id={myInvoice ? myInvoice.id : undefined} 
          data={myInvoice ? myInvoice : undefined}
          handleClose={() => {
            setMyInvoice(null)
            setShowCreateAppModal(false)
            refresh()
          }} 
        />
        {/* <ModalDeleteClient 
          show={showCreateAppModalDelete}
          data={myClient}
          id={myClient ? myClient.id : undefined} 
          handleClose={() => {
            setMyClient(null)
            setShowCreateAppModalDelete(false)
            refresh()
          }} 
        /> */}

        {/* <ModalDetailsClient
          show={showCreateAppModalDetails}
          id={myClient ? myClient.id : undefined} 
          handleClose={() => {
            setMyClient(null)
            setShowCreateAppModalDetails(false)
            refresh()
          }} 
        /> */}
      {/* begin::Body */}
    </div>
  )
}

export {TableInvoices}
function toggleToast(arg0: string, arg1: string) {
  throw new Error('Function not implemented.');
}

