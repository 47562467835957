import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { getTemplateAll } from '../../../../../../../../services/templates';
import { useAuth } from '../../../../../../../../modules/auth';
import { postSettingsCompanyOne, putSettingsCompanyAll } from '../../../../../../../../services/settingsCompanyService';
import { useToast } from '../../../../../../../components/Toats';

const validationSchema = Yup.object().shape({
    reports_client_automatics: Yup.boolean(),
    idTemplate: Yup.string(),
  });

export function PreferencesCompany() {
    const { currentUser } = useAuth()
    const { toggleToast } = useToast();
    const [myTemplateString, setMyTemplateString] = useState<string>("Sem template padrão. ")
    const [myTemplateGenerationString, setMyTemplateGenerationString] = useState<string>("Sem template padrão. ")
    const [myTemplates, setMyTemplates] = useState<any[]>([])
    const [loadingTemplates, setLoadingTemplates] = useState(false)
    const [loading, setLoading] = useState(false)
    const [menuSelection, setMenuSelection] = useState("DETALHADO");
    const [initialValues, setInitialValues] = useState<any>({
        reports_client_automatics: false,
        idsTemplates: []
    })

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: false,
        onSubmit: () => {},
    });


    const getTemplate = async (idTemplatSelectd?: string, idTemplateGenerationSected?: string) => {
        try {
          setLoadingTemplates(true)
     
          const templates = await getTemplateAll()
          setMyTemplates(templates)

          const templatString = templates.find((template: any) => template.id === (idTemplatSelectd !== "" ? idTemplatSelectd : ""))
          const templatGenerationString = templates.find((template: any) => template.id === (idTemplateGenerationSected !== "" ? idTemplateGenerationSected : ""))

            setMyTemplateString(templatString ? templatString.description : "Sem template padrão. ");
            setMyTemplateGenerationString(templatGenerationString ? templatGenerationString.description : "Sem template padrão. ");

    
          //toggleToast("Sucesso ao buscar os templates. ", "success")
    
        } catch (error) {
          console.log('erro',error)
          //toggleToast("Erro ao buscar os templates. ", "error")
        }finally{
          setLoadingTemplates(false)
        }
    }

    const updateSettingsCompany = async () => {
        try {
          setLoading(true)
     
        const body = {
            reports_client_automatics: formik.values.reports_client_automatics,
            idsTemplates: formik.values.idsTemplates,
        }
          await putSettingsCompanyAll(currentUser 
            && currentUser.companies && currentUser.companies.length > 0 
            && currentUser.companies[0].settingsCompany  ? currentUser.companies[0].settingsCompany.id : "", body)

          toggleToast("Sucesso ao atualizar preferências. ", "success")
          //toggleToast("Sucesso ao buscar os templates. ", "success")
    
        } catch (error) {
          console.log('erro',error)
          toggleToast("Erro ao atualizar preferências. ", "error")
          //toggleToast("Erro ao buscar os templates. ", "error")
        }finally{
          setLoading(false);
          setTimeout(()=>{
            window.location.reload()
          }, 700);
        }
    }

    const postSettingsCompany = async () => {
        try {
          setLoading(true)
            const body = {
                reports_client_automatics: formik.values.reports_client_automatics,
                idsTemplates: formik.values.idsTemplates,
                idCompany: currentUser && currentUser.companies && currentUser.companies.length > 0  ? currentUser.companies[0].id : null
            }
          await postSettingsCompanyOne(body)
          toggleToast("Sucesso ao salvar preferências. ", "success")
    
        } catch (error) {
          console.log('erro',error)
          toggleToast("Erro ao salvar preferências. ", "error")
        }finally{
          setLoading(false)
          setTimeout(()=>{
            window.location.reload()
          }, 700);
        }
    }


    const submit = () => {
        if(currentUser && currentUser.companies && currentUser.companies.length > 0){
            if(currentUser.companies[0].settingsCompany){
                updateSettingsCompany()
            }else{
                postSettingsCompany()
            }
        }
    }

    useEffect(() => {
        let idTemplateString = ""
        let idTemplateGenerationString = ""

        if (currentUser && currentUser.companies && currentUser.companies.length > 0 && currentUser.companies[0].settingsCompany) {
            const updatedValues = {
                ...formik.values,
                reports_client_automatics: currentUser.companies[0].settingsCompany.reports_client_automatics,
            };
    
            if (currentUser.companies[0].settingsCompany.templates) {
                updatedValues.idsTemplates = currentUser.companies[0].settingsCompany.templates.map((template: any) => {return template.id});
                
                const template_detalhado = currentUser.companies[0].settingsCompany.templates.find((template) => template.type_template === "DETALHADO")
                const template_geracao = currentUser.companies[0].settingsCompany.templates.find((template) => template.type_template === "GERACAO")

                idTemplateString = template_detalhado ? template_detalhado.id : "";
                idTemplateGenerationString = template_geracao ? template_geracao.id : "";
            }
    
            formik.setValues(updatedValues);
            console.log('valores carregados', updatedValues, currentUser.companies[0].settingsCompany)
        }
        getTemplate(idTemplateString, idTemplateGenerationString)

    }, [currentUser])

    useEffect(() => {
      console.log(formik.values)
    }, [formik.values])
    
  return (
    <div className='card mb-5 mb-xl-10'>
        <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_email_preferences'
        aria-expanded='true'
        aria-controls='kt_account_email_preferences'
        >
        <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Preferências da Empresa</h3>
        </div>
        </div>

        <div id='kt_account_email_preferences' className='collapse show'>
        <form className='form'>
            <div className='card-body border-top px-9 py-9'>
            <label className='form-check form-check-custom form-check-solid align-items-start'>
                <input
                  className='form-check-input me-3'
                  type='checkbox'
                  name='email-preferences[]'
                  defaultChecked={formik.values.reports_client_automatics}
                  checked={formik.values.reports_client_automatics}
                  onChange={formik.handleChange("reports_client_automatics")}
                />

                <span className='form-check-label d-flex flex-column align-items-start'>
                <span className='fw-bolder fs-5 mb-0'>Relatórios para Clientes</span>
                <span className='text-muted fs-6'>
                    Disponibilizar relatórios altomaticamente para os clientes 
                </span>
                </span>
            </label>

            <div className='separator separator-dashed my-6'></div>

            <label className='form-check form-check-custom form-check-solid align-items-start gap-1'>
                {/* <div className='me-0 dropdown'>
                    <button
                    className='btn btn-sm btn-secondary'
                    type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"
                    style={{display: 'flex', gap: '1rem', padding: '0.5rem'}}
                    >
                    <i className='bi bi-three-dots fs-5 p-0'></i>
                    </button>

                    <div className='dropdown-menu dropdown-menu-start' aria-labelledby="dropdownMenuButton1" style={{padding: '1rem', width: '25rem'}}>
                        <div className='card-toolbar nav nav-tabs' data-kt-buttons='true' style={{display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'start', height: '40rem', overflowY: 'auto'}}>
                            <a
                                className={`btn btn-sm btn-color-muted btn-active btn-active-primary px-5 me-1 nav-item text-dark`}
                                //style={{background: 'green'}}
                                data-bs-toggle="tab"
                                style={{display: 'flex', gap: '1rem', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}
                                href="#"
                                onClick={()=> {
                                    //setFilterConcessionaries("")
                                    //setSelectedConcessionaries("")
                                }}
                                >
                                Selecione um template
                            </a>
                            {myTemplates && myTemplates.map((template: any) => {
                            return (
                                <a
                                    className={`btn btn-sm btn-color-muted btn-active ${template.id === formik.values.idTemplate ? "active" : ""} btn-active-primary px-5 me-1 nav-item`}
                                    style={{display: 'flex', gap: '1rem', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}
                                    data-bs-toggle="tab"
                                    href="#"
                                    onClick={()=> {
                                        //setFilterConcessionaries(concessionarie.name ? concessionarie.name : "")
                                        //setSelectedConcessionaries(concessionarie.name ? concessionarie.name : "")
                                        formik.setValues({...formik.values, idTemplate: template.id})
                                        setMyTemplateString(template && template.description ? template.description : "")
                                    }}
                                    >
                                    <span style={{textAlign: 'start'}}>
                                        {template.description ? template.description : "Não identificado"} 
                                    </span>

                                    <span style={{textAlign: 'end'}}>
                                        {template.type ? template.type : "Não identificado"} 
                                    </span>
                                </a>
                                // <option key={concessionarie.id} value={concessionarie.name}>{concessionarie.name}</option>
                            )
                            })}
                        </div>
                    </div> 
                </div> */}

                <div>
                  <div className="dropdown">

                    {/* <button
                      className='btn btn-sm btn-light btn-active-primary'
                      type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"
                      style={{display: 'flex', gap: '1rem', padding: '0.5rem'}}
                    >
                      <i className='bi bi-three-dots fs-5 p-0'></i>
                    </button> */}

                    <button 
                      type="button" 
                      className="btn btn-sm btn-light btn-active-primary" 
                      data-bs-toggle="dropdown" 
                      aria-expanded="false" 
                      data-bs-auto-close="outside"
                      style={{display: 'flex', gap: '1rem', padding: '0.5rem'}}
                    >
                      <i className='bi bi-three-dots fs-5 p-0'></i>
                    </button>
                    
                    <div className="dropdown-menu dropdown-menu-start p-4">
                      <div>
                        <ul className={
                          `nav nav-stretch nav-line-tabs 
                          nav-line-tabs-2x border-transparent fw-bolder 
                          flex-nowrap fs-6` 
                        } 
                          style={{
                            display: 'flex',
                            height: 'auto',
                            width: '20rem',
                            marginBottom: '1rem'
                          }}>
                          <li className="nav-item">
                            <a
                              className="nav-link active text-active-primary me-6"
                              data-bs-toggle="tab"
                              href="#"
                              onClick={() => {setMenuSelection("DETALHADO")}}
                            >
                              Detalhados
                            </a>
                          </li>

                          <li className="nav-item">
                            <a
                              className="nav-link text-active-primary me-6"
                              data-bs-toggle="tab"
                              href="#kt_tab_pane_2"
                              onClick={() => {setMenuSelection("GERACAO")}}
                            >
                              Geração
                            </a>
                          </li>
                        </ul>

                        <div>
                          {menuSelection === "DETALHADO" &&
                          <div
                            style={{maxHeight: '20rem', overflowY: 'auto', overflowX: "clip"}}
                            //style={{maxHeight: '20rem', overflowY: 'auto'}}
                          >
                            <a
                              className={`btn btn-sm btn-color-muted px-5 me-1 nav-item`}
                              style={{display: 'flex', gap: '1rem', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}
                              href='#'
                              //data-bs-toggle="tab"
                              onClick={()=> {
                                //selectTemplate("")
                                //formik.setValues({...formik.values, idTemplate: null})
                              }}
                            >
                              <span style={{textAlign: 'start'}}>
                                  Selecione o template 
                              </span>
                            </a>

                            {myTemplates && myTemplates.map((template: any) => {
                              if(template.type_template === "DETALHADO"){
                                const exist_template = formik.values.idsTemplates.find((id) => id === template.id)
                                //const exist_template = false
                                return (
                                  // <button
                                  //   className={`btn btn-sm btn-color-muted ${exist_template ? "btn-active-primary active" : ""} px-5 me-1 nav-item`}
                                  //     style={{display: 'flex', gap: '1rem', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}
                                  //     data-bs-toggle="tab"
                                  //     onClick={()=> {
                                  //       //selectTemplate(template.id)
                                  //       let formik_templates = formik.values.idsTemplates;
                                  //       const result = formik_templates.map(id => myTemplates.find(template => template.id === id) || null);
                                  //       const index = result.findIndex(template=> template.type_template === "DETALHADO");
                                  //       if(index === -1){
                                  //         formik_templates.push(template.id)
                                  //       }else{
                                  //         formik_templates[index] = template.id
                                  //       }
                                  //       //formik.setValues({...formik.values, idsTemplates: formik_templates})
                                  //       //setMyTemplateString(template && template.description ? template.description : "")
                                  //     }}
                                  // >
                                  //     <span style={{textAlign: 'start'}}>
                                  //         {template.description ? template.description : "Não identificado"} 
                                  //     </span>

                                  //     <span style={{textAlign: 'end'}}>
                                  //         {template.type ? template.type : "Não identificado"} 
                                  //     </span>
                                  // </button>

                                  <a
                                    className={`btn btn-sm btn-color-muted btn-active ${exist_template ? "active" : ""} btn-active-primary px-5 me-1 nav-item`}
                                    style={{display: 'flex', gap: '1rem', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}
                                    data-bs-toggle="tab"
                                    href="#"
                                    onClick={()=> {
                                      //selectTemplate(template.id)
                                      let formik_templates = formik.values.idsTemplates;
                                      const result = formik_templates.map(id => myTemplates.find(template => template.id === id) || null);
                                      const index = result.findIndex(template=> template.type_template === "DETALHADO");
                                      if(index === -1){
                                        formik_templates.push(template.id)
                                      }else{
                                        formik_templates[index] = template.id
                                      }
                                      formik.setValues({...formik.values, idsTemplates: formik_templates})
                                      setMyTemplateString(template && template.description ? template.description : "")
                                    }}
                                    >
                                    <span style={{textAlign: 'start'}}>
                                        {template.description ? template.description : "Não identificado"} 
                                    </span>

                                    <span style={{textAlign: 'end'}}>
                                        {template.type ? template.type : "Não identificado"} 
                                    </span>
                                  </a>
                                )
                              }
                            })}
                          </div>}

                          {menuSelection === "GERACAO" &&
                          <div 
                            style={{maxHeight: '20rem', overflowY: 'auto', overflowX: "clip"}}
                          >
                            <a
                              className={`btn btn-sm btn-color-muted px-5 me-1 nav-item`}
                              style={{display: 'flex', gap: '1rem', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}
                              data-bs-toggle="tab"
                              href='#'
                              onClick={()=> {
                                //selectTemplate("")
                                //formik.setValues({...formik.values, idTemplate: null})
                              }}
                            >
                              <span style={{textAlign: 'start'}}>
                                  Selecione o template 
                              </span>
                            </a>

                            {myTemplates && myTemplates.map((template: any) => {
                              if(template.type_template === "GERACAO"){
                                const exist_template = formik.values.idsTemplates.find((id) => id === template.id)
                                //const exist_template = false
                                return (
                                  <a
                                      className={`btn btn-sm btn-color-muted ${exist_template ? "btn-active-primary active" : ""} px-5 me-1 nav-item`}
                                      style={{display: 'flex', gap: '1rem', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}
                                      data-bs-toggle="tab"
                                      href='#'
                                      onClick={()=> {
                                        //selectTemplate(template.id)
                                        let formik_templates = formik.values.idsTemplates;
                                        const result = formik_templates.map(id => myTemplates.find(template => template.id === id) || null);
                                        const index = result.findIndex(template=> template.type_template === "GERACAO");
                                        if(index === -1){
                                          formik_templates.push(template.id)
                                        }else{
                                          formik_templates[index] = template.id
                                        }
                                        formik.setValues({...formik.values, idsTemplates: formik_templates})
                                        setMyTemplateGenerationString(template && template.description ? template.description : "")
                                      }}
                                  >
                                      <span style={{textAlign: 'start'}}>
                                          {template.description ? template.description : "Não identificado"} 
                                      </span>

                                      <span style={{textAlign: 'end'}}>
                                          {template.type ? template.type : "Não identificado"} 
                                      </span>
                                  </a>
                                  // <option key={concessionarie.id} value={concessionarie.name}>{concessionarie.name}</option>
                              )
                              }
                            })}
                          </div>}       
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <span className='form-check-label d-flex flex-column align-items-start'>
                <span className='fw-bolder fs-5 mb-0'>Template Padrão dos Relatórios</span>
                <span className='text-muted fs-6'>
                    Detalhado: {myTemplateString} / Geração: {myTemplateGenerationString}
                </span>
                </span>
            </label>

            {/* <label className='form-check form-check-custom form-check-solid align-items-start'>
                <input
                className='form-check-input me-3'
                type='checkbox'
                name='email-preferences[]'
                //defaultChecked={data.payouts}
                onChange={() =>
                    // updateData({
                    //   payouts: !data.payouts,
                    // })
                    console.log()
                }
                />

                <span className='form-check-label d-flex flex-column align-items-start'>
                <span className='fw-bolder fs-5 mb-0'>Payouts</span>
                <span className='text-muted fs-6'>
                    Receive a notification for every initiated payout.
                </span>
                </span>
            </label>

            <div className='separator separator-dashed my-6'></div> */}

            {/* <label className='form-check form-check-custom form-check-solid align-items-start'>
                <input
                className='form-check-input me-3'
                type='checkbox'
                name='email-preferences[]'
                //defaultChecked={data.freeCollections}
                onChange={() =>
                    // updateData({
                    //   freeCollections: !data.freeCollections,
                    // })

                    console.log()
                }
                />

                <span className='form-check-label d-flex flex-column align-items-start'>
                <span className='fw-bolder fs-5 mb-0'>Fee Collection</span>
                <span className='text-muted fs-6'>
                    Receive a notification each time you collect a fee from sales
                </span>
                </span>
            </label>

            <div className='separator separator-dashed my-6'></div> */}
    {/* 
            <label className='form-check form-check-custom form-check-solid align-items-start'>
                <input
                className='form-check-input me-3'
                type='checkbox'
                name='email-preferences[]'
                //defaultChecked={data.customerPaymentDispute}
                onChange={() =>
                    // updateData({
                    //   customerPaymentDispute: !data.customerPaymentDispute,
                    // })

                    console.log()
                }
                />

                <span className='form-check-label d-flex flex-column align-items-start'>
                <span className='fw-bolder fs-5 mb-0'>Customer Payment Dispute</span>
                <span className='text-muted fs-6'>
                    Receive a notification if a payment is disputed by a customer and for dispute
                    purposes.
                </span>
                </span>
            </label>

            <div className='separator separator-dashed my-6'></div> */}
    {/* 
            <label className='form-check form-check-custom form-check-solid align-items-start'>
                <input
                className='form-check-input me-3'
                type='checkbox'
                name='email-preferences[]'
                //defaultChecked={data.refundAlert}
                onChange={() =>
                    // updateData({
                    //   refundAlert: !data.refundAlert,
                    // })

                    console.log()
                }
                />

                <span className='form-check-label d-flex flex-column align-items-start'>
                <span className='fw-bolder fs-5 mb-0'>Refund Alerts</span>
                <span className='text-muted fs-6'>
                    Receive a notification if a payment is stated as risk by the Finance Department.
                </span>
                </span>
            </label>

            <div className='separator separator-dashed my-6'></div> */}

            {/* <label className='form-check form-check-custom form-check-solid align-items-start'>
                <input
                className='form-check-input me-3'
                type='checkbox'
                name='email-preferences[]'
                //defaultChecked={data.invoicePayments}
                onChange={() =>
                    // updateData({
                    //   invoicePayments: !data.invoicePayments,
                    // })
                    console.log()
                }
                />

                <span className='form-check-label d-flex flex-column align-items-start'>
                <span className='fw-bolder fs-5 mb-0'>Invoice Payments</span>
                <span className='text-muted fs-6'>
                    Receive a notification if a customer sends an incorrect amount to pay their
                    invoice.
                </span>
                </span>
            </label>

            <div className='separator separator-dashed my-6'></div> */}
    {/* 
            <label className='form-check form-check-custom form-check-solid align-items-start'>
                <input
                className='form-check-input me-3'
                type='checkbox'
                name='email-preferences[]'
                //defaultChecked={data.webhookAPIEndpoints}
                onChange={() =>
                    // updateData({
                    //   webhookAPIEndpoints: !data.webhookAPIEndpoints,
                    // })
                    console.log()
                }
                />

                <span className='form-check-label d-flex flex-column align-items-start'>
                <span className='fw-bolder fs-5 mb-0'>Webhook API Endpoints</span>
                <span className='text-muted fs-6'>
                    Receive notifications for consistently failing webhook API endpoints.
                </span>
                </span>
            </label> */}
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button className='btn btn-lightbtn-active-light-primary me-2'>Cancelar</button>
            <button type='button' onClick={() => {submit()}} className='btn btn-primary'>
                {!loading && 'Salvar Preferências'}
                {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                    Salvando...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
                )}
            </button>
            </div>
        </form>
        </div>
    </div>
  );
}