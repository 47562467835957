/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import { searchSubstitut } from '../../../../utils/search';
import { SearchInput } from '../../../components/SearchInput';
//import { ModalCreateCredential } from '../../../portals/components/modalCreateCredential';
import { TableBody } from './TableBody';
import { ModalAddUpdateProposal } from './components/ModalAddEditProposal';
import { ModalDetailsProposals } from './components/ModalDetailsProposals';
import { ModalDeleteProposal } from './components/ModalDeleteProposal';


type Props = {
  className: string;
  proposals: any[]
  refresh: any;
  filterFunnel: any;
  mySearch: any[];
  control: boolean;
  showCreateAppModal: boolean
  setShowCreateAppModal: any
}

const TableProposals: React.FC<Props> = ({
  className, proposals, refresh, 
  filterFunnel, mySearch, control, showCreateAppModal, 
  setShowCreateAppModal
}) => {

  const [showCreateAppModalDelete, setShowCreateAppModalDelete] = useState<boolean>(false)
  const [showCreateAppModalDetails, setShowCreateAppModalDetails] = useState<boolean>(false)
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false)
  const [myProposal, setMyProposal] = useState<any | null>(null);
  const [largura, setLargura] = useState(window.innerWidth);


  const atualizarTamanhoDaTela = () => {
    setLargura(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', atualizarTamanhoDaTela);
    return () => {
      window.removeEventListener('resize', atualizarTamanhoDaTela);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // useEffect(() => {
  //   const search_unitBranchs = mySearch.length > 0 ? 
  //           searchSubstitut(unitBranchs, mySearch)
  //         : []
  //         setMySearch(search_unitBranchs)
  //  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [unitBranchs]);

  
  return (
    <div className={`card ${className}`} style={{height: largura > 1200 ? '62vh' : '68vh', paddingBottom: '1rem'}}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{filterFunnel ? filterFunnel.description : ""}</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            Quantidade de Propostas no Funil: {" "}
            {/* {filterFunnel && filterFunnel.phasesFunnel && filterFunnel.phasesFunnel.proposals ? 
              filterFunnel.phasesFunnel.reduce((acc, phasesFunnel) => acc + phasesFunnel.proposals.length, 0)
            : 0} */}
            {proposals.length}
          </span>
        </h3>

        <div style={{display: 'flex', gap: '1rem'}}>
          {filterFunnel && filterFunnel.phasesFunell && filterFunnel.phasesFunell.length > 0 &&(
            filterFunnel.phasesFunell.map((phaseFunnel: any) => {
              return (
                <div className='d-flex align-items-center bg-primary rounded p-3 mb-7' style={{gap: '1rem', justifyContent: 'space-between'}}>
                  <div className='flex-grow-1 me-2'>
                    <a href='#' className='fw-bold text-gray-800 text-hover-muted fs-6'>
                      {phaseFunnel.description}
                    </a>
                    <span className='text-white fw-semibold d-block'>
                      Quantidade: {" "}
                      {phaseFunnel.proposals ? phaseFunnel.proposals.length : "0"}
                    </span>
                  </div>
                  <span className='fw-bold text-white py-1'>
                    R$ {phaseFunnel.proposals && phaseFunnel.proposals.length > 0 ? 
                    phaseFunnel.proposals.map(proposal => proposal.value_final).reduce((acc, curr) => acc + curr, 0) : "0"},00
                  </span>
                </div>
              )
            })
          )}
        </div>


      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3' style={largura < 1200 ? {height: '20rem', overflow: 'auto', paddingTop: '0rem'} : {overflow: 'auto'}}>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-hover table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='text-muted text-start fw-bolder fs-7 text-uppercase gs-0'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th>
                <th className='min-w-250px'>Título</th>
                <th className='min-w-140px'>Valor Final</th>
                <th className='min-w-150px'>Etapa</th>
                <th className='min-w-140px'>Responsável</th>
                <th className='min-w-140px'>Status</th>
                <th className='min-w-100px text-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
              {
                control === false ? (
                  <TableBody 
                    proposals={proposals} 
                    setMyProposals={setMyProposal}
                    setShowCreateAppModal={setShowCreateAppModal}
                    setShowCreateAppModalDelete={setShowCreateAppModalDelete}
                    setShowCreateAppModalDetails={setShowCreateAppModalDetails}
                  />
                )
                : (
                  <TableBody 
                    proposals={mySearch} 
                    setMyProposals={setMyProposal}
                    setShowCreateAppModal={setShowCreateAppModal}
                    setShowCreateAppModalDelete={setShowCreateAppModalDelete}
                    setShowCreateAppModalDetails={setShowCreateAppModalDetails}
                  />
                )
              }
              
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>

          <ModalAddUpdateProposal
            show={showCreateAppModal}
            id={myProposal ? myProposal.id : undefined} 
            data={myProposal ? myProposal : undefined}
            handleClose={() => {
              setMyProposal(null)
              setShowCreateAppModal(false)
              refresh()
            }} 
          />

          <ModalDetailsProposals
            show={showCreateAppModalDetails}
            data={myProposal ? myProposal : undefined}
            id={myProposal ? myProposal.id : undefined} 
            handleClose={() => {
              setMyProposal(null)
              setShowCreateAppModalDetails(false)
              refresh()
            }} 
          />

          <ModalDeleteProposal
            show={showCreateAppModalDelete}
            data={myProposal ? myProposal : undefined}
            id={myProposal ? myProposal.id : undefined} 
            handleClose={() => {
              setMyProposal(null)
              setShowModalDelete(false)
              refresh()
            }} 
          />

        {/* <ModalAddUnitBranch 
          show={showCreateAppModal}
          id={myUnitBranch ? myUnitBranch.id : undefined} 
          data={myUnitBranch ? myUnitBranch : undefined}
          handleClose={() => {
            setMyUnitBranch(null)
            setShowCreateAppModal(false)
            refresh()
          }} 
        />

        <ModalDetailsUnitBranch 
          show={showCreateAppModalDetails}
          id={myUnitBranch ? myUnitBranch.id : undefined} 
          handleClose={() => {
            setMyUnitBranch(null)
            setShowCreateAppModalDetails(false)
            refresh()
          }} 
        /> */}
      {/* begin::Body */}
    </div>
  )
}

export {TableProposals}
