import React, { useEffect, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
//import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { LoadingSpan } from '../Loadings/loadingSpan';
import { KTSVG } from '../../../../_metronic/helpers';
import * as Yup from "yup";
import { useFormik } from 'formik';
import { postTemplateOne, putTemplateAll } from '../../../services/templates';
import { getUnitBranchAll } from '../../../services/unitBranchService';
import { DropdownMenuDates } from './components/DropdownMenuDados/dropdownMenuDates';
import Editor from "ckeditor5-custom-build/build/ckeditor";
import './styles.css'
import { getfileUrl } from '../../../services/uploadService';
import { useAuth } from '../../../modules/auth';
import { getFile } from '../../../services/files';
import { DropdownMenuDatesV2 } from './components/DropdownMenuDatesV2';


// const editorConfiguration = {
//   plugins: [ Bold, Italic, Code],
//   toolbar: [ 'bold', 'italic', 'code'  ]
// };

type Props = {
  initialHtml: string,
  setEditorStateV2: any,
  mySelectTemplate: any
  refresh: any;
  setInitialHtml: any;
  formikReport : any;
  setMyTemplate: any;
  myTemplates: any[]
}



const validationSchema = Yup.object().shape({
  textHTML: Yup.string(),
  description: Yup.string(),
  type: Yup.string(),
  type_template:  Yup.string(),
  idUnitBranch: Yup.string()
});

const CKEditorV2 = ({initialHtml, setMyTemplate, mySelectTemplate, refresh, setInitialHtml, formikReport, myTemplates} : Props) => {
  const [, setEditorData] = useState('');
  const [generateTemplate, setGenerateTemplate] = useState(false);
  const [loading, setLoading] = useState(false)
  const [loadingUnitBranchs, setLoadingUnitBranchs] = useState(false)
  const [unitBranchs, setUnitBranchs] = useState<any[]>([]);
  const user = useAuth()

  //const editorRef = useRef<any>(null);

  const [initialValues] = useState({
    textHTML: initialHtml,
    description: null,
    type: "private",
    type_template: "DETALHADO",
    idUnitBranch : null
  })

  const verificadValues = () => {
    const myValues = {
      year: initialHtml.includes("{{year}}") ? true : false,
      month: initialHtml.includes("{{month}}") ? true : false,
      generation: initialHtml.includes("{{generation}}") ? true : false,
      performance: initialHtml.includes("{{performance}}") ? true : false,
      consumption: initialHtml.includes("{{consumption}}") ? true : false,
      saved: initialHtml.includes("{{saved}}") ? true : false,
      initPeriod: initialHtml.includes("{{initPeriod}}") ? true : false,
      endPeriod: initialHtml.includes("{{endPeriod}}") ? true : false, 
      corporateName: initialHtml.includes("{{corporateName}}") ? true : false,

      performance_anual: initialHtml.includes("{{performance_anual}}") ? true : false,
      generation_anual: initialHtml.includes("{{generation_anual}}") ? true : false,
      prog_12_meses: initialHtml.includes("{{prog_12_meses}}") ? true : false,
      capacity: initialHtml.includes("{{capacity}}") ? true : false,

      invoiced: initialHtml.includes("{{invoiced}}") ? true : false,
      total_economy: initialHtml.includes("{{total_economy}}") ? true : false,
      financial_feedback_month: initialHtml.includes("{{financial_feedback_month}}") ? true : false,
      financial_feedback_year: initialHtml.includes("{{financial_feedback_year}}") ? true : false,

      consumerRegister: initialHtml.includes("{{consumerRegister}}") ? true : false,
      consumerInstant: initialHtml.includes("{{consumerInstant}}") ? true : false,
      unitConsumerTotal: initialHtml.includes("{{unitConsumerTotal}}") ? true : false,
      energyInjected: initialHtml.includes("{{energyInjected}}") ? true : false,

      cnpj: initialHtml.includes("{{cnpj}}") ? true : false,
      companyName: initialHtml.includes("{{companyName}}") ? true : false,
      emailCompany: initialHtml.includes("{{emailCompany}}") ? true : false,
      namePlant: initialHtml.includes("{{namePlant}}") ? true : false,
      adressPlant: initialHtml.includes("{{adressPlant}}") ? true : false,
      mark: initialHtml.includes("{{mark}}") ? true : false,


      graphic_mensal_combination: initialHtml.includes("{{graphic_mensal_combination}}") ? true : false,
      graphic_days: initialHtml.includes("{{graphic_days}}") ? true : false,
      graphic_months: initialHtml.includes("{{graphic_months}}") ? true : false,
      
    }
    return myValues
  }

  const [valuesReport, setVeluesReport] = useState(verificadValues())

  


  // const [editorStateV2, setEditorStateV2] = useState(() => {
  //   if(initialHtml){
  //     return { __html: initialHtml };
  //   }
  //   return { __html: '' };
  // });


  // const handleEditorReady = (editor) => {
  //   //setEditor(editor);
  //   editor.config.define('contentFilter', {
  //     classes: true,
  //     styles: true,
  //   });
  // };

  const getImage = async (key: string) => {
    try {
      const url = await getFile(key);
      return url
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
    
    setInitialHtml(data);
    formik.setValues({...formik.values, textHTML: data})

    //setEditorStateV2({ __html: data });

  };

  const handleCheckBoxValues = (checked: boolean, value: string) => {
    if(checked){
      const newHTML = initialHtml + `{{${value}}} </br>`
      
      setInitialHtml(newHTML);
      formik.setValues({...formik.values, textHTML: newHTML})

    }else{
      const newHTML = initialHtml.replace(`{{${value}}}`, "");
      setInitialHtml(newHTML);
      formik.setValues({...formik.values, textHTML: newHTML})
    }
  }

  const handleCheckBoxValuesImg = async (value: string) => {
    if(
      user && user.currentUser 
      && user.currentUser.companies 
      && user.currentUser.companies.length > 0 
      && user.currentUser.companies[0].image){

      let newHTML = ""
      const url = await getImage(user.currentUser.companies[0].image)

      if(value === 'logo_100'){
        newHTML = initialHtml + `<img src="${url.url}" alt="" style="height:100px;"/> </br>`
      }

      if(value === 'logo_200'){
        newHTML = initialHtml + `<img src="${url.url}" alt="" style="height:200px;"/> </br>`
      }

      if(value === 'logo_500'){
        newHTML = initialHtml + `<img src="${url.url}" alt="" style="height:500px;"/> </br>`
      }

      if(value === 'logo_700'){
        newHTML = initialHtml + `<img src="${url.url}" alt="" style="height:700px;"/> </br>`
      }
      
      setInitialHtml(newHTML);
      formik.setValues({...formik.values, textHTML: newHTML})

    }
  }


  const getAllUnitBranchs = async () => {
    try {
      setLoadingUnitBranchs(true)
      const unitBranchs = await getUnitBranchAll()
      setUnitBranchs(unitBranchs)

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar a lista de unidades. ", "error")

    }finally{
      setLoadingUnitBranchs(false)
    }
  }

  const postTemplate = async () => {
    try {
      setLoading(true)
      const myData = {
        textHTML: formik.values.textHTML,
        description: formik.values.description,
        type: user.currentUser?.role.slug !== "ADMIN" ? "private" : "public",
        type_template: formik.values.type_template,
        idUnitBranch : formik.values.idUnitBranch
      }
 
      const myTemplate = await postTemplateOne(myData)
      setInitialHtml(myTemplate.textHTML)

      if(myTemplate){
        let formik_templates = formikReport.values.idsTemplates;
        const result = formik_templates.map(id => myTemplates.find(template => template.id === id) || null);

        const index = result.findIndex(template => template.type_template === myTemplate.type_template);
        if(index === -1){
          formik_templates.push(myTemplate.id)
        }else{
          formik_templates[index] = myTemplate.id
        }
        formikReport.setValues({...formikReport.values, idsTemplates: formik_templates})

        //formikReport.setValues({...formik.values, idTemplate: template.id})

        setMyTemplate(myTemplate)
      }

      toggleToast("Sucesso ao cadastrar o template. ", "success")

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao cadastrar template. ", "error")
    }finally{
      setLoading(false)
      refresh()
      setGenerateTemplate(false)
    }
  }

  const postImgUrl = async (file) => {
    try {
      const url = await getfileUrl(file);
      return url;
    } catch (error) {
      toggleToast("Erro ao inserir a imagem. ", "error");
    }
  };

  const putTemplate = async (id: string) => {
    try {
      setLoading(true)
      const myData = {
        textHTML: formik.values.textHTML,
        description: formik.values.description,
        type: formik.values.type,
        type_template: formik.values.type_template
      }
 
      await putTemplateAll(id, myData)
      toggleToast("Sucesso ao atualizar o template. ", "success")

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao atualizar o template. ", "error")
    }finally{
      setLoading(false)
      refresh()
    }
  }


  const submit = (type: number) => {
    if(type === 1){
      if(mySelectTemplate){
        putTemplate(mySelectTemplate.id)
      }
    }else if (type === 0){
      postTemplate()
    }
  }


  // function uploadPlugin(editor: any) {
    
  // }

  // function uploadPlugin(editor: any) {
  //   editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
  //     return {
  //       upload: () => {
  //         return new Promise(async (resolve, reject) => {
  //           try {
  //             const body = new FormData();
  
  //             const file = await loader.file;
  //             body.append("file", file);
  
  //             const url = await postImgUrl(body);
  
  //             // Adicione um ID específico à imagem antes de inseri-la no editor
  //             const imageId = 'myImg'; // Substitua 'suaIdEspecifica' pelo ID desejado
  
  //             // Crie um elemento de imagem com o ID específico
  //             const img = new editor.model.insertContent(
  //               editor.model.schema.create('image', {
  //                 src: url.url,
  //                 attributes: {
  //                   'data-id': imageId,
  //                 },
  //               })
  //             );
  
  //             resolve({
  //               default: url.url,
  //             });
  //           } catch (error) {
  //             reject(error);
  //           }
  //         });
  //       },
  //     };
  //   };
  // }

//   function uploadPlugin(editor) {
//     editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
//         return {
//             upload: () => {
//                 return new Promise(async (resolve, reject) => {
//                     try {
//                         const body = new FormData();
//                         const file = await loader.file;
//                         body.append("file", file);

//                         const url = await postImgUrl(body);

//                         // Resolva a URL da imagem
//                         resolve({
//                             default: url.url,
//                         });

//                         // Adicione a imagem ao editor
//                         editor.model.change(writer => {
//                             const imageElement = writer.createElement('image', {
//                                 src: url.url,
//                                 'data-id': 'myImg' // ID específico da imagem
//                             });
//                             // Inserir a imagem no lugar do cursor atual
//                             editor.model.insertContent(imageElement, editor.model.document.selection);
//                         });
//                     } catch (error) {
//                         reject(error);
//                     }
//                 });
//             },
//         };
//     };
// }

function uploadPlugin(editor: any) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return {
          upload: () => {
              return new Promise(async (resolve, reject) => {
                  try {
                      const body = new FormData();
                      const file = await loader.file;
                      body.append("file", file);

                      const url = await postImgUrl(body);

                      resolve({
                          default: url.url,
                      });

                      editor.model.change(writer => {
                          const imageElement = writer.createElement('imageBlock', {
                              src: url.url,
                              'data-id': 'myImg'
                          });
                          const insertAtSelection = editor.model.document.selection.getFirstPosition();
                          editor.model.insertContent(imageElement, insertAtSelection);
                      });
                  } catch (error) {
                      reject(error);
                  }
              });
          },
      };
  };
}

//   function divPlugin(editor: any) {

//     editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
//       return {
//         upload: () => {
//           return new Promise(async (resolve, reject) => {
//             try {
//               const body = new FormData();
  
//               const file = await loader.file;
//               body.append("file", file);
  
//               const url = await postImgUrl(body);
  
//               resolve({
//                 default: url.url
//               });
//             } catch (error) {
//               reject(error);
//             }
//           });
//         }
//       };
//     };
//     // Adiciona o comando ao editor
//     // editor.commands.add('insertDiv', {
//     //     exec: function (editor) {
//     //         // Insere uma div no cursor atual
//     //         const divElement = editor.model.document.createElement('div');
//     //         editor.model.insertContent(divElement);
//     //     }
//     // });

//     // // Adiciona um botão à barra de ferramentas
//     // editor.ui.componentFactory.add('DivButton', locale => {
//     //     const button = document.createElement('button');
//     //     button.setAttribute('title', 'Inserir Div');
//     //     button.textContent = 'Div';
//     //     button.addEventListener('click', () => {
//     //         editor.execute('insertDiv');
//     //     });

//     //     return button;
//     // });
// }

  

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: () => {},
  });

  // const onReady = (editor) => {
  //   // Configurar o editor como necessário
  //   //setEditorInstance(editor);
  // };


  useEffect(() => {
    const data = {
      textHTML: mySelectTemplate.textHTML ? mySelectTemplate.textHTML : "<h1>Não encontrado.. </h1>",
      description: mySelectTemplate.description ? mySelectTemplate.description : null,
      type: mySelectTemplate.type ? mySelectTemplate.type : null,
      type_template: mySelectTemplate.type_template ? mySelectTemplate.type_template : null,
      idUnitBranch: mySelectTemplate.unitBranch ? mySelectTemplate.unitBranch.id : null
    }
    formik.setValues(data)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mySelectTemplate]);


  useEffect(() => {
    setVeluesReport(verificadValues)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialHtml]);

  useEffect(() => {
    getAllUnitBranchs()
  }, []);

  useEffect(() => {
    console.log('values', formik.values)
  }, [formik.values])


return (
  <div className='bg-light' style={{ display: 'flex', gap: '2rem', padding: '1rem', width: '100%', flexDirection: 'column' , height: '50rem', overflow: 'auto'}}>
    <div className='bg-body' style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1rem'}}>
      <h3 className='align-items-start flex-column' style={{display: "flex"}}>
          <span className='card-label fw-bold fs-3 mb-1'>Dados Adicionados</span>
          <span className='text-muted fw-semibold fs-7'>Dados que serão vinculados ao relatório</span>
      </h3>
      <div className='me-0 dropdown'>
        <button
          className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
          type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"
          //data-kt-menu-trigger='click'
          //data-kt-menu-dismiss='false'
          //data-kt-menu-placement='bottom-end'
          //data-kt-menu-flip='top-end'
        >
          <i className='bi bi-three-dots fs-3'></i>
        </button>
        
          <DropdownMenuDates 
            valuesReport={valuesReport} 
            handleCheckBoxValues={handleCheckBoxValues}
            handleCheckBoxValuesImg={handleCheckBoxValuesImg}
            myTemplateSelected={mySelectTemplate}
          />
      </div>

    </div>
    <div style={{ flex: '1', overflowY: 'auto', height: '40rem', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <div id="editor"></div>
        <CKEditor
          // @ts-ignore
          editor={ Editor.Editor }
          data={initialHtml}
          //ref={editorRef}
          config={{
          //   toolbar:[
          //     'imageStyle:inline', 
          //     'imageStyle:wrapText', 
          //     'imageStyle:breakText', 
          //     'imageStyle:side',
          //     'imageStyle:block',
          //     'imageStyle:alignLeft',
          //     'imageStyle:alignRight',
          //     'imageStyle:alignCenter',
          //     'imageStyle:float',
          //     'imageStyle:alignBlockLeft',

          //     '|',
          //     'toggleImageCaption', 
          //     'imageTextAlternative', 
          // ],
            //plugins: [Image, ImageResize],
            //toolbar: ['ckbox'],
            
            licenseKey: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxIiwiYXV0aCI6eyJjb2xsYWJvcmF0aW9uIjp7IioiOnsicm9sZSI6IndyaXRlciJ9fSwiY2tib3giOnsicm9sZSI6InN1cGVyYWRtaW4ifX0sInVzZXIiOnsiaWQiOiIxIiwibmFtZSI6IlN0YXJsaW4gQnJhbXN0b20iLCJlbWFpbCI6Im9qdGlzaWZAdWdlLmJnIn0sImlzRGV2VG9rZW4iOnRydWUsInRpbWVzdGFtcCI6MTcwOTMwMTY3NTU0Mywic2lnbmF0dXJlIjoiZjk3NTBlZDA5OThiMDc4NDk1YmQ2NDA4ZjYxYzZiZmMzOWQ5NGU5Y2Q1N2ZjNzlmN2Y3OTAwZDI2YzAxNjIwZCIsImV4cCI6MTcwOTMwNTI3NSwianRpIjoiN3RIUkcyUFNfMGZ0YzZiekJQWUtrdGt4dVdvdHRIUFYiLCJhdWQiOiJ6Zk56c2xQUnM5WW4wZldkcFh1ayIsImlhdCI6MTcwOTMwMTY3NX0.r42wV0NFJOj1V_BOoZPYmlQ9329MJwciBhX6ml3zo_Q",
            extraPlugins: [uploadPlugin],
            // @ts-ignore
            htmlSupport: {
              // allow: [
              //     {
              //       name: 'div',
              //       classes: ['selectable-div']
              //     },
              //     {
              //       name: 'svg',
              //       styles: true,
              //       classes: true
              //     },
              //     {
              //       name: 'figure',
              //       styles: true,
              //       classes: true
              //     },
              //     {
              //       name: 'body',
              //       styles: true,
              //       classes: true
              //     },
              //     {
              //       name: 'table',
              //       styles: true,
              //       classes: true
              //     },
              //     {
              //       name: 'tr',
              //       styles: true,
              //       classes: true
              //     },
              //     {
              //       name: 'a',
              //       styles: true,
              //       classes: true
              //     },
              //     {
              //       name: 'path',
              //       styles: true,
              //       classes: true
              //     },
              //     {
              //       name: 'i',
              //       styles: true,
              //       classes: true
              //     },
              //     {
              //       name: /^(div|section|article)$/
              //     },
              //     {
              //         name: 'div',
              //         styles: true,
              //         classes: true
              //     },
              //     {
              //       name: 'span',
              //       styles: true,
              //       classes: true
              //     },
              //     {
              //       name: 'h3',
              //       styles: true,
              //       classes: true
              //     },
              //     {
              //         name: 'div',
              //         classes: [ 'foo', 'bar' ]
              //     },
              //     {
              //         name: 'p',
              //         classes: [ 'foo', 'bar' ]
              //     },
              //     {
              //         name: 'div',
              //         styles: {'position': 'relative'},
              //         attributes: {
              //             foo: 'true',
              //             bar: true
              //         }
              //     },
      
              // ]
              allow: [
                {
                    name: /.*/,
                    attributes: true,
                    classes: true,
                    styles: true
                }
            ]
        },
            image: { toolbar: [
              'imageStyle:inline', 'imageStyle:wrapText', 'imageStyle:breakText', '|',
              'toggleImageCaption', 'imageTextAlternative'
            ]},
            style: {
              definitions: [
                {
                  name: 'Article category',
                  element: 'h3',
                  classes: [ 'category' ]
              },
              {
                  name: 'Info box',
                  element: 'p',
                  classes: [ 'info-box' ]
              },
              {
                name: 'Image sobreposta',
                element: 'figure',
                classes: [ 'imgSobreposta' ]
              },
              {
                name: 'Image Folha',
                element: 'img',
                classes: [ 'imgFolha' ]
              },
                
              ]
            },
            
                    
            // ckbox: {
            //   // defaultUploadCategories: {
            //   //   Bitmaps: [ 'bmp' ],
            //   //   Pictures: [ 'jpg', 'jpeg' ],
            //   //   Scans: [ 'png', 'tiff' ]
            //   // },
            //   // ignoreDataId: true,
            //   // serviceOrigin: 'https://example.com/',
            //   // //assetsOrigin: 'https://example.cloud/',
            //   tokenUrl: 'https://106405.cke-cs.com/token/dev/cGz0bBoYyV5qcVHNOprqB8A9dMM9nGMHiDUx?limit=10'
            // }
          }}
          onChange={ ( event, editor ) => {
            handleEditorChange(event, editor)
          }}
          
        />
      {/* <div>
        <button onClick={() => {
          
          const editor = Editor.Editor


        // Use o plugin de exportação de PDF para exportar o HTML
        editor.defaultConfig.exportPdf?.d
        editor.defaultConfig.get('ExportPdf').execute().then(data => {
            // 'data' contém o PDF exportado em formato de dados, que você pode manipular conforme necessário
        }).catch(error => {
            console.error('Erro ao exportar PDF:', error);
        });

        }}>teste</button>
      </div> */}

      {/* <CKEditor
        editor={DecoupledEditor}
        onReady={onReady}
        data="<p>Conteúdo inicial</p>"
      /> */}

      <div style={{display: 'flex', gap: '1rem'}}>
      <button
          type='button'
          className='btn btn-outline-success'
          //disabled={loading}
          //data-kt-stepper-action='submit'
          onClick={() => setGenerateTemplate(true)}
        >
          Gerar Template
          {
            loading ? 
            <LoadingSpan style={{marginLeft: '0.5rem'}}/>
            :
            <KTSVG
                path='/media/icons/duotune/arrows/arr064.svg'
                className='svg-icon-3 ms-2 me-0'
            />
          }
      </button>

      <button
          type='button'
          className='btn btn-outline-primary'
          disabled={!mySelectTemplate.id || mySelectTemplate.type === "public" ? true : false}
          //data-kt-stepper-action='submit'
          onClick={() => {
            setGenerateTemplate(false)
            submit(1)
          }}
          //onClick={putTemplate}
        >
          Atualizar Template
          {
            loading ? 
            <LoadingSpan style={{marginLeft: '0.5rem'}}/>
            :
            <KTSVG
                path='/media/icons/duotune/arrows/arr064.svg'
                className='svg-icon-3 ms-2 me-0'
            />
          }
      </button>
      </div>
    </div>
    
    {generateTemplate ? 
      loadingUnitBranchs ?
        <><h1>Carregando..</h1></>
      :
        <div>
          <div className='mb-10' style={{display: 'flex', gap: '1rem', alignItems: 'center'}}>
            <div style={{flex: '1'}}>
              <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                  <span className='required'>Descrição</span>
                  <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Fim do período de análise do relatório'
                  ></i>
              </label>
            </div>
            <div style={{flex: '3'}}>
              <input
                  type='text'
                  className='form-control form-control-lg'
                  name='endPeriod'
                  disabled={loading}
                  placeholder=''
                  value={formik.values.description ? formik.values.description : ""}
                  onChange={formik.handleChange("description")}
              />
            </div>
          </div>

          <div style={{}}>
            <div style={{}}>
              <div className='mb-10' style={{display: 'flex', gap: '1rem'}}>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  <span className='required'>Unidade (Filial)</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Selecione para qual unidade filial essa credencial será vinculada. '
                  ></i>
                </label>
                <div style={{flexGrow: '1', display: 'flex', gap: '1rem'}}>
                  <select
                    className='form-select form-select-solid bg-white'
                    data-kt-select2='true'
                    disabled={loading}
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    defaultValue={formik.values.idUnitBranch ? formik.values.idUnitBranch : '1'}
                    onChange={formik.handleChange("idUnitBranch")}
                  >
                    <option value={'1'}>Selecione uma Unidade (Filial)</option>
                    {unitBranchs && unitBranchs.map((myUnitBranch: any) => {
                      return (
                        <option key={myUnitBranch.id} value={myUnitBranch.id}>{myUnitBranch.companyName}</option>
                      )
                    })}
                    
                  </select>
                  {/* {!data.idUnitBranch && hasError && (
                  <div className='fv-plugins-message-container'>
                    <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                      Selecione a unidade!
                    </div>
                  </div>
                )} */}

                <select
                    className='form-select form-select-solid bg-white'
                    data-kt-select2='true'
                    disabled={loading}
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    defaultValue={formik.values.type_template ? formik.values.type_template : 'DETALHADO'}
                    onChange={formik.handleChange("type_template")}
                  >
                    <option value={'GERACAO'}>Geração</option>
                    <option value={'DETALHADO'}>Detalhado</option>
                    
                  </select>

                </div>
              </div>
            </div>
          </div>

          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <button
              type='button'
              className='btn btn-lg btn-secondary'
              disabled={loading}
              //data-kt-stepper-action='submit'
              onClick={() => {
                setGenerateTemplate(false)
              }}
              >
                Cancelar
                {
                  loading ? 
                  <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                  :
                  <KTSVG
                      path='/media/icons/duotune/arrows/arr063.svg'
                      className='svg-icon-3 ms-2 me-0'
                  />
                }
            </button>

            <button
                type='button'
                className='btn btn-lg btn-primary'
                //disabled={loading}
                //data-kt-stepper-action='submit'
                onClick={() => submit(0)}
              >
                Gerar
                {
                  loading ? 
                  <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                  :
                  <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-3 ms-2 me-0'
                  />
                }
            </button>
          </div>

        </div> 

      : <></>
    }

    {/* <div style={{ flex: '1', height: '85%' }}>
      <div style={{marginBottom: '1rem'}}>
        <h3 className='align-items-start flex-column' style={{display: "flex"}}>
          <span className='card-label fw-bold fs-3 mb-1'>Visualização do layout</span>
          <span className='text-muted fw-semibold fs-7'>Renderização da edição do relatório</span>
        </h3>
      </div>
      
      <div style={{height: '100%', overflowY: 'auto', padding: '1rem',}} className="border border-gray-300 border-dashed rounded">
        <div dangerouslySetInnerHTML={editorStateV2} />
      </div>  
    </div> */}
  </div>
);
};

export default CKEditorV2;

function toggleToast(arg0: string, arg1: string) {
  throw new Error('Function not implemented.');
}
