/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import { KTSVG } from '../../../_metronic/helpers';
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import { useAuth } from '../../modules/auth';
import { SearchInput } from '../components/SearchInput';
import { TableProposals } from './components/TableProposals';
import { getFunnelsAll } from '../../services/funnels';
import { useToast } from '../components/Toats';
import { ModalAddEditFunnel } from './components/ModalAddEditFunnels';
import { ModalDeleteFunnel } from './components/ModalDeleteFunnel';
import { Loading } from '../components/Loadings/loading';
import { getProposalsAll, getProposalsQtd } from '../../services/proposalsService';

const proposalGeneratorBreadcrumbs: Array<PageLink> = [
  {
    title: 'Painel de Controle',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ProposalGeneratorPage: FC = () => {
    const { toggleToast } = useToast();
    const user = useAuth()
    const [mySearch, setMySearch] = useState<any[]>([]);
    const [control, setControl] = useState(false);
    const [myProposals, setMyProposals] = useState<any[]>([]);

    const [myProposalsQtd, setMyProposalsQtd] = useState<any>(null);
    const [funnels, setFunnels] = useState<any[]>([]);
    const [loadingFunnels, setLoadingFunnels] = useState(true);
    const [loadingProposals, setLoadingProposals] = useState(true);

    const [loadingProposalsQtd, setLoadingProposalsQtd] = useState(true);
    const [filterFunnel, setFilterFunnel] = useState<any>(null);
    const [filterStatus, setFilterStatus] = useState<any>("");
    const [myFunnel, setMyFunnel] = useState<any>(null)
    const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
    const [showCreateAppModalDelete, setShowCreateAppModalDelete] = useState<boolean>(false)
    const [showCreateAppModalProposal, setShowCreateAppModalProposal] = useState<boolean>(false)
    

    const getFunnels = async () => {
      try {
        setLoadingFunnels(true)
        const funnels = await getFunnelsAll()
        setFunnels(funnels)
        setFilterFunnel(funnels.length > 0 ? funnels[0] : {})
      } catch (error) {
        console.log(error)
        toggleToast("Erro ao buscar os funis. ", "error")
      }finally{
        setLoadingFunnels(false)
      }
    }

    const getProposals = async () => {
      try {
        setLoadingProposals(true)
        const proposals = await getProposalsAll(filterStatus, filterFunnel && filterFunnel.id ? filterFunnel.id : "")
        setMyProposals(proposals)
      } catch (error) {
        console.log(error)
        toggleToast("Erro ao buscar as propostas. ", "error")
      }finally{
        setLoadingProposals(false)
      }
    }

    const getProposalsQtdAll = async () => {
      try {
        setLoadingProposalsQtd(true)
        const qtd = await getProposalsQtd(filterFunnel && filterFunnel.id ? filterFunnel.id : "")
        setMyProposalsQtd(qtd)
      } catch (error) {
        console.log(error)
        toggleToast("Erro ao buscar as quantidades de propostas. ", "error")
      }finally{
        setLoadingProposalsQtd(false)
      }
    }


    const refresh = () => {
      getFunnels()
      getProposals()
    }

    useEffect(() => {
      async function all(){
        await getFunnels()
        await getProposalsQtdAll()
        await getProposals()
      }
      all()
    }, [])

    useEffect(() => {
      getProposals()
      getProposalsQtdAll()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterStatus, filterFunnel])

    if (loadingFunnels) {
      return (
        <div style={{display: 'flex', justifyContent: 'center', height: '30rem', alignItems: 'center'}}>
          <Loading myClass='spinner-border' style={{width: '3rem', height: '3rem'}}/>
        </div>
      );
    }

  return (
    <div className='column g-5 g-xl-8 card p-7'>
      <div style={{display: 'flex', alignItems: 'center', gap: '1rem', justifyContent: 'space-between'}}>
        <div style={{display: 'flex', alignItems: 'center', gap: '1rem'}}>
          <div className="btn-group" role="group" aria-label="Basic radio toggle button group" 
            style={{width: '10rem', height: '3rem'}}
          >
            <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off" checked />
            <label className="btn btn-outline-primary" htmlFor="btnradio1" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <KTSVG path='/media/icons/duotune/text/txt012.svg' className='svg-icon-3' />
            </label>

            {/* <input type="radio" className="btn-check" name="btnradio" id="btnradio3" autoComplete="off" />
            <label className="btn btn-outline-primary" htmlFor="btnradio3" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <KTSVG path='/media/icons/duotune/general/gen009.svg' className='svg-icon-3' />
            </label> */}
          </div>

          <div style={{width: '25rem'}}>
            <div className='me-0 dropdown' style={{width: '100%'}}>
              <button
                className='btn btn-lg btn-light buttonFilterMark'
                type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"
                style={{display: 'flex', gap: '1rem'}}
              >
                {/* <span>{filterConcessionaries !== "" ? filterConcessionaries : "Todas"} </span> */}
                <span>{filterFunnel ? filterFunnel.description : ""}</span>
                <i className='bi bi-three-dots fs-3'></i>
              </button>

              <div className='dropdown-menu dropdown-menu-end' aria-labelledby="dropdownMenuButton1" style={{padding: '1rem', width: '100%'}}>
                <div className='card-toolbar nav nav-tabs' data-kt-buttons='true' style={{display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'start'}}>
                  {funnels && funnels.map((funnel: any) => {
                  return (
                    <a
                      className={`btn btn-sm btn-active btn-active-primary px-5 me-1 nav-item`}
                      style={{display: 'flex', gap: '1rem', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}
                      data-bs-toggle="tab"
                      href="#"
                      onClick={()=> {
                        setFilterFunnel(funnel ? funnel : "")
                      }}
                    >
                    <span style={{textAlign: 'start'}}>
                      {funnel.description ? funnel.description : "Não identificado"} 
                    </span>
                    <div>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        <a
                            onClick={(event: any) =>{
                                setShowCreateAppModal(true)
                                setMyFunnel(funnel)
                                //handleButtonClickUpdate(event, unitBranch)
                            }}
                            href='#'
                            className='btn btn-icon btn-bg-light btn-sm me-1'
                        >
                            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3 text-primary' />
                        </a>
                        <a
                            onClick={(event: any) => {
                              setMyFunnel(funnel)
                              setShowCreateAppModalDelete(true)

                            }}
                            href='#'
                            className='btn btn-icon btn-bg-light btn-sm'
                        >
                            <KTSVG
                            path='/media/icons/duotune/general/gen027.svg'
                            className='svg-icon-3 text-primary'
                            />
                        </a>
                      </div>
                    </div>
                  </a>
                    // <option key={concessionarie.id} value={concessionarie.name}>{concessionarie.name}</option>
                  )
                })}
                <div className='separator my-2 mb-2' style={{width: '100%'}}></div>

                <div style={{width: '100%'}}>
                  <div
                    className='card-toolbar'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Click para adicionar uma unidade filial'
                    style={{width: '100%', display: 'flex', justifyContent: 'end'}}
                  >
                    <a
                      onClick={() =>{
                        setShowCreateAppModal(true)
                      }}
                      href='#'
                      style={{width: "100%"}}
                      className='btn btn-sm btn-light-primary'
                      //data-bs-target='#kt_modal_create_unitBranch'

                    >
                      <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                      Adicionar Funil
                    </a>
                  </div>
                </div>

                </div>
              </div>
            </div>
          </div>

          <div className='card-toolbar nav nav-tabs' data-kt-buttons='true'>
            <a
                className='btn btn-sm btn-color-muted btn-active btn-active-dark active px-5 me-1 nav-item'
                data-bs-toggle="tab"
                href="#"
                style={{display: 'flex', alignItems: 'center'}}
                onClick={()=> {
                  setFilterStatus("")
                }}
            >
                Todas 
                <span 
                className='badge badge-dark spanFilter'

                >
                {myProposals.length}
                </span>
            </a>
            <a
                className='btn btn-sm btn-color-muted btn-active btn-active-warning px-4 nav-item'
                data-bs-toggle="tab"
                href="#"
                style={{display: 'flex', alignItems: 'center'}}
                onClick={()=> {
                  setFilterStatus("EM NEGOCIACAO")
                }}
            >
                Em Negociação
                <span 
                className='badge badge-warning spanFilter'
                >
                {myProposalsQtd && myProposalsQtd.em_negociacao ? myProposalsQtd.em_negociacao : 0}
                </span>
            </a>
            <a
                className='btn btn-sm btn-color-muted btn-active btn-active-danger px-4 nav-item'
                data-bs-toggle="tab"
                href="#"
                style={{display: 'flex', alignItems: 'center'}}
                onClick={()=> {
                  setFilterStatus("NEGOCIO RECUSADO")
                }}
            >
                Negócio Recusado
                <span 
                className='badge badge-danger spanFilter'
                >
                {myProposalsQtd && myProposalsQtd.negocio_recusado ? myProposalsQtd.negocio_recusado : 0}
                </span>
            </a>
            <a
                className='btn btn-sm btn-color-muted btn-active btn-active-success px-4 me-1 nav-item'
                data-bs-toggle="tab"
                href="#"
                style={{display: 'flex', alignItems: 'center'}}
                onClick={()=> {
                  setFilterStatus("NEGOCIO FECHADO")
                }}
            >
                Negócio Fechado
                <span 
                className='badge badge-success spanFilter'
                >
                {myProposalsQtd && myProposalsQtd.negocio_fechado ? myProposalsQtd.negocio_fechado : 0}
                </span>
            </a>
          </div>
        </div>
        <div style={{display: 'flex', alignItems: 'center', gap: '1rem', justifyContent: 'end'}}>
          <SearchInput 
              className='form-control form-control-solid ps-14 bg-body'
              placeholder='Buscar Propostas'
              myDate={myProposals}
              setMySearch={setMySearch}
              control={control}
              setControl={setControl}
              searchParameter="description"
              style={{width: "15rem"}}
          />
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click para adicionar uma unidade filial'
            style={{display: 'flex', justifyContent: 'end'}}
          >
            <a
              onClick={() =>{
                setShowCreateAppModalProposal(true)
              }}
              href='#'
              className='btn btn-sm btn-light-primary'
              //data-bs-target='#kt_modal_create_unitBranch'

            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
              Nova Proposta
            </a>
          </div>
        </div>
      </div>
      <div>
        {loadingProposals ? 
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Loading myClass='spinner-border' style={{width: '3rem', height: '3rem'}}/>
        </div>
        : 
          <TableProposals 
            className='card-xl-stretch mb-xl-8'
            proposals={myProposals}
            refresh={refresh}
            filterFunnel={filterFunnel} 
            mySearch={mySearch}
            control={control}
            setShowCreateAppModal={setShowCreateAppModalProposal}
            showCreateAppModal={showCreateAppModalProposal}
          />
        }
        
      </div>
      <ModalAddEditFunnel 
        show={showCreateAppModal}
        id={myFunnel && myFunnel.id ? myFunnel.id : undefined} 
        handleClose={() => {
          setMyFunnel(null)
          setShowCreateAppModal(false)
          refresh()
        }} 
      />
      <ModalDeleteFunnel 
        show={showCreateAppModalDelete}
        data={myFunnel}
        id={myFunnel ? myFunnel.id : undefined} 
        handleClose={() => {
          setMyFunnel(null)
          setShowCreateAppModalDelete(false)
          refresh()
        }} 
      />
    </div>
  )
}

const ProposalGeneratorWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={proposalGeneratorBreadcrumbs}>Gestão Comercial</PageTitle>
      <ProposalGeneratorPage />
    </>
  )
}

export {ProposalGeneratorWrapper}
// function toggleToast(arg0: string, arg1: string) {
//     throw new Error('Function not implemented.');
// }

