/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {PageLink, PageTitle} from '../../../../../_metronic/layout/core'
import { getClientAll } from '../../../../services/clientService';
import { Loading } from '../../../components/Loadings/loading';
import { ProfileHeaderSettings } from '../profileHeaderSettings';
import { BodySettingsProfile } from './components/bodySettingsProfile';
//import { MyPortal } from './components/my-portal'

const settingsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const SettingsPage: FC = () => {
    const [clients, setClients] = useState<any>([]);
    const [clientsLoading, setclientsLoading] = useState<any>(true);

    // const getClients = async () => {
    //     try {
    //       setclientsLoading(true)
    //       const myClient = await getClientAll()
    //       setClients(myClient)
    //       console.log(myClient)

    //     } catch (error) {
    //       console.log(error)
    //       toggleToast("Erro ao buscar os clientes!", "error")
    //     }finally{
    //       setclientsLoading(false)
    //     }
    // }

    const refresh = () => {
      //getClients()
    }

    useEffect(() => {
      //getClients()
    }, []);


  return (
    <div className='column g-5 g-xl-8'>
        <ProfileHeaderSettings />
        <BodySettingsProfile />
    </div>
  )
}

const SettingsWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={settingsBreadcrumbs}>Configurações de Perfil</PageTitle>
      <SettingsPage />
    </>
  )
}

export {SettingsWrapper}
function toggleToast(arg0: string, arg1: string) {
    throw new Error('Function not implemented.');
}

