/* eslint-disable jsx-a11y/anchor-is-valid */
type Props = {
  valuesReport: any
  handleCheckBoxValues: any,
  handleCheckBoxValuesImg: any
  myTemplateSelected: any
}

export function DropdownMenuDates({valuesReport, handleCheckBoxValues, handleCheckBoxValuesImg, myTemplateSelected} : Props) {
    return (
      <div className='dropdown-menu w-250px w-md-300px dropdown-menu-end' aria-labelledby="dropdownMenuButton1">
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Dados do Relatório</div>
        </div>
  
        <div className='separator border-gray-200'></div>

        {!myTemplateSelected.type_template || myTemplateSelected.type_template === "DETALHADO" ?  
          <div className='px-7 py-5' style={{display: 'flex', flexDirection: 'column', gap: '1.5rem', overflow: 'auto', height: '40rem' , }}>
            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <input className='form-check-input' type='checkbox' value='month' checked={valuesReport.month} 
                onChange={(e) => {
                  handleCheckBoxValues(e.target.checked, e.target.value)
                }}
              />
              <span className='form-check-label fs-4'>Mês Referência</span>
            </label>

            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <input className='form-check-input' type='checkbox' value='initPeriod' checked={valuesReport.initPeriod}
                onChange={(e) => {
                  handleCheckBoxValues(e.target.checked, e.target.value)
                }}
              />
              <span className='form-check-label fs-4'>Inicio Período</span>
            </label>

            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <input className='form-check-input' type='checkbox' value='endPeriod' checked={valuesReport.endPeriod}
                onChange={(e) => {
                  handleCheckBoxValues(e.target.checked, e.target.value)
                }}
              />
              <span className='form-check-label fs-4'>Fim Período</span>
            </label>

            <label className='form-label fw-bold' style={{marginTop: '0.5rem', marginBottom: '0.5rem'}}>Dados da Geração</label>

            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <input className='form-check-input' type='checkbox' value='generation' checked={valuesReport.generation}
                onChange={(e) => {
                  handleCheckBoxValues(e.target.checked, e.target.value)
                }}
              />
              <span className='form-check-label fs-4'>Geração (Kwh)</span>
            </label>

            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <input className='form-check-input' type='checkbox' value='performance' checked={valuesReport.performance}
                onChange={(e) => {
                  handleCheckBoxValues(e.target.checked, e.target.value)
                }}
              />
              <span className='form-check-label fs-4'>Performance (%)</span>
            </label>

            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <input className='form-check-input' type='checkbox' value='consumption' checked={valuesReport.consumption}
                onChange={(e) => {
                  handleCheckBoxValues(e.target.checked, e.target.value)
                }}
              />
              <span className='form-check-label fs-4'>Consumo (KWh)</span>
            </label>

            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <input className='form-check-input' type='checkbox' value='saved' checked={valuesReport.saved}
                onChange={(e) => {
                  handleCheckBoxValues(e.target.checked, e.target.value)
                }}
              />
              <span className='form-check-label fs-4'>Economizado (R$)</span>
            </label>

            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <input className='form-check-input' type='checkbox' value='total_economy' checked={valuesReport.total_economy}
                onChange={(e) => {
                  handleCheckBoxValues(e.target.checked, e.target.value)
                }}
              />
              <span className='form-check-label fs-4'>Total Economizado (R$)</span>
            </label>

            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <input className='form-check-input' type='checkbox' value='invoiced' checked={valuesReport.invoiced}
                onChange={(e) => {
                  handleCheckBoxValues(e.target.checked, e.target.value)
                }}
              />
              <span className='form-check-label fs-4'>Faturado (R$)</span>
            </label>

            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <input className='form-check-input' type='checkbox' value='financial_feedback_month' checked={valuesReport.financial_feedback_month}
                onChange={(e) => {
                  handleCheckBoxValues(e.target.checked, e.target.value)
                }}
              />
              <span className='form-check-label fs-4'>Retorno Financeiro Total (%)</span>
            </label>

            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <input className='form-check-input' type='checkbox' value='financial_feedback_year' checked={valuesReport.financial_feedback_year}
                onChange={(e) => {
                  handleCheckBoxValues(e.target.checked, e.target.value)
                }}
              />
              <span className='form-check-label fs-4'>Retorno Financeiro Total (%)</span>
            </label>


            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <input className='form-check-input' type='checkbox' value='graphic_days' checked={valuesReport.graphic_days}
                onChange={(e) => {
                  handleCheckBoxValues(e.target.checked, e.target.value)
                }}
              />
              <span className='form-check-label fs-4'>Gráfico dos Dias</span>
            </label>

            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <input className='form-check-input' type='checkbox' value='graphic_months' checked={valuesReport.graphic_months}
                onChange={(e) => {
                  handleCheckBoxValues(e.target.checked, e.target.value)
                }}
              />
              <span className='form-check-label fs-4'>Gráfico dos Meses Anteriores</span>
            </label>

            <label className='form-label fw-bold' style={{marginTop: '0.5rem', marginBottom: '0.5rem'}}>Dados da Geradora / Unidade</label>

            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <input className='form-check-input' type='checkbox' value='consumerRegister' checked={valuesReport.consumerRegister}
                onChange={(e) => {
                  handleCheckBoxValues(e.target.checked, e.target.value)
                }}
              />
              <span className='form-check-label fs-4'>Consumo Registrado</span>
            </label>

            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <input className='form-check-input' type='checkbox' value='consumerInstant' checked={valuesReport.consumerInstant}
                onChange={(e) => {
                  handleCheckBoxValues(e.target.checked, e.target.value)
                }}
              />
              <span className='form-check-label fs-4'>Consumo Instantâneo</span>
            </label>

            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <input className='form-check-input' type='checkbox' value='unitConsumerTotal' checked={valuesReport.unitConsumerTotal}
                onChange={(e) => {
                  handleCheckBoxValues(e.target.checked, e.target.value)
                }}
              />
              <span className='form-check-label fs-4'>Consumo Total</span>
            </label>

            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <input className='form-check-input' type='checkbox' value='energyInjected' checked={valuesReport.energyInjected}
                onChange={(e) => {
                  handleCheckBoxValues(e.target.checked, e.target.value)
                }}
              />
              <span className='form-check-label fs-4'>Energia Injetada</span>
            </label>


            {/* <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <button
                className='btn btn-sm btn-secondary'
                type="button" aria-expanded="false"
                style={{display: 'flex', gap: '1rem', padding: '0.25rem'}}
                onClick={() => {
                  handleCheckBoxValuesImg("logo_100")
                }}
              >
                <i className='bi bi-plus fs-5 p-0'></i>
              </button>

              <span className='form-check-label fs-4'>Logo - 100</span>
            </label> */}
            

            <label className='form-label fw-bold' style={{marginTop: '0.5rem', marginBottom: '0.5rem'}}>Dados da Empresa / Filial</label>

            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <input className='form-check-input' type='checkbox' value='corporateName' checked={valuesReport.corporateName}
                onChange={(e) => {
                  handleCheckBoxValues(e.target.checked, e.target.value)
                }}
              />
              <span className='form-check-label fs-4'>Nome da Empresa</span>
            </label>

            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <input className='form-check-input' type='checkbox' value='cnpj' checked={valuesReport.cnpj}
                onChange={(e) => {
                  handleCheckBoxValues(e.target.checked, e.target.value)
                }}
              />
              <span className='form-check-label fs-4'>CNPJ da Empresa</span>
            </label>

            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <input className='form-check-input' type='checkbox' value='companyName' checked={valuesReport.companyName}
                onChange={(e) => {
                  handleCheckBoxValues(e.target.checked, e.target.value)
                }}
              />
              <span className='form-check-label fs-4'>Nome da Filial</span>
            </label>

            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <input className='form-check-input' type='checkbox' value='emailCompany' checked={valuesReport.emailCompany}
                onChange={(e) => {
                  handleCheckBoxValues(e.target.checked, e.target.value)
                }}
              />
              <span className='form-check-label fs-4'>E-mail da Filial</span>
            </label>


            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <button
                className='btn btn-sm btn-secondary'
                type="button" aria-expanded="false"
                style={{display: 'flex', gap: '1rem', padding: '0.25rem'}}
                onClick={() => {
                  handleCheckBoxValuesImg("logo_100")
                }}
              >
                <i className='bi bi-plus fs-5 p-0'></i>
              </button>

              <span className='form-check-label fs-4'>Logo - 100</span>
            </label>

            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <button
                className='btn btn-sm btn-secondary'
                type="button" aria-expanded="false"
                style={{display: 'flex', gap: '1rem', padding: '0.25rem'}}
                onClick={() => {
                  handleCheckBoxValuesImg("logo_200")
                }}
              >
                <i className='bi bi-plus fs-5 p-0'></i>
              </button>

              <span className='form-check-label fs-4'>Logo - 200</span>
            </label>

            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <button
                className='btn btn-sm btn-secondary'
                type="button" aria-expanded="false"
                style={{display: 'flex', gap: '1rem', padding: '0.25rem'}}
                onClick={() => {
                  handleCheckBoxValuesImg("logo_500")
                }}
              >
                <i className='bi bi-plus fs-5 p-0'></i>
              </button>

              <span className='form-check-label fs-4'>Logo - 500</span>
            </label>


            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <button
                className='btn btn-sm btn-secondary'
                type="button" aria-expanded="false"
                style={{display: 'flex', gap: '1rem', padding: '0.25rem'}}
                onClick={() => {
                  handleCheckBoxValuesImg("logo_700")
                }}
              >
                <i className='bi bi-plus fs-5 p-0'></i>
              </button>

              <span className='form-check-label fs-4'>Logo - 700</span>
            </label>


            <label className='form-label fw-bold' style={{marginTop: '0.5rem', marginBottom: '0.5rem'}}>Dados da Usina</label>

            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <input className='form-check-input' type='checkbox' value='namePlant' checked={valuesReport.namePlant}
                onChange={(e) => {
                  handleCheckBoxValues(e.target.checked, e.target.value)
                }}
              />
              <span className='form-check-label fs-4'>Nome da Usina</span>
            </label>

            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <input className='form-check-input' type='checkbox' value='adressPlant' checked={valuesReport.adressPlant}
                onChange={(e) => {
                  handleCheckBoxValues(e.target.checked, e.target.value)
                }}
              />
              <span className='form-check-label fs-4'>Endereço da Usina</span>
            </label>

            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <input className='form-check-input' type='checkbox' value='mark' checked={valuesReport.mark}
                onChange={(e) => {
                  handleCheckBoxValues(e.target.checked, e.target.value)
                }}
              />
              <span className='form-check-label fs-4'>Portal da Usina</span>
            </label>
          </div>
        :
        myTemplateSelected.type_template === "GERACAO" &&
          <div className='px-7 py-5' style={{display: 'flex', flexDirection: 'column', gap: '1.5rem', overflow: 'auto', height: '40rem' , }}>
            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <input className='form-check-input' type='checkbox' value='month' checked={valuesReport.month} 
                onChange={(e) => {
                  handleCheckBoxValues(e.target.checked, e.target.value)
                }}
              />
              <span className='form-check-label fs-4'>Mês Referência</span>
            </label>
  
            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <input className='form-check-input' type='checkbox' value='initPeriod' checked={valuesReport.initPeriod}
                onChange={(e) => {
                  handleCheckBoxValues(e.target.checked, e.target.value)
                }}
              />
              <span className='form-check-label fs-4'>Inicio Período</span>
            </label>
  
            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <input className='form-check-input' type='checkbox' value='endPeriod' checked={valuesReport.endPeriod}
                onChange={(e) => {
                  handleCheckBoxValues(e.target.checked, e.target.value)
                }}
              />
              <span className='form-check-label fs-4'>Fim Período</span>
            </label>
  
            <label className='form-label fw-bold' style={{marginTop: '0.5rem', marginBottom: '0.5rem'}}>Dados da Geração</label>
  
            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <input className='form-check-input' type='checkbox' value='generation' checked={valuesReport.generation}
                onChange={(e) => {
                  handleCheckBoxValues(e.target.checked, e.target.value)
                }}
              />
              <span className='form-check-label fs-4'>Geração (Kwh)</span>
            </label>

            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <input className='form-check-input' type='checkbox' value='generation_anual' checked={valuesReport.generation_anual}
                onChange={(e) => {
                  handleCheckBoxValues(e.target.checked, e.target.value)
                }}
              />
              <span className='form-check-label fs-4'>Geração - Ult. 12 meses (KWh)</span>
            </label>
  
            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <input className='form-check-input' type='checkbox' value='performance' checked={valuesReport.performance}
                onChange={(e) => {
                  handleCheckBoxValues(e.target.checked, e.target.value)
                }}
              />
              <span className='form-check-label fs-4'>Performance (%)</span>
            </label>
  

  
            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <input className='form-check-input' type='checkbox' value='prog_12_meses' checked={valuesReport.prog_12_meses}
                onChange={(e) => {
                  handleCheckBoxValues(e.target.checked, e.target.value)
                }}
              />
              <span className='form-check-label fs-4'>Performance - Ult. 12 meses (%)</span>
            </label>
  
  
  
            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <input className='form-check-input' type='checkbox' value='graphic_days' checked={valuesReport.graphic_days}
                onChange={(e) => {
                  handleCheckBoxValues(e.target.checked, e.target.value)
                }}
              />
              <span className='form-check-label fs-4'>Gráfico dos Dias</span>
            </label>
  
            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <input className='form-check-input' type='checkbox' value='graphic_mensal_combination' checked={valuesReport.graphic_mensal_combination}
                onChange={(e) => {
                  handleCheckBoxValues(e.target.checked, e.target.value)
                }}
              />
              <span className='form-check-label fs-4'>Gráfico dos ultimos 12 meses</span>
            </label>
  
            {/* <label className='form-label fw-bold' style={{marginTop: '0.5rem', marginBottom: '0.5rem'}}>Dados da Geradora / Unidade</label> */}
  
  
            {/* <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <button
                className='btn btn-sm btn-secondary'
                type="button" aria-expanded="false"
                style={{display: 'flex', gap: '1rem', padding: '0.25rem'}}
                onClick={() => {
                  handleCheckBoxValuesImg("logo_100")
                }}
              >
                <i className='bi bi-plus fs-5 p-0'></i>
              </button>
  
              <span className='form-check-label fs-4'>Logo - 100</span>
            </label> */}
            
  
            <label className='form-label fw-bold' style={{marginTop: '0.5rem', marginBottom: '0.5rem'}}>Dados da Empresa / Filial</label>
  
            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <input className='form-check-input' type='checkbox' value='corporateName' checked={valuesReport.corporateName}
                onChange={(e) => {
                  handleCheckBoxValues(e.target.checked, e.target.value)
                }}
              />
              <span className='form-check-label fs-4'>Nome da Empresa</span>
            </label>
  
            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <input className='form-check-input' type='checkbox' value='cnpj' checked={valuesReport.cnpj}
                onChange={(e) => {
                  handleCheckBoxValues(e.target.checked, e.target.value)
                }}
              />
              <span className='form-check-label fs-4'>CNPJ da Empresa</span>
            </label>
  
            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <input className='form-check-input' type='checkbox' value='companyName' checked={valuesReport.companyName}
                onChange={(e) => {
                  handleCheckBoxValues(e.target.checked, e.target.value)
                }}
              />
              <span className='form-check-label fs-4'>Nome da Filial</span>
            </label>
  
            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <input className='form-check-input' type='checkbox' value='emailCompany' checked={valuesReport.emailCompany}
                onChange={(e) => {
                  handleCheckBoxValues(e.target.checked, e.target.value)
                }}
              />
              <span className='form-check-label fs-4'>E-mail da Filial</span>
            </label>
  
  
            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <button
                className='btn btn-sm btn-secondary'
                type="button" aria-expanded="false"
                style={{display: 'flex', gap: '1rem', padding: '0.25rem'}}
                onClick={() => {
                  handleCheckBoxValuesImg("logo_100")
                }}
              >
                <i className='bi bi-plus fs-5 p-0'></i>
              </button>
  
              <span className='form-check-label fs-4'>Logo - 100</span>
            </label>
  
            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <button
                className='btn btn-sm btn-secondary'
                type="button" aria-expanded="false"
                style={{display: 'flex', gap: '1rem', padding: '0.25rem'}}
                onClick={() => {
                  handleCheckBoxValuesImg("logo_200")
                }}
              >
                <i className='bi bi-plus fs-5 p-0'></i>
              </button>
  
              <span className='form-check-label fs-4'>Logo - 200</span>
            </label>
  
            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <button
                className='btn btn-sm btn-secondary'
                type="button" aria-expanded="false"
                style={{display: 'flex', gap: '1rem', padding: '0.25rem'}}
                onClick={() => {
                  handleCheckBoxValuesImg("logo_500")
                }}
              >
                <i className='bi bi-plus fs-5 p-0'></i>
              </button>
  
              <span className='form-check-label fs-4'>Logo - 500</span>
            </label>
  
  
            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <button
                className='btn btn-sm btn-secondary'
                type="button" aria-expanded="false"
                style={{display: 'flex', gap: '1rem', padding: '0.25rem'}}
                onClick={() => {
                  handleCheckBoxValuesImg("logo_700")
                }}
              >
                <i className='bi bi-plus fs-5 p-0'></i>
              </button>
  
              <span className='form-check-label fs-4'>Logo - 700</span>
            </label>
  
  
            <label className='form-label fw-bold' style={{marginTop: '0.5rem', marginBottom: '0.5rem'}}>Dados da Usina</label>
  
            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <input className='form-check-input' type='checkbox' value='namePlant' checked={valuesReport.namePlant}
                onChange={(e) => {
                  handleCheckBoxValues(e.target.checked, e.target.value)
                }}
              />
              <span className='form-check-label fs-4'>Nome da Usina</span>
            </label>

              
            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <input className='form-check-input' type='checkbox' value='capacity' checked={valuesReport.capacity}
                onChange={(e) => {
                  handleCheckBoxValues(e.target.checked, e.target.value)
                }}
              />
              <span className='form-check-label fs-4'>Potência</span>
            </label>
  
            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <input className='form-check-input' type='checkbox' value='adressPlant' checked={valuesReport.adressPlant}
                onChange={(e) => {
                  handleCheckBoxValues(e.target.checked, e.target.value)
                }}
              />
              <span className='form-check-label fs-4'>Endereço da Usina</span>
            </label>
  
            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
              <input className='form-check-input' type='checkbox' value='mark' checked={valuesReport.mark}
                onChange={(e) => {
                  handleCheckBoxValues(e.target.checked, e.target.value)
                }}
              />
              <span className='form-check-label fs-4'>Portal da Usina</span>
            </label>
          </div>  
      }
  
      </div>
    )
  }
  