/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { KTSVG } from '../../../../../../_metronic/helpers';
import { DateComplete } from '../../../../../utils/date';

type Props = {
    clients: any[];
    setMyClient: any;
    setShowCreateAppModal: any;
    setShowCreateAppModalDelete: any
    setShowCreateAppModalDetails: any
}

export function TableBody({
    clients, 
    setMyClient,
    setShowCreateAppModal,
    setShowCreateAppModalDelete, 
    setShowCreateAppModalDetails
}: Props) {

    const [largura, setLargura] = useState(window.innerWidth);

    const handleButtonClickDelete = (event, client) => {
        event.stopPropagation();
        //unitBranch.idUser = unitBranch.users.length > 0 ? unitBranch.users[0].id : null; 
        setMyClient(client)
        setShowCreateAppModalDelete(true)
    };

    const handleButtonClickUpdate = (event: any, client: any) => {
        event.stopPropagation();
        client.idUser = client.user ? client.user.id : null; 
        client.idUnitBranch = client.unitBranchs && client.unitBranchs.length > 0 ? client.unitBranchs[0].id : null;
        client.idsPlants = client.plants && client.plants.length > 0 ? client.plants.map((plant: any) => plant.id) : null;
        setMyClient(client)
        setShowCreateAppModal(true)
    };


    const atualizarTamanhoDaTela = () => {
        setLargura(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', atualizarTamanhoDaTela);
        return () => {
          window.removeEventListener('resize', atualizarTamanhoDaTela);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  return (
    <tbody>
        {clients && clients.length !== 0 ?   
            clients.map((client: any) => {
                return (
                    <tr 
                        onClick={() =>{
                            setMyClient(client)
                            setShowCreateAppModalDetails(true)
                        }}
                    >
                        <td>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                <input className='form-check-input widget-9-check' type='checkbox' value='1' />
                            </div>
                        </td>

                        <td>
                            <div className='d-flex align-items-center'>
                            <div className='symbol symbol-45px me-5'>
                                <KTSVG path="/media/icons/duotune/communication/com006.svg" className="svg-icon-muted svg-icon-3hx" />
                            </div>
                            <div className='d-flex justify-content-start flex-column'>
                                <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                    {client.nameResponsible ? client.nameResponsible : "Não encontrado. "}
                                </a>
                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                    {client.created_at ? DateComplete(new Date(client.created_at)) : "Não encontrado."}
                                </span>
                            </div>
                            </div>
                        </td>

                        <td>
                            <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                {client.cpfResponsible ? client.cpfResponsible : "Não encntrado. "}
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                CPF do cliente
                            </span>
                        </td>

                        <td>
                            <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                {client.email ? client.email : "Nao encontrado"}
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                E-mail de contato
                            </span>
                        </td>

                        <td>
                            {client.user ? (
                            <div>
                                <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                {client.user.name}
                                </a>
                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                {client.user.email}
                                </span>
                            </div>
                            )
                            :
                            (
                                <div>
                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                    Não encontrado.
                                </span>
                                </div>
                            )
                        }
                            
                        </td>
                        

                        <td>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                            {/* <a
                                href='#'
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            >
                                <KTSVG
                                path='/media/icons/duotune/general/gen019.svg'
                                className='svg-icon-3'
                                />
                            </a> */}
                            <a
                                onClick={(event: any) =>{
                                    handleButtonClickUpdate(event, client)
                                }}
                                href='#'
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            >
                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                            </a>
                            <a
                                onClick={(event: any) => {
                                    handleButtonClickDelete(event, client)
                                }}
                                href='#'
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            >
                                <KTSVG
                                path='/media/icons/duotune/general/gen027.svg'
                                className='svg-icon-3'
                                />
                            </a>
                            </div>
                        </td>
                    </tr>
                )
            })
        :
        <tr>
            <td colSpan={largura < 1200 ? 2 : 6} style={{textAlign: 'center'}}>
                <div className='d-flex justify-content-center' style={{padding: '2rem'}}>
                    <span className='text-muted fw-semibold text-muted d-block fs-4'>
                        Clientes não encontrados!
                    </span>
                </div>
            </td>
        </tr>
        }
    </tbody>
  );
}