/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import { getUsersAll } from '../../services/usersService';
import { Loading } from '../components/Loadings/loading';
import { TableUsers } from './TableUsers';

const usersBreadcrumbs: Array<PageLink> = [
    {
      title: 'Dashboard',
      path: '/dashboard',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
]

const UsersPage: FC = () => {
    const [users, setUsers] = useState<any>([]);
    const [usersLoading, setUsersLoading] = useState<any>(true);

    const getUsers = async () => {
        try {
          setUsersLoading(true)
          const myUsers = await getUsersAll()
          setUsers(myUsers)

        } catch (error) {
          console.log(error)
          toggleToast("Erro ao buscar os usuários!", "error")
        }finally{
          setUsersLoading(false)
        }
    }

    const refresh = () => {
      getUsers()
    }

    useEffect(() => {
      getUsers()
    }, []);

  return (
    <div className='column g-5 g-xl-8'>
        {usersLoading ? 
        (
            <div style={{display: 'flex', justifyContent: 'center', height: '30rem', alignItems: 'center'}}>
                <Loading myClass='spinner-border' style={{width: '3rem', height: '3rem'}}/>
            </div>
        )
        :
        (
            // 
            <TableUsers
              className='card-xl-stretch mb-xl-8'
              users={users}
              refresh={refresh} 
            />
        )
    }
    </div>
  )
}

const UsersWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>Gerenciamento de Usuários</PageTitle>
      <UsersPage />
    </>
  )
}

export {UsersWrapper}
function toggleToast(arg0: string, arg1: string) {
    throw new Error('Function not implemented.');
}

