/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import * as Yup from "yup";
import { useFormik } from 'formik';
import { useToast } from '../../../../../../../components/Toats';
import { StepperComponent } from '../../../../../../../../../_metronic/assets/ts/components';
import { KTSVG } from '../../../../../../../../../_metronic/helpers';
import { LoadingSpan } from '../../../../../../../components/Loadings/loadingSpan';
import { putReportService } from '../../../../../../../../services/reportService';

type Props = {
    data: any;
    show: boolean;
    handleClose: () => void
}

const validationSchema = Yup.object().shape({
  //value_implementation: Yup.number(),
});


const modalsRoot = document.getElementById('root-modals') || document.body

const ModalStatusRequestService = ({show, handleClose, data}: Props) => {
  const { toggleToast } = useToast();
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [loading, setLoading] = useState(false)
  const [initialValues] = useState<any>({
    //value_implementation: data.value_implementation ? data.value_implementation : 0,
  })
  
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }


  const closed = () => {
    handleClose()
  }

  const updatePlantSubmit = async () => {
    try {
      setLoading(true)
      const body = {
        status: data && data.modalStatus === "ATENDIDO" 
        ? "ATENDIDO"
        :  data && data.modalStatus === "CANCELADO" 
        ? "CANCELADO"
        : "PENDENTE"
      };
      await putReportService(body, data && data.id ? data.id : "");
      toggleToast("Sucesso ao atualizar o status da solicitação. ", "success")

    } catch (error) {
      console.log('erro',error)
      toggleToast("Erro ao atualizar o status da solicitação. ", "error")
    }finally{
      setLoading(false)
      closed()
      // handleClose()
    //   setTimeout(()=>{
    //     window.location.reload()
    //   }, 2000);
    }
  }

  const submit = () => {
    updatePlantSubmit()
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: submit,
  });

  useEffect(() => {
    // formik.setValues({
    //   value_implementation: data.value_implementation
    // })
   }, [show]);


  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-500px'
      show={show}
      onHide={closed}
      onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>
            {data && data.modalStatus && data.modalStatus === "ATENDIDO" 
            ? "Confirmação da Solicitação" 
            : data && data.modalStatus && data.modalStatus === "CANCELADO" 
            ? "Cancelamento da Solicitação"
            : ""
            }
        </span>
          {/* <span className='text-muted mt-1 fw-semibold fs-5'>( {unitBranch.name} )</span> */}
        </h3>

        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        {/*begin::Stepper */}
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >
         
          {/*begin::Content */}
          <div className='flex-row-fluid py-lg-0'>
            <div style={{marginBottom: '3rem'}}>
                
                <h3 className='card-title align-items-start flex-column'>
                    <span className='text-muted fw-semibold fs-5'>
                        {
                            data && data.modalStatus === "ATENDIDO" 
                            ? "Deseja confirmar o atendimento dessa solicitação?"
                            :  data && data.modalStatus === "CANCELADO" 
                            ? "Deseja cancelar essa solicitação?"
                            : "Deseja alterar essa solicitação?"
                        }
                        
                    </span>
                </h3>
                {/* <div className='fv-row'>
                  <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                      <span>Valor investido (R$)</span>
                      <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Valor da implantação da usina'
                      ></i>
                  </label>
                  <input
                      type='number'
                      className='form-control form-control-lg'
                      name='value_implementation'
                      placeholder=''
                      value={formik.values.value_implementation}
                      onChange={formik.handleChange("value_implementation")}
                  />
                </div> */}


            </div>
            {/*begin::Form */}
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <button
                    type='button'
                    className='btn btn-lg btn-secondary'
                    disabled={loading}
                    onClick={() => {
                        closed()
                    }}
                    //data-kt-stepper-action='submit'
                >
                    <KTSVG
                        path='/media/icons/duotune/arrows/arr063.svg'
                        className='svg-icon-3 ms-2 me-0'
                    />
                    <span style={{marginLeft: '0.5rem'}}>Cancelar</span>
                    
                </button>

                <button
                    type='button'
                    className={`btn btn-lg btn-${
                        data && data.modalStatus && data.modalStatus === "ATENDIDO" 
                        ? 'success'
                        :  data && data.modalStatus && data.modalStatus === "CANCELADO"
                        ? 'danger' 
                        : 'primary'
                    }`}
                    disabled={loading}
                    //data-kt-stepper-action='submit'
                    onClick={submit}
                >
                    {
                        data && data.modalStatus && data.modalStatus === "ATENDIDO" 
                        ? 'Sim, confirmar atendimento'
                        :  data && data.modalStatus && data.modalStatus === "CANCELADO"
                        ? 'Sim, cancelar solicitação' 
                        : 'Atualizar'
                    }
                    {
                        loading ? 
                        <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                        :
                        <KTSVG
                            path='/media/icons/duotune/arrows/arr064.svg'
                            className='svg-icon-3 ms-2 me-0'
                        />
                    }
                </button>
            </div>
            {/*end::Form */}
          </div>
          {/*end::Content */}
        </div>
        {/* end::Stepper */}
      </div>
    </Modal>,
    modalsRoot
  )
}

export {ModalStatusRequestService}
