import api from "./api";

export const postProposalsOne = async (body: any) => {
    const response = await api.post(`proposals/`, body);
    return response.data.result;
};

export const getProposalsAll = async (status?: string, funnel?: string) => {
    const response = await api.get(`proposals/listAll?status=${status}&funnel=${funnel ? funnel : ""}`,);
    return response.data;
};


export const getProposalsQtd = async (funnel?: string) => {
    const response = await api.get(`proposals/listAllQtd?funnel=${funnel ? funnel : ""}`,);
    return response.data;
};

export const getProposalOne = async (id: string) => {
    const response = await api.get(`proposals/${id}`);
    return response.data;
};

export const putProposalOne = async (id: string, body: any) => {
    const response = await api.put(`proposals/${id}`, body);
    return response.data;
};

export const deleteProposalOne = async (id: string) => {
    const response = await api.delete(`proposals/${id}`);
    return response.data;
};