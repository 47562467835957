import api from "./api";

export const getClientAll = async (unitBranch?: string) => {
    const response = await api.get(`client/listAll?unitBranch=${unitBranch ? unitBranch : "" }`);
    return response.data;
};

export const postClient = async (body: any) => {
    const response = await api.post(`client/`, body);
    return response.data;
};

export const updateClient = async (body: any, id: string) => {
    const response = await api.put(`client/${id}`, body);
    return response.data;
};

export const deleteClient = async (id: string) => {
    const response = await api.delete(`client/${id}`);
    return response.data;
};

export const getClientOne = async (id: string) => {
    const response = await api.get(`client/${id}`);
    return response.data;
};

