/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import { getConcessionariesAll } from '../../../../services/concessionariesService';
import { getUnitConsumerAllFilter, getUnitConsumerAllFilterPagination } from '../../../../services/unitConsumerService';
import { searchSubstitut } from '../../../../utils/search';
import { Loading } from '../../../components/Loadings/loading';
import { SearchInput } from '../../../components/SearchInput';
import { ModalAddUpdateUnitConsumer } from '../ModalAddEditUnitConsumer';

// import { ModalDeleteClient } from '../ModalDeleteClient';
// import { ModalDetailsClient } from '../ModalDetailsClient';

import { TableBody } from './TableBody';
import useSize from '../../../../hooks/useSize';
import { useAuth } from '../../../../modules/auth';
import { getplantsAll } from '../../../../services/plantsService';


type Props = {
  className: string;
}

const TableUnitConsumers: React.FC<Props> = ({className}) => {
  
  const [mySearch, setMySearch] = useState<any[]>([]);
  const [control, setControl] = useState(false);
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  // const [showCreateAppModalDelete, setShowCreateAppModalDelete] = useState<boolean>(false)
  // const [showCreateAppModalDetails, setShowCreateAppModalDetails] = useState<boolean>(false)
  const [myUnitConsumer, setMyUnitConsumer] = useState<any | null>(null);
  const [loadingConcessionaries, setLoadingConcessionaries] = useState<any>(true);
  const [loadingPlants, setLoadingPlants] = useState<any>(false);
  const [concessionaries, setConcessionaries] = useState<any>([]);

  const [unitConsumers, setUnitConsumers] = useState<any>([]);
  const [plants, setPlants] = useState<any>([]);
  const [unitConsumerLoading, setUnitConsumerLoading] = useState<any>(true);
  const [filterConcessionaries, setFilterConcessionaries] = useState<string>("");
  const [filterPlant, setFilterPlant] = useState<string>("");
  const [largura, setLargura] = useState(window.innerWidth);
  const {height} = useSize()
  const user = useAuth()

  const [pagination, setPagination] = useState<any>({
    page: 1,
    limit: 100,
    total: 0,
    qtdPages: 1
  });

    const getUnitConsumer = async () => {
        try {
          setUnitConsumerLoading(true)
          const myUnitConsumer = await getUnitConsumerAllFilterPagination(
            filterConcessionaries ? filterConcessionaries : "", 
            filterPlant ? filterPlant : "", pagination.limit, 
            pagination.page
          )
          setUnitConsumers(myUnitConsumer.data)
          setPagination({...pagination, qtdPages: myUnitConsumer.lastPage, total: myUnitConsumer.total})

          const search_unitConsumer = mySearch.length > 0 ? 
            searchSubstitut(myUnitConsumer.data, mySearch)
          : []
          setMySearch(search_unitConsumer)

        } catch (error) {
          console.log(error)
          toggleToast("Erro ao buscar as unidades consumidoras!", "error")
        }finally{
          setUnitConsumerLoading(false)
        }
    }

  const getConcessionaries = async () => {
    try {
      setLoadingConcessionaries(true)
      const myConcessionaries = await getConcessionariesAll()
      setConcessionaries(myConcessionaries)

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar as Concessionárias!", "error")
    }finally{
      setLoadingConcessionaries(false)
    }
  }
  const getPlants = async () => {
    try {

      setLoadingPlants(true)
      let plants = await getplantsAll()
      setPlants(plants)

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar a lista de unidades. ", "error")

    }finally{
      setLoadingPlants(false)
    }
  }

  const refresh = () => {
    getUnitConsumer()
  }

  const atualizarTamanhoDaTela = () => {
    setLargura(window.innerWidth);
  };


  useEffect(() => {
    getUnitConsumer()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterConcessionaries, filterPlant, pagination.page]);

  useEffect(() => {
    getUnitConsumer()
    getConcessionaries()
    getPlants()

    window.addEventListener('resize', atualizarTamanhoDaTela);
    return () => {
      window.removeEventListener('resize', atualizarTamanhoDaTela);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  if (loadingConcessionaries || unitConsumerLoading) {
    return (
      <div style={{display: 'flex', justifyContent: 'center', height: '30rem', alignItems: 'center'}}>
        <Loading myClass='spinner-border' style={{width: '3rem', height: '3rem'}}/>
      </div>
    );
  }
  
  return (
    <div 
      className={`card ${className}`} 
      style={{
        height: height <= 750 ? '62vh' 
        : height <= 850 ? '70vh'
        : height <= 930 ? '70vh'
        : '72vh',
        paddingBottom: '1rem'
      }}
    >
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        {/*<h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Lista de Clientes</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{clients.length} Cliente(s)</span>
        </h3>*/}
        <div 
          style={{
            display: 'flex', 
            gap: '1rem', 
            alignItems: largura > 1200 ? 'center' : 'start', 
            width: "100%", 
            justifyContent: 'space-between', 
            flexDirection: largura > 1200 ? 'row' : 'column', 
            paddingBottom: largura > 1200 ? '1rem' : '1rem'
          }}>
          <div style={{display: 'flex', gap: '1rem', flexDirection: largura > 1200 ? 'row' : 'column', width: '100%'}}>
            <SearchInput 
              className='form-control form-control-solid ps-14 bg-body'
              placeholder='Buscar Unidades Consumidoras'
              myDate={unitConsumers}
              setMySearch={setMySearch}
              control={control}
              style={{
                //flex: '1',
                width: largura < 1200 ? "100%" : "300px"
              }}
              setControl={setControl}
              searchParameter="name"
            />

            {/* <div>
              <select
                className='form-select form-select-solid'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                defaultValue={filterConcessionaries}
                onChange={(e) => {
                  setFilterPlant(e.target.value)
                }}
              >
                <option value={''}>Selecione uma usina</option>
                {plants && plants.map((plant: any) => {
                  return (
                    <option key={plant.id} value={plant.name}>{plant.name}</option>
                  )
                })}
                
              </select>
            </div> */}

            <div>
              <div className='me-0 dropdown' style={{width: '100%'}}>
                <button
                  className='btn btn-lg btn-light buttonFilterMark'
                  type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false"
                  style={{display: 'flex', gap: '1rem'}}
                >
                  <span>{filterPlant !== "" ? plants.find((plant: any) => plant.id === filterPlant)?.name : "Selecione uma usina"} </span>
                  <i className='bi bi-three-dots fs-3'></i>
                </button>

                <div className='dropdown-menu dropdown-menu-end' aria-labelledby="dropdownMenuButton2" style={{padding: '1rem', height: '25rem', overflow: 'auto'}}>
                  <div className='card-toolbar nav nav-tabs' data-kt-buttons='true' style={{display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'start'}}>
                    <a
                        className={`btn btn-sm btn-color-muted btn-active btn-active-dark px-5 me-1 nav-item`}
                        //style={{background: 'green'}}
                        data-bs-toggle="tab"
                        style={{display: 'flex', gap: '1rem', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}
                        href="#"
                        onClick={()=> {
                          setFilterPlant("")
                        }}
                      >
                        Todas as usinas
                      {/* <span 
                        className='badge badge-dark spanFilter'
                      >
                        {concessionarie.status_mon_aut ? concessionarie.status_mon_aut : 'Não identificado.'}
                      </span> */}
                    </a>
                    {plants && plants.map((plant: any) => {
                    return (
                      <a
                        className={`btn btn-sm btn-color-muted btn-active btn-active-primary px-5 me-1 nav-item`}
                        style={{display: 'flex', gap: '1rem', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}
                        data-bs-toggle="tab"
                        href="#"
                        onClick={()=> {
                          setFilterPlant(plant.id ? plant.id : "")
                        }}
                      >
                      <span style={{textAlign: 'start'}}>
                        {plant.name ? plant.name : "Não identificado"} 
                      </span>
                    </a>
                      // <option key={concessionarie.id} value={concessionarie.name}>{concessionarie.name}</option>
                    )
                  })}
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className='me-0 dropdown' style={{width: '100%'}}>
                <button
                  className='btn btn-lg btn-light buttonFilterMark'
                  type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"
                  style={{display: 'flex', gap: '1rem'}}
                >
                  <span>{filterConcessionaries !== "" ? filterConcessionaries : "Selecione a concessionária"} </span>
                  <i className='bi bi-three-dots fs-3'></i>
                </button>

                <div className='dropdown-menu dropdown-menu-end' aria-labelledby="dropdownMenuButton1" style={{padding: '1rem'}}>
                  <div className='card-toolbar nav nav-tabs' data-kt-buttons='true' style={{display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'start'}}>
                    <a
                        className={`btn btn-sm btn-color-muted btn-active btn-active-dark px-5 me-1 nav-item`}
                        //style={{background: 'green'}}
                        data-bs-toggle="tab"
                        style={{display: 'flex', gap: '1rem', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}
                        href="#"
                        onClick={()=> {
                          setFilterConcessionaries("")
                        }}
                      >
                        Selecione a concessioária
                      {/* <span 
                        className='badge badge-dark spanFilter'
                      >
                        {concessionarie.status_mon_aut ? concessionarie.status_mon_aut : 'Não identificado.'}
                      </span> */}
                    </a>
                    {concessionaries && concessionaries.map((concessionarie: any) => {
                    return (
                      <a
                        className={`btn btn-sm btn-color-muted btn-active btn-active-
                          ${concessionarie.status_mon_aut && concessionarie.status_mon_aut === 'MONITORADA' ? 'success' : 'danger'}
                          px-5 me-1 nav-item`}
                        style={{display: 'flex', gap: '1rem', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}
                        data-bs-toggle="tab"
                        href="#"
                        onClick={()=> {
                          setFilterConcessionaries(concessionarie.name ? concessionarie.name : "")
                        }}
                      >
                      <span style={{textAlign: 'start'}}>
                        {concessionarie.name ? concessionarie.name : "Não identificado"} 
                      </span>
                      <span 
                        className={`badge badge-${concessionarie.status_mon_aut && concessionarie.status_mon_aut === 'MONITORADA' ? 'success' : 'danger'} fs-9`}
                        style={{height: '1.5rem', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                      >
                        {concessionarie.status_mon_aut ? concessionarie.status_mon_aut : 'Não identificado.'}
                      </span>
                    </a>
                      // <option key={concessionarie.id} value={concessionarie.name}>{concessionarie.name}</option>
                    )
                  })}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click para adicionar uma unidade filial'
            style={largura < 1200 ? {width: '100%', display: 'flex', justifyContent: 'end'} : {}}
          >
            <a
              onClick={() =>{setShowCreateAppModal(true)}}
              href='#'
              className='btn btn-sm btn-light-primary'
              //data-bs-target='#kt_modal_create_unitBranch'

            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
              Nova Unidade Consumidora
            </a>
          </div>
        </div>
        
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3' style={largura < 1200 ? {height: '20rem', overflow: 'auto', paddingTop: '0rem'} : {overflow: 'auto'}}>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-hover table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted fs-7 text-uppercase gs-0'>
                <th className='w-25px' >
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th>
                <th className='min-w-250px'>Denominação</th>
                <th className='min-w-150px'>Tipo de Unidade</th>
                <th className='min-w-200px'>Usina Vinculada</th>
                <th className='min-w-140px'>Contrato</th>
                <th className='min-w-250px'>Concessionária Vinculada</th>
                {user.currentUser && user.currentUser.companies && user.currentUser.companies.length > 0
                && user.currentUser.companies[0].planos === "PREMIUM" && 
                (<th className='min-w-200px'>Monit. Autom.</th>)}
                <th className='min-w-100px text-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
              {
                control === false ? (
                  <TableBody 
                    unitConsumers={unitConsumers} 
                    setMyUnitConsumer={setMyUnitConsumer}
                    setShowCreateAppModal={setShowCreateAppModal}
                    // setShowCreateAppModalDelete={setShowCreateAppModalDelete}
                    // setShowCreateAppModalDetails={setShowCreateAppModalDetails}
                  />
                )
                : (
                  <TableBody 
                    unitConsumers={mySearch} 
                    setMyUnitConsumer={setMyUnitConsumer}
                    setShowCreateAppModal={setShowCreateAppModal}
                    // setShowCreateAppModalDelete={setShowCreateAppModalDelete}
                    // setShowCreateAppModalDetails={setShowCreateAppModalDetails}
                  />
                )
                }
              
            {/* end::Table body */}
          </table>
        </div>
      </div>
        
      <div className='d-flex flex-stack flex-wrap pt-10' style={{marginRight: '2rem', marginLeft: '2rem', marginBottom: '1rem'}}>
            <div className='fs-6 fw-bold text-gray-700'>Mostrando 1 a {pagination.limit} de {pagination.total} encontradas</div>

            <ul className='pagination'>
              <li className='page-item previous'>
                <a 
                  href='#' 
                  className='page-link'
                  onClick={() => {
                    setPagination({...pagination, page: pagination.page - 1 > 0 ? pagination.page - 1 : pagination.page})
                  }}
                >
                  <i className='previous'></i>
                </a>
              </li>

              {Array.from({ length: pagination.qtdPages }, (_, index) => (
              <li 
                key={index} 
                className={`page-item ${(index + 1) === pagination.page ? "active" : ""}`}
                onClick={() => {
                  setPagination({...pagination, page: index + 1})
                }}
              >
                <a href='#' className='page-link'>
                  {index + 1}
                </a>
              </li>
              ))}

              
              <li className='page-item next'>
                <a 
                  href='#' 
                  className='page-link'
                  onClick={() => {
                    setPagination({...pagination, page: pagination.page + 1 <= pagination.qtdPages ? pagination.page + 1 : pagination.page})
                  }}
                >
                  <i className='next'></i>
                </a>
              </li>
            </ul>
          </div>



        <ModalAddUpdateUnitConsumer
          show={showCreateAppModal}
          id={myUnitConsumer ? myUnitConsumer.id : undefined} 
          data={myUnitConsumer ? myUnitConsumer : undefined}
          handleClose={() => {
            setMyUnitConsumer(null)
            setShowCreateAppModal(false)
            refresh()
          }} 
        />
        {/* <ModalDeleteClient 
          show={showCreateAppModalDelete}
          data={myClient}
          id={myClient ? myClient.id : undefined} 
          handleClose={() => {
            setMyClient(null)
            setShowCreateAppModalDelete(false)
            refresh()
          }} 
        /> */}

        {/* <ModalDetailsClient
          show={showCreateAppModalDetails}
          id={myClient ? myClient.id : undefined} 
          handleClose={() => {
            setMyClient(null)
            setShowCreateAppModalDetails(false)
            refresh()
          }} 
        /> */}
      {/* begin::Body */}
    </div>
  )
}

export {TableUnitConsumers}

function toggleToast(arg0: string, arg1: string) {
  throw new Error('Function not implemented.');
}

