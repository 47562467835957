/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { KTSVG } from '../../../../../_metronic/helpers';
import { DateComplete } from '../../../../utils/date';
import { initialLetters } from '../../../../utils/initialLetters';
import { useAuth } from '../../../../modules/auth';

type Props = {
    users: any[];
    setMyUser: any;
    setShowCreateAppModal: any;
    setShowCreateAppModalDelete: any
    setShowCreateAppModalActive: any
    // setShowCreateAppModalDetails: any
}

export function TableBody({
    users, 
    setMyUser,
    setShowCreateAppModal,
    setShowCreateAppModalDelete, 
    setShowCreateAppModalActive
    // setShowCreateAppModalDetails
}: Props) {

    const [largura, setLargura] = useState(window.innerWidth);
    const {currentUser} = useAuth()

    const handleButtonClickDelete = (event, user) => {
        event.stopPropagation();
        //unitBranch.idUser = unitBranch.users.length > 0 ? unitBranch.users[0].id : null; 
        setMyUser(user)
        setShowCreateAppModalDelete(true)
    };

    const handleButtonClickActive = (event, user) => {
        event.stopPropagation();
        //unitBranch.idUser = unitBranch.users.length > 0 ? unitBranch.users[0].id : null; 
        setMyUser(user)
        setShowCreateAppModalActive(true)
    };

    const handleButtonClickUpdate = (event: any, user: any) => {
        event.stopPropagation();
        user.idUnitBranch = user.unitBranchs.length > 0 ? user.unitBranchs[0].id : null;
        user.role = user.role ? user.role.slug : null
        user.idClient = user.client ? user.client.id : null;
        setMyUser(user)
        setShowCreateAppModal(true)
    };

    const atualizarTamanhoDaTela = () => {
        setLargura(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', atualizarTamanhoDaTela);
        return () => {
          window.removeEventListener('resize', atualizarTamanhoDaTela);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  return (
    <tbody >
        {users && users.length !== 0 ?   
            users.map((user: any) => {
                return (
                    <tr 
                        onClick={() =>{
                            setMyUser(user)
                            //setShowCreateAppModalDetails(true)
                        }}
                    >
                        <td>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                <input className='form-check-input widget-9-check' type='checkbox' value='1' />
                            </div>
                        </td>

                        <td>
                            <div className='d-flex align-items-center'>
                                <div className='symbol symbol-50px symbol-circle me-5'>
                                    {/* <img src={toAbsoluteUrl("/media/avatars/300-6.jpg")} /> */}

                                    <div className='symbol symbol-40px symbol-circle'>
                                        <span className={`symbol-label bg-light-primary text-primary fs-4 fw-bolder`}>
                                            {user ? initialLetters(user.name) : "Não identificado."}
                                        </span>
                                        {/* <div className='symbol-badge bg-success start-100 top-100 border-4 h-15px w-15px ms-n3 mt-n3'></div> */}
                                    </div>
                                </div>
                                <div className='d-flex justify-content-start flex-column'>
                                    <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                        {user.name ? user.name : "Não encontrado. "}
                                    </a>
                                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                        {user.email ? user.email : "Nao encontrado"}
                                    </span>
                                </div>
                            </div>
                        </td>

                        <td>
                            {user.role ? (
                            <div>
                                <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                {user.role.slug}
                                </a>
                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                {user.role.description}
                                </span>
                            </div>
                            )
                            :
                            (
                                <div>
                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                    Não encontrado.
                                </span>
                                </div>
                            )
                        }
                            
                        </td>

                        <td>
                            <span 
                              className={
                                `badge badge-light-${
                                  user.status !== null
                                    ? user.status === "active"
                                    ? "success"
                                    : user.status === "inactive"
                                  : "secondary"
                                
                                } fs-7 fw-semibold`
                              }
                            >
                                {
                                  user.status !== null
                                  ? user.status === "active"
                                  ? "active"
                                  : user.status === "inactive"
                                : "Inactive"
                                }
                            </span>
                        </td>
                        
                        <td >
                            <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                {user.created_at ? DateComplete(new Date(user.created_at)) : "Não encontrado."}
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                Data de inscrição na plataforma
                            </span>
                        </td>

                        <td>
                            {currentUser && currentUser.id !== user.id &&
                            (<div className='d-flex justify-content-end flex-shrink-0'>
                            {/* <a
                                href='#'
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            >
                                <KTSVG
                                path='/media/icons/duotune/general/gen019.svg'
                                className='svg-icon-3'
                                />
                            </a> */}
                            <a
                                onClick={(event: any) =>{
                                    handleButtonClickUpdate(event, user)
                                }}
                                href='#'
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            >
                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                            </a>

                            <a
                                onClick={(event: any) => {
                                    handleButtonClickDelete(event, user)
                                }}
                                href='#'
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            >
                                <KTSVG
                                path='/media/icons/duotune/general/gen027.svg'
                                className='svg-icon-3'
                                />
                            </a>

                            {user.status === 'active' ?
                                <a
                                onClick={(event: any) =>{
                                    handleButtonClickActive(event, user)
                                }}
                                style={{marginLeft: '0.3rem'}}
                                href='#'
                                className='btn btn-icon btn-bg-danger btn-active-color-primary btn-sm me-1'
                                >
                                    <i className="bi bi-person-x-fill fs-3 text-white"></i>
                                </a>

                                :

                                <a
                                onClick={(event: any) =>{
                                    handleButtonClickActive(event, user)
                                }}
                                href='#'
                                className='btn btn-icon btn-bg-success btn-active-color-primary btn-sm me-1'
                                >
                                    <i className="bi bi-person-fill-check fs-3 text-white"></i>
                                </a>
                            }

                            
                            </div>)}
                        </td>
                    </tr>
                )
            })
        :
        <tr>
            <td colSpan={largura < 1200 ? 2 : 6} style={{textAlign: 'center'}}>
                <div className='d-flex justify-content-center' style={{padding: '2rem'}}>
                    <span className='text-muted fw-semibold text-muted d-block fs-4'>
                        Usuários não encontrados!
                    </span>
                </div>
            </td>
        </tr>
        }
    </tbody>
  );
}