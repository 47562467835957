import api from "./api";

export const getfileUrl = async (file: any) => {
    const response = await api.post(`uploads/`, file, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  };




