import React, { createContext, useContext, useState } from 'react';
import Toast from 'react-bootstrap/Toast';

interface ToastContextType {
  showToast: boolean;
  toggleToast: (message?: string, type?: string) => void; // Atualize a assinatura para aceitar um parâmetro opcional
  closeToast: () => void;
}

const ToastContext = createContext<ToastContextType>({
  showToast: false,
  toggleToast: () => {},
  closeToast: () => {},
});

export const useToast = () => {
  return useContext(ToastContext);
};

export const ToastProvider = ({ children }) => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('');

  const toggleToast = (message?: string, type?: string) => {
    setToastMessage(message || ''); // Use uma mensagem em branco se nenhum parâmetro for fornecido
    setToastType(type ? type : "")
    setShowToast(true);
  };

  const closeToast = () => {
    setShowToast(false);
  };

  return (
    <ToastContext.Provider value={{ showToast, toggleToast, closeToast }}>
      {children}
      {showToast && (
        <div
          className="position-fixed bottom-0 end-0 p-3"
          style={{ zIndex: 100 }}
        >
          <Toast
            show={showToast}
            onClose={closeToast}
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            className={`bg-${toastType === "error" ? "danger" : toastType === "success" ? "success" : toastType === "alert" ? "warning" : "primary"}`}
            style={{ width: '40rem', height: '4rem', display: 'flex', alignItems: 'center' }}
          >
            <Toast.Body
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                fontSize: '1.2rem',
                alignItems: 'center',
                width: '100%',
                color: 'white'
              }}
            >
              {toastMessage}
              <button
                type="button"
                className="btn-close btn-close-white"
                onClick={closeToast}
                aria-label="Close"
              ></button>
            </Toast.Body>
          </Toast>
        </div>
      )}
    </ToastContext.Provider>
  );
};
